<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row row-spacing">
        <div class="form-group col-md-6">
          <label>Ordered Download Speed</label>
          <input class="form-control" v-model="value.orderedDownloadSpeed" />
        </div>
        <div class="form-group col-md-6">
          <label>Ordered Upload Speed</label>
          <input class="form-control" v-model="value.orderedUploadSpeed" />
        </div>
        <div class="form-group col-md-6">
          <label>Required Download Speed</label>
          <input class="form-control" disabled v-model="value.downloadSpeed" />
          <small>Automatically Calculated</small>
        </div>
        <div class="form-group col-md-6">
          <label>Required Upload Speed</label>
          <input class="form-control" disabled v-model="value.uploadSpeed" />
          <small>Automatically Calculated</small>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <label>Link Label</label>
          <input class="form-control" v-model="value.linkLabel" />
        </div>
        <div class="form-group col-md-4">
          <label>Circuit Number</label>
          <input class="form-control" v-model="value.circuitNumber" />
        </div>
        <div class="form-group col-md-4">
          <label>Client Side Port</label>
          <input class="form-control" v-model="value.clientSidePort" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <label>Provider Backhaul Circuit</label>
          <input class="form-control" v-model="value.providerBackhaulCircuit" />
        </div>
        <div class="form-group col-md-4">
          <label>Provider Location</label>
          <input class="form-control" v-model="value.providerLocation" />
        </div>
        <div class="form-group col-md-4">
          <label>Provider Port</label>
          <input class="form-control" v-model="value.providerPort" />
        </div>
      </div>
      <div class="form-group">
        <label>Contract Term</label>
        <input class="form-control" v-model="value.contractTerm" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
