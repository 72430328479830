<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <button
          v-if="userEditRight"                  
          type="button"
          class="btn btn-info"
          @click="addPackage()"
        >
          Create New Package
        </button>
      </div>
    </div>
    <div class="row row-spacing mb-3">
      <div class="col-md-12">
        <div
          class="table-responsive table-bordered"
          v-if="zimbraPackages.length > 0"
        >
          <table class="table">
            <thead>
              <tr class="text-nowrap">
                <th>Description</th>
                <th>Zimbra Class of Service ID</th>
                <th>Size</th>
                <th>Price (excl. VAT)</th>
                <th>Incl. VAT</th>
                <th>Expired</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="{ 'bg-primary': zimbraPackage.expired }"
                v-for="zimbraPackage in zimbraPackages"
                :key="zimbraPackage.zimbraPackageNumber"
              >
                <td>{{ zimbraPackage.description }}</td>
                <td class="text-nowrap">
                  {{ zimbraPackage.zimbraClassOfServiceId }}
                </td>
                <td class="text-nowrap">{{ zimbraPackage.size }} MB</td>
                <td>
                  {{ getPrice(zimbraPackage.zimbrapackageprices, period) }}
                  <span
                    v-if="userEditRight"                  
                    style="cursor: pointer"
                    @click="openPriceModal(zimbraPackage)"
                    v-tooltip="'Edit Row Price'"
                    class="pull-right"
                  >
                    <em class="fa fa-edit fa-fw text-green"></em>
                  </span>
                </td>
                <td class="text-nowrap">
                  {{
                    getPrice(zimbraPackage.zimbrapackageprices, period, true)
                  }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      zimbraPackage.expired ? 'badge-warning' : 'badge-green'
                    "
                    >{{ zimbraPackage.expired }}
                  </span>
                </td>
                <td class="text-center">
                  <button
                    v-if="userEditRight"                  
                    v-tooltip="'Edit Package'"
                    @click="editPackage(zimbraPackage)"
                    type="button"
                    class="btn btn-sm btn-info m-1"
                  >
                    <em class="fa fa-edit fa-fw"></em>
                  </button>
                  <button
                    v-if="userEditRight"                  
                    v-tooltip="'Delete Package'"
                    type="button"
                    @click="deletePackage(zimbraPackage)"
                    class="btn btn-sm btn-primary"
                  >
                    <em class="fa fa-trash fa-fw"></em>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="alert alert-info">No Zimbra Packages Available</div>
      </div>
    </div>
    <ModifyZimbraPackageModal
      ref="modifyPackageModal"
      :pricePackage="newPackage"
      @packageAdded="packageAdded"
      @packageEdited="loadZimbraPackages()"
    />
    <AddPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="loadZimbraPackages()"
    />
  </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";
import ModifyZimbraPackageModal from "@/components/Admin/Modals/ModifyZimbraPackageModal";
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";

export default {
  extends: PriceFunctions,
  components: {
    ModifyZimbraPackageModal,
    AddPriceModal,
  },
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },

  },
  data() {
    return {
      zimbraPackages: [],
      period: this.getCurrentPeriod(),
      rowPrice: [],
      pricePackage: {},
      newPackage: {
        zimbrapackageprice: {}
      },
      newPrice: {},
    };
  },
  mounted() {
    this.loadZimbraPackages();
  },
  methods: {
    loadZimbraPackages() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/zimbrapackages/product/" +
            this.productNumber
        )
        .then(
          (response) => {
            this.pricePackage = response.data;
            this.setNewPrice();
            this.zimbraPackages = response.data.zimbrapackages;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Solarwinds Backup Packages", error);
          }
        );
    },
    setNewPrice() {
      this.newPrice = this.deepCopyObject(this.pricePackage.zimbrapackageprice);
    },
    openPriceModal(zimbraPackage) {
      this.rowPrice = this.deepCopyObject(
        zimbraPackage.zimbrapackageprices || []
      );
      this.newPrice.zimbraPackageNumber = zimbraPackage.zimbraPackageNumber;
      this.$refs.addPriceModal.open();
    },
    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
        }
      });
      this.rowPrice.push(newPrice);
      this.setNewPrice();
      this.newPrice.zimbraPackageNumber = newPrice.zimbraPackageNumber;
    },
    addPackage() {
      this.newPackage = this.pricePackage;
      this.$refs.modifyPackageModal.open();
    },
    editPackage(zimbraPackage) {
      this.newPackage = this.deepCopyObject(zimbraPackage);
      this.$refs.modifyPackageModal.open();
    },
    packageAdded(zimbraPackage) {
      zimbraPackage.zimbrapackageprices = [];
      this.zimbraPackages.push(zimbraPackage);
      this.openPriceModal(zimbraPackage);
    },
    deletePackage(zimbraPackage) {
      this.$swal({
        title: "Are you sure you want to delete this Package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/pricepackages/" +
                zimbraPackage.objKey +
                "?objClassName=" +
                zimbraPackage.class
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.loadZimbraPackages();
              },
              (error) => {
                this.showError("Error Deleting Price Package", error);
              }
            );
        }
      });
    },
  },
};
</script>
