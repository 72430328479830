<template>
  <div class="card card-default">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row row-spacing">
            <div class="col-md-4">
              <label>Select</label>
              <select
                class="form-control"
                v-model="templateNumberSelected"
                @change="templateSelected()"
              >
                <option :value="-1" selected disabled>Select Template..</option>
                <option
                  :value="template.templateNumber"
                  v-for="template in availableTemplates"
                  :key="template.templateNumber"
                >
                  {{ template.description }} ({{ template.type }})
                </option>
              </select>
            </div>
            <div class="col-md-8 mt-4">
              <div class="pull-right">
                <div class="form-group">
                <button
                  class="input-group-text btn btn-primary text-white"
                  type="button"
                  @click="openTemplateModal()"
                >
                <i class="fa fa-plus"></i>
                  New Template
                </button>
              </div>
              </div>
            </div>
          </div>
          <hr v-if="templateNumberSelected > 0"/>
          <div class="row" v-if="templateNumberSelected > 0">
            <div class="col-md-8">
              <div class="row row-spacing">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Description</label>
                    <input class="form-control" v-model="templateDetails.description" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Type</label>
                    <select class="form-control" v-model="templateDetails.type">
                      <option>Configuration</option>
                      <option>Onboarding</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row row-spacing">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Group Name</label>
                    <select class="form-control" v-model="templateDetails.group">
                      <option
                        :value="group"
                        v-for="group in templateGroups"
                        :key="group"
                      >
                        {{ group }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>File</label>
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="templateDetails.fileName" disabled/>
                      <div class="input-group-append">
                        <button
                          class="btn btn-primary rounded text-white border-0"
                          type="button"
                          @click="downloadTemplate()"
                          :disabled="templateDetails.fileName == 'To be uploaded'"
                        >
                        <i class="fa fa-download"></i>
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row row-spacing">
                <div class="col-md-3">
                  <div class="form-group">
                    <button
                      class="btn btn-success"
                      type="button"
                      @click="saveTemplate()"
                    >
                      Save Template
                    </button>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <button
                      class="btn btn-primary pull-right"
                      type="button"
                      @click="deleteTemplate()"
                    >
                      Delete Template
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4"> 
              <div class="pull-right"> 
                <div class="card card-default card-body"> 
                  <div class="form-group">
                    <label>Upload File</label>
                    <div class="row">
                      <div class="col-md-6">
                        <input type="file" id="selectedFile" style="display: none;" @change="fileChanged($event)"/>
                        <button 
                          type="button" 
                          class="btn btn-info" 
                          onclick="document.getElementById('selectedFile').click();"
                        >
                          Browse Files..
                        </button>
                      </div>
                      <div class="col-md-6">
                        <button
                          class="btn btn-primary pull-right"
                          type="button"
                          @click="uploadTemplate()"
                          :disabled="!fileSelected.name"
                        >
                          <i class="fa fa-upload"></i>
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="this.fileSelected.name" disabled/>
                      <div class="input-group-append">
                        <button
                          class="btn btn-primary rounded text-white border-0"
                          type="button"
                          @click="resetFile()"
                          :disabled="!fileSelected.name"
                        >
                          <i class="fa fa-times iconOnly"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal title="Create New Document Template" ref="addNewTemplate" @save="createNewTemplate()">
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label">
              Description
          </label>
          <input type="text" class="form-control" v-model="newTemplate.description">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label">
              Type
          </label>
          <select class="form-control" v-model="newTemplate.type">
            <option>Configuration</option>
            <option>Onboarding</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-10">
          <label class="control-label">
            Group Name
          </label>
          <select class="form-control" v-model="newTemplate.group">
            <option
              :value="group"
              v-for="group in templateGroups"
              :key="group"
            >
              {{ group }}
            </option>
          </select>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';

export default {
  components: {
    Modal
  },
  computed: {
    templateGroups() {
      const groups = this.availableTemplates.map((t) => {
        return t.group;
      });
      return groups.filter((v, i, a) => a.indexOf(v) === i);
    },
  },
  data() {
    return {
      availableTemplates: [],
      templateDetails: {},
      newTemplate: {},
      templateNumberSelected: -1,
      fileSelected: {
        name: ''
      },
      showtemplateDetailsForm: false,
    };
  },
  mounted() {
    this.getAvailableTemplates();
    this.setTemplateDetails();
    this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
  },
  methods: {
    fileChanged: function (event) {
      this.fileSelected = event.target.files[0];
    },
    resetFile: function () {
      this.fileSelected = { name: '' };
      $("input[type=file]").val(null);
    },
    getAvailableTemplates() {
      this.$http.get(this.$config.aimsAPI + "documents/templates/").then(
        (response) => {
          this.availableTemplates = response.data;
          this.templateDetails = {};
          this.newTemplate = {};
          this.templateNumberSelected = -1;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Templates", error);
        }
      );
    },
    templateSelected() {
      if (this.templateNumberSelected != -1) {
        const template = this.availableTemplates.find(
          (t) => t.templateNumber == this.templateNumberSelected
        );

        this.templateDetails = template;
      } else {
        this.setTemplateDetails();
      }
    },
    setTemplateDetails() {
      this.$http.get(this.$config.aimsAPI + "documents/emptyTemplate").then(
        (response) => {
          this.newTemplate = { class: response.data };
        },
        (error) => {
          this.showError("Could not fetch empty template.", error);
          console.error(error);
        }
      );
    },
    openTemplateModal() {
      this.$refs.addNewTemplate.open();
    },
    createNewTemplate() {
      this.$refs.addNewTemplate.isLoading();

      switch (this.newTemplate.type) {
        case "Configuration":
          this.newTemplate.contentType = "TEXT";
          break;
        case "Onboarding":
          this.newTemplate.contentType = "PDF";
          break;
        default:
          this.showWarning(
            "Could not set Content Type for template type" +
              this.newTemplate.type
          );
          return;
      }

      if (!this.newTemplate.fileName || this.newTemplate.fileName == "")
        this.newTemplate.fileName = "To be uploaded";

      this.$http
        .post(
          this.$config.aimsAPI + "documents/templates/",
          this.newTemplate
        )
        .then(
          (response) => {
            this.availableTemplates.push(response.data);
            this.templateDetails = response.data;
            this.templateNumberSelected = this.templateDetails.templateNumber;

            this.setTemplateDetails();

            this.showSuccess("Save Success");
            this.$refs.addNewTemplate.close();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Template", error);
          }
        );
    },
    saveTemplate() {
      switch (this.templateDetails.type) {
        case "Configuration":
          this.templateDetails.contentType = "TEXT";
          break;
        case "Onboarding":
          this.templateDetails.contentType = "PDF";
          break;
        default:
          this.showWarning(
            "Could not set Content Type for template type" +
              this.templateDetails.type
          );
          return;
      }

      if (!this.templateDetails.fileName || this.templateDetails.fileName == "")
        this.templateDetails.fileName = "To be uploaded";

      this.$http
        .post(
          this.$config.aimsAPI + "documents/templates/",
          this.templateDetails
        )
        .then(
          (response) => {
            if (this.templateNumberSelected == -1) {
              this.availableTemplates.push(response.data);
              this.templateDetails = response.data;
              this.templateNumberSelected = this.templateDetails.templateNumber;
            } else {
              const templateIndex = this.availableTemplates.findIndex(
                (t) => t.templateNumber === this.templateNumberSelected
              );
              this.availableTemplates[templateIndex] = response.data;
              this.$set(this.availableTemplates, templateIndex, response.data);
            }
            this.showSuccess("Save Success");
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Template", error);
          }
        );
    },
    deleteTemplate() {
      this.$swal({
        title: "Are you sure you want to delete this template?",
        text: this.templateDetails.description + " (" + this.templateDetails.type + ")",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          this.$http.delete(this.$config.aimsAPI + "documents/templates/" + this.templateNumberSelected + "/delete").then(
            (response) => {
              this.getAvailableTemplates();
              this.showSuccess("Template Deleted Successfully");
            },
            (error) => {
              this.showError("Could not fetch empty template.", error);
              console.error(error);
            }
          );
        }
      });
    },
    downloadTemplate() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "documents/templates/" +
            this.templateNumberSelected +
            "/download",
          {
            responseType: "arraybuffer",
          }
        )
        .then(
          function (response) {
            const contentType = response.headers.get("Content-Type");
            let blob = new Blob([response.data], {
              type: contentType,
            });
            const fileName = response.headers
              .get("Content-Disposition")
              .split("filename = ")[1];
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
          },
          function (error) {
            console.error(error);
            this.showError("Error Downloading Template", error.data);
          }
        );
    },
    uploadTemplate() {
      const formData = new FormData();

      formData.append("file", this.fileSelected);

      this.$http
        .post(
          this.$config.aimsAPI +
            "documents/templates/" +
            this.templateNumberSelected +
            "/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(
          function (response) {
            this.showSuccess("Upload Success");
            const templateIndex = this.availableTemplates.findIndex(
              (t) => t.templateNumber === this.templateNumberSelected
            );
            this.$set(this.availableTemplates, templateIndex, response.data);
            this.templateDetails = response.data;
            this.resetFile();
          },
          function (error) {
            console.error(error);
            this.showError("Error Uploading Template", error);
          }
        );
    },
  },
};
</script>

<style scoped>
select option[disabled]:first-child { display: none; }

i {
  padding-right: 5px;
}

.iconOnly {
  padding-right: 0;
}

.pull-right {
  float: right;
}
</style>
