<template>
  <div>
    <ServiceScreenLayout :parts="parts">
      <template v-slot:main>
          <div class="row">
            <div class="col-md-12">
              <button
                v-if="userEditRight"                  
                type="button"
                class="btn btn-info"
                @click="createNewPackage()"
              >
                Create New Package
              </button>
            </div>
          </div>
          <div class="row row-spacing mb-3">
            <div class="col-md-12">
              <div
                class="table-responsive table-bordered"
                v-if="cx3Packages.length > 0"
              >
                <table class="table">
                  <thead>
                    <tr class="text-nowrap">
                      <th>Description</th>
                      <th>Concurrent Calls</th>
                      <th>MRC (excl. VAT)</th>
                      <th>Expired</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      :class="{ 'bg-primary': cx3Package.expired }"
                      v-for="cx3Package in cx3Packages"
                      :key="cx3Package.cx3CloudPbxPackageNumber"
                    >
                      <td>{{ cx3Package.description }}</td>
                      <td class="text-nowrap" style="width: 10%">
                        {{ cx3Package.concurrentCalls }}
                      </td>
                      <td style="width: 20%">
                        {{ getPrice(cx3Package.prices, period) }}
                        <span
                          v-if="userEditRight"                  
                          style="cursor: pointer"
                          @click="openPriceModal(cx3Package)"
                          v-tooltip="'Edit Row Price'"
                          class="pull-right"
                        >
                          <em class="fa fa-edit fa-fw text-green"></em>
                        </span>
                      </td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            cx3Package.expired ? 'badge-warning' : 'badge-green'
                          "
                          >{{ cx3Package.expired }}
                        </span>
                      </td>
                      <td class="text-center">
                        <button
                          v-if="userEditRight"                  
                          v-tooltip="'Edit Package'"
                          @click="editPackage(cx3Package)"
                          type="button"
                          class="btn btn-sm btn-info m-1"
                        >
                          <em class="fa fa-edit fa-fw"></em>
                        </button>
                        <button
                          v-if="userEditRight"                  
                          v-tooltip="'Delete Package'"
                          type="button"
                          @click="deletePackage(cx3Package)"
                          class="btn btn-sm btn-primary"
                        >
                          <em class="fa fa-trash fa-fw"></em>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="alert alert-info">No 3CX Packages Available</div>
            </div>
          </div>
          <ModifyCx3PackageModal
            ref="modifyPackageModal"
            :pricePackage="pricePackage"
            @packageAdded="packageAdded"
            @packageEdited="loadCx3Packages()"
          />
          <AddPriceModal
            ref="addPriceModal"
            :row-price="rowPrice"
            :new-price="newPrice"
            @addPrice="addPrice"
            @pricesAdded="loadCx3Packages()"
          />
      </template>
      <template v-slot:support>
        <div class="row">
            <div class="col-md-12">
              <button
                v-if="userEditRight"                  
                type="button"
                class="btn btn-info"
                @click="createNewSupport()"
              >
                Create New Support Package
              </button>
            </div>
          </div>
          <div class="row row-spacing mb-3">
            <div class="col-md-12">
              <div
                class="table-responsive table-bordered"
                v-if="cx3Supports.length > 0"
              >
                <table class="table">
                  <thead>
                    <tr class="text-nowrap">
                      <th>Description</th>
                      <th>MRC (excl. VAT)</th>
                      <th>Expired</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      :class="{ 'bg-primary': support.expired }"
                      v-for="support in cx3Supports"
                      :key="support.pbxSupportPackageNumber"
                    >
                      <td>{{ support.description }}</td>
                      <td>
                        {{ getPrice(support.prices, period) }}
                        <span
                          v-if="userEditRight"                  
                          style="cursor: pointer"
                          @click="openSupportPriceModal(support)"
                          v-tooltip="'Edit Row Price'"
                          class="pull-right"
                        >
                          <em class="fa fa-edit fa-fw text-green"></em>
                        </span>
                      </td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            support.expired ? 'badge-warning' : 'badge-green'
                          "
                          >{{ support.expired }}
                        </span>
                      </td>
                      <td class="text-center">
                        <button
                          v-if="userEditRight"                  
                          v-tooltip="'Edit Package'"
                          @click="editSupport(support)"
                          type="button"
                          class="btn btn-sm btn-info m-1"
                        >
                          <em class="fa fa-edit fa-fw"></em>
                        </button>
                        <button
                          v-if="userEditRight"                  
                          v-tooltip="'Delete Package'"
                          type="button"
                          @click="deletePackage(support)"
                          class="btn btn-sm btn-primary"
                        >
                          <em class="fa fa-trash fa-fw"></em>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="alert alert-info">No Support Packages Available</div>
            </div>
          </div>
          <ModifyCx3SupportPackageModal
            ref="modifySupportPackageModal"
            :pricePackage="priceSupportPackage"
            @packageAdded="supportAdded"
            @packageEdited="loadCx3Supports()"
          />
          <AddPriceModal
            ref="addSupportPriceModal"
            :row-price="rowSupportPrice"
            :new-price="newSupportPrice"
            @addPrice="addSupportPrice"
            @pricesAdded="loadCx3Supports()"
          />
      </template>
      <template v-slot:call>
        <div class="row">
            <div class="col-md-12">
              <button
                v-if="userEditRight"                  
                type="button"
                class="btn btn-info"
                @click="createNewRecording()"
              >
                Create New Call Cabinet
              </button>
            </div>
          </div>
          <div class="row row-spacing mb-3">
            <div class="col-md-12">
              <div
                class="table-responsive table-bordered"
                v-if="cx3Recording.length > 0"
              >
                <table class="table">
                  <thead>
                    <tr class="text-nowrap">
                      <th>Description</th>
                      <th>Hours</th>
                      <th>MRC (excl. VAT)</th>
                      <th>Expired</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      :class="{ 'bg-primary': record.expired }"
                      v-for="record in cx3Recording"
                      :key="record.callCabinetPackageNumber"
                    >
                      <td>{{ record.description }}</td>
                      <td>{{ record.hours }}</td>
                      <td>
                        {{ getPrice(record.prices, period) }}
                        <span
                          v-if="userEditRight"                  
                          style="cursor: pointer"
                          @click="openRecordingPriceModal(record)"
                          v-tooltip="'Edit Row Price'"
                          class="pull-right"
                        >
                          <em class="fa fa-edit fa-fw text-green"></em>
                        </span>
                      </td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            record.expired ? 'badge-warning' : 'badge-green'
                          "
                          >{{ record.expired }}
                        </span>
                      </td>
                      <td class="text-center">
                        <button
                          v-if="userEditRight"                  
                          v-tooltip="'Edit Package'"
                          @click="editRecording(record)"
                          type="button"
                          class="btn btn-sm btn-info m-1"
                        >
                          <em class="fa fa-edit fa-fw"></em>
                        </button>
                        <button
                          v-if="userEditRight"                  
                          v-tooltip="'Delete Package'"
                          type="button"
                          @click="deletePackage(record)"
                          class="btn btn-sm btn-primary"
                        >
                          <em class="fa fa-trash fa-fw"></em>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="alert alert-info">No Call Cabinets Available</div>
            </div>
          </div>
          <ModifyCx3RecordingPackageModal
            ref="modifyRecordingPackageModal"
            :pricePackage="priceRecordingPackage"
            @packageAdded="recordingAdded"
            @packageEdited="loadCx3Recording()"
          />
          <AddPriceModal
            ref="addRecordingPriceModal"
            :row-price="rowRecordingPrice"
            :new-price="newRecordingPrice"
            @addPrice="addRecordingPrice"
            @pricesAdded="loadCx3Recording()"
          />
      </template>
    </ServiceScreenLayout>
  </div>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

import PriceFunctions from "@/components/Admin/PriceFunctions";
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
import ModifyCx3PackageModal from "@/components/Admin/Modals/ModifyCx3PackageModal";
import ModifyCx3SupportPackageModal from "@/components/Admin/Modals/ModifyCx3SupportPackageModal";
import ModifyCx3RecordingPackageModal from "@/components/Admin/Modals/ModifyCx3RecordingPackageModal";

export default {
  extends: PriceFunctions,
  components: {
      ServiceScreenLayout,
      ModifyCx3PackageModal,
      ModifyCx3SupportPackageModal,
      ModifyCx3RecordingPackageModal,
      AddPriceModal
  },
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      parts: [
        {
          name: "main",
          icon: "fa fa-archive",
          displayName: "Main Packages",
          permissions: ["STAFF"],
        },
        {
          name: "support",
          icon: "fa fa-plus",
          displayName: "Support Packages",
          permissions: ["STAFF"],
        },
        {
          name: "call",
          icon: "fa fa-phone",
          displayName: "Call Recording",
          permissions: ["STAFF"],
        },
      ],
      cx3Packages: [],
      cx3Supports: [],
      cx3Recording: [],

      rowPrice: [],
      rowSupportPrice: [],
      rowRecordingPrice: [],

      pricePackage: {},
      priceSupportPackage: {},
      priceRecordingPackage: {},

      newPrice: {},
      newSupportPrice: {},
      newRecordingPrice: {},

      period: this.getCurrentPeriod(),
    };
  },
  mounted() {
    this.loadCx3Packages();
    this.loadCx3Supports();
    this.loadCx3Recording();
  },
  methods: {
    loadCx3Packages() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/packages/product/" +
            this.productNumber + "/Cx3CloudPbxPackage"
        )
        .then(
          (response) => {
            this.cx3Packages = response.data;
            this.setNewPrice();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching 3CX Packages", error);
          }
        );
    },
    loadCx3Supports() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/packages/product/" +
            this.productNumber + "/PbxSupportPackage"
        )
        .then(
          (response) => {
            this.cx3Supports = response.data;
            this.setNewSupportPrice();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching 3CX Support Packages", error);
          }
        );
    },
    loadCx3Recording() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/packages/product/" +
            this.productNumber + "/CallCabinetPackage"
        )
        .then(
          (response) => {
            this.cx3Recording = response.data;
            this.setNewRecordingPrice();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching 3CX Call Cabinet Packages", error);
          }
        );
    },

    setNewPrice() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/new/price/Cx3CloudPbxPackagePrice"
        )
        .then(
          (response) => {
            this.newPrice = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Price Template", error);
          }
        );
    },
    setNewSupportPrice() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/new/price/PbxSupportPackagePrice"
        )
        .then(
          (response) => {
            this.newSupportPrice = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Support Price Template", error);
          }
        );
    },
    setNewRecordingPrice() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/new/price/CallCabinetPackagePrice"
        )
        .then(
          (response) => {
            this.newRecordingPrice = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Call Cabinet Price Template", error);
          }
        );
    },

    openPriceModal(cx3Package) {
      this.rowPrice = this.deepCopyObject(
        cx3Package.prices || []
      );
      this.newPrice.cx3CloudPbxPackageNumber = cx3Package.cx3CloudPbxPackageNumber;
      this.$refs.addPriceModal.open();
    },
    openSupportPriceModal(support) {
      this.rowSupportPrice = this.deepCopyObject(
        support.prices || []
      );
      this.newSupportPrice.pbxSupportPackageNumber = support.pbxSupportPackageNumber;
      this.$refs.addSupportPriceModal.open();
    },
    openRecordingPriceModal(record) {
      this.rowRecordingPrice = this.deepCopyObject(
        record.prices || []
      );
      this.newRecordingPrice.callCabinetPackageNumber = record.callCabinetPackageNumber;
      this.$refs.addRecordingPriceModal.open();
    },

    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
        }
      });
      this.rowPrice.push(newPrice);
      this.setNewPrice();
      this.newPrice.cx3CloudPbxPackageNumber = newPrice.cx3CloudPbxPackageNumber;
    },
    addSupportPrice(newPrice) {
      this.rowSupportPrice.forEach((price) => {
        if (price.validUntil == 0) {
          price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
        }
      });
      this.rowSupportPrice.push(newPrice);
      this.setNewSupportPrice();
      this.newPrice.pbxSupportPackageNumber = newPrice.pbxSupportPackageNumber;
    },
    addRecordingPrice(newPrice) {
      this.rowRecordingPrice.forEach((price) => {
        if (price.validUntil == 0) {
          price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
        }
      });
      this.rowRecordingPrice.push(newPrice);
      this.setNewRecordingPrice();
      this.newPrice.callCabinetPackageNumber = newPrice.callCabinetPackageNumber;
    },

    editPackage(cx3Package) {
      this.pricePackage = this.deepCopyObject(cx3Package);
      this.$refs.modifyPackageModal.open();
    },
    editSupport(support) {
      this.priceSupportPackage = this.deepCopyObject(support);
      this.$refs.modifySupportPackageModal.open();
    },
    editRecording(record) {
      this.priceRecordingPackage = this.deepCopyObject(record);
      this.$refs.modifyRecordingPackageModal.open();
    },

    createNewPackage() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/new/package/Cx3CloudPbxPackage"
        )
        .then(
          (response) => {
            this.pricePackage = response.data;
            this.pricePackage.productNumber = this.productNumber;
            this.$refs.modifyPackageModal.open();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Package Template", error);
          }
        );
    },
    createNewSupport() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/new/package/PbxSupportPackage"
        )
        .then(
          (response) => {
            this.priceSupportPackage = response.data;
            this.priceSupportPackage.productNumber = this.productNumber;
            this.$refs.modifySupportPackageModal.open();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Support Template", error);
          }
        );
    },
    createNewRecording() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/new/package/CallCabinetPackage"
        )
        .then(
          (response) => {
            this.priceRecordingPackage = response.data;
            this.priceRecordingPackage.productNumber = this.productNumber;
            this.$refs.modifyRecordingPackageModal.open();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Call Cabinet Template", error);
          }
        );
    },

    packageAdded(cx3Package) {
      this.cx3Packages.push(cx3Package);
      this.openPriceModal(cx3Package);
    },
    supportAdded(support) {
      this.cx3Supports.push(support);
      this.openSupportPriceModal(support);
    },
    recordingAdded(record) {
      this.cx3Recording.push(record);
      this.openRecordingPriceModal(record);
    },

    deletePackage(cx3package) {
      this.$swal({
        title: "Are you sure you want to delete this Package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/pricepackages/" +
                cx3package.objKey +
                "?objClassName=" +
                cx3package.class
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.loadCx3Packages();
                this.loadCx3Supports();
                this.loadCx3Recording();
              },
              (error) => {
                this.showError("Error Deleting Price Package", error);
              }
            );
        }
      });
    },
  },
};
</script>
