<template>
  <div>
    <div class="row row-spacing">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <ClientRequiredDocumentation :clientNumber="clientNumber"
              :outstandingDocuments="clientMetaData.outstandingDocuments" @documentUploaded="documentUploaded" />
          </div>
        </div>
        <hr v-if="clientMetaData.outstandingDocuments.length > 0">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-8">
            <div class="form-row align-items-center">
              <div class="form-group col-md-6">
                <label>Search Documents</label>
                <div class="input-group"> 
                  <input type="text" class="form-control" v-model="searchText" placeholder="Filter on Description or Type" />
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <UploadDocument :paperTypes="paperTypes" :clientSales="clientSales" :clientNumber="clientNumber" @updateCorrespondence="updateCorrespondence" />
          </div>
        </div>
        <hr>
        <Correspondence @fetchCorrespondence="fetchCorrespondence" :correspondence="filteredCorrespondence"
          :context="'client'" :paperTypes="paperTypes" :clientSales="clientSales" />
      </div>
    </div>
  </div>
</template>

<script>
  import ClientFunctions from '../ClientFunctions';
  import ClientRequiredDocumentation from '@/components/Clients/ClientRequiredDocumentation'
  import Correspondence from '@/components/Documents/Correspondence'
  import UploadDocument from '@/components/Documents/UploadDocument'
  export default {
    extends: ClientFunctions,
    components: {
      ClientRequiredDocumentation,
      Correspondence,
      UploadDocument
    },
    computed: {
      filteredCorrespondence() {
        return this.correspondence.filter(c =>
          c.description.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1 ||
          c.type.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1);
      }
    },
    data() {
      return {
        persons: [],
        correspondence: [],
        paperTypes: [],
        searchText: '',
        paperUpload: {
          file: {}
        },
        clientMetaData: {
          outstandingDocuments: []
        },
        showInvoices: false,
        clientNumber: null,
        clientSales: [],
        selectedSales: []
      }
    },
    created() {
      this.clientNumber = parseInt(this.$route.params.clientNumber);
      this.getClientMetaData(this.clientNumber).then(response => {
          this.clientMetaData = response.data;
        },
        error => {
          this.showError("Error Fetching Client Meta Data", error);
          console.error(error);
        })

      this.$http.get(this.$config.aimsAPI + "sales/client/" + this.clientNumber).then(
        response => {
          this.clientSales = response.data;
        },
        error => {
          console.error(error);
          this.showError("Error Fetching Sales for Client", error);
        }
      );
    },
    mounted() {
      $('.responsive-tabs').responsiveTabs({
        accordionOn: ['xs', 'sm']
      });
      document.title = this.clientNumber + ' - Correspondence';
      this.$http.get(this.$config.aimsAPI + 'clients/' + this.clientNumber + '/users')
        .then(response => {
          this.persons = response.data;
        }, error => {
          console.error(error);
          this.showError('Error Fetching Docs for Client Users', error);
        });

      this.$http.get(this.$config.aimsAPI + 'clients/correspondence/types')
        .then(response => {
          this.paperTypes = response.data;
          this.fetchCorrespondence();
        }, error => {
          console.error(error);
          this.showError("Error Fetching Correspondence Types", error);
        });
    },
    methods: {
      getLabel({
        firstnames,
        lastname
      }) {
        return firstnames + ' ' + lastname;
      },
      fetchCorrespondence() {
        this.$http.get(this.$config.aimsAPI + 'clients/' + this.clientNumber +
            '/correspondence')
          .then(response => {
            this.correspondence = response.data;
          }, error => {
            console.error(error);
            this.showError("Error Fetching Client Correspondence", error);
          });
      },
      documentUploaded(type) {
        this.clientMetaData.outstandingDocuments.splice(this.clientMetaData.outstandingDocuments.findIndex(o => o ==
          type), 1);
          this.fetchCorrespondence();
      },
      updateCorrespondence(data) {
        for (let i = 0; i < data.length; i++) {
          this.correspondence.unshift(data[i]);
        }
      }
    }
  }

</script>

<style scoped>
textarea {
  resize: none;
}

.word-counter {
  position: absolute;
  right: 18;
  bottom: 0;
  z-index: 1000;
}
</style>
