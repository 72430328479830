<template>
  <service-screen-layout :parts="parts">
    <template v-slot:cabinet-rental>
      <CabinetRentalDetails
        :cabinetRental="saleDefinition.sale.cabinetRental"
        @saveSale="saveSale"
      />
    </template>
    <template v-slot:cross-connect>
      <CrossConnects
        :crossConnects="saleDefinition.sale.crossConnect"
        :newCrossConnect="saleDefinition.meta.crossConnect.objMeta.new"
        :crossConnectTypes="saleDefinition.meta.crossConnect.objMeta.enums.type"
        :cabinetRental="saleDefinition.sale.cabinetRental"
        @getSaleDefinition="getSaleDefinition"
      />
    </template>
    <template v-slot:cabinet-addons>
      <CabinetAddons
        :cabinetAddons="saleDefinition.sale.cabinetAddons"
        :newCabinetAddon="saleDefinition.meta.cabinetAddons.objMeta.new"
        :cabinetAddonTypes="saleDefinition.meta.cabinetAddons.objMeta.enums.type"
        :cabinetRental="saleDefinition.sale.cabinetRental"
        @getSaleDefinition="getSaleDefinition"
      />
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";

import CabinetRentalDetails from "@/components/ObjParts/SingleCaptured/CabinetRentalDetails";
import CrossConnects from "@/components/ObjParts/MultipleCaptured/CrossConnects";
import CabinetAddons from "@/components/ObjParts/MultipleCaptured/CabinetAddons";

export default {
  components: {
    ServiceScreenLayout,
    CabinetRentalDetails,
    CrossConnects,
    CabinetAddons
  },
  data() {
    return {
      parts: [
        {
          name: "cabinet-rental",
          icon: "fa-solid fa-server",
          displayName: "Cabinet Rental",
          permissions: ["*"],
        },
        {
          name: "cross-connect",
          icon: "fa-solid fa-link",
          displayName: "Cross Connect",
          permissions: ["*"],
        },
        {
          name: "cabinet-addons",
          icon: "fa-solid fa-square-plus",
          displayName: "Cabinet Addons",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
          cabinetRental: {
            dataCentre: {},
          },
          crossConnect: [],
          cabinetAddons: []
        },
        meta: {
          crossConnect: {
            objMeta: {
              enums: {
                type: []
              },
              new: {}
            }
          },
          cabinetAddons: {
            objMeta: {
              enums: {
                type: []
              },
              new: {}
            }
          }
        }
      },
      saleNumber: null,
    };
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    await this.getSaleDefinition();
  },
  methods: {
    getSaleDefinition: function() {
      const hostUrl = this.$config.aimsAPIv2;
      const saleNumber = this.saleNumber;

      this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
        (response) => {
          this.saleDefinition = response.data;       
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      )
    },
    saveSale: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const hostUrl = this.$config.aimsAPIv2;
          const saleNumber = this.saleNumber;

          this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.getSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
              }
            );
        }
      });
    },
  },
};
</script>