<template>
  <div>
    <form class="row" novalidate>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Address Type') }"
            >
              <label>Address Type *</label>
              <select
                name="Address Type"
                v-validate="'required'"
                class="form-control"
                v-model="address.addressType"
                @change="addressChanged()"
              >
                <option
                  :value="addressType.name"
                  v-for="addressType in addressTypes"
                  :key="addressType.name"
                >
                  {{ addressType.description }}
                </option>
              </select>
              <span class="has-error" v-if="errors.has('Address Type')">{{
                errors.first("Address Type")
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="form-group">
              <label>Sub Premise</label>
              <input
                placeholder="Sub Premise"
                name="Subpremise"
                class="form-control"
                v-model="address.subpremise"
                @change="addressChanged()"
              />
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Street Number') }"
            >
              <label>Number *</label>
              <input
                placeholder="Street Number"
                name="Street Number"
                v-validate="'required'"
                class="form-control"
                v-model="address.streetNumber"
                @change="addressChanged()"
              />
              <span class="has-error" v-if="errors.has('Street Number')">{{
                errors.first("Street Number")
              }}</span>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Street Name') }"
            >
              <label>Street Name *</label>
              <input
                placeholder="Street Name"
                name="Street Name"
                v-validate="'required'"
                class="form-control"
                v-model="address.streetName"
                @change="addressChanged()"
              />
              <span class="has-error" v-if="errors.has('Street Name')">{{
                errors.first("Street Name")
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6" :class="{ 'has-error': errors.has('Suburb') }">
            <div class="form-group">
              <label>Suburb *</label>
              <input
                placeholder="Suburb"
                name="Suburb"
                v-validate="'required'"
                class="form-control"
                v-model="address.suburb"
                @change="addressChanged()"
              />
              <span class="has-error" v-if="errors.has('Suburb')">{{
                errors.first("Suburb")
              }}</span>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('City') }"
            >
              <label>City *</label>
              <input
                placeholder="City"
                name="City"
                v-validate="'required'"
                class="form-control"
                v-model="address.city"
                @change="addressChanged()"
              />
              <span class="has-error" v-if="errors.has('City')">{{
                errors.first("City")
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Province') }"
            >
              <label>Province *</label>
              <select
                name="Province"
                v-validate="'required'"
                class="form-control"
                v-model="address.province"
                @change="addressChanged()"
              >
                <option value="Eastern Cape">Eastern Cape</option>
                <option value="Western Cape">Western Cape</option>
                <option value="Northern Cape">Northern Cape</option>
                <option value="North West">North West</option>
                <option value="Freestate">Freestate</option>
                <option value="Gauteng">Gauteng</option>
                <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                <option value="Limpopo">Limpopo</option>
                <option value="Mpumalanga">Mpumalanga</option>
              </select>
              <span class="has-error" v-if="errors.has('Province')">{{
                errors.first("Province")
              }}</span>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Postal Code') }"
            >
              <label>Postal Code *</label>
              <input
                placeholder="Postal Code"
                name="Postal Code"
                v-validate="'required'"
                class="form-control"
                v-model="address.postalCode"
                @change="addressChanged()"
              />
              <span class="has-error" v-if="errors.has('Postal Code')">{{
                errors.first("Postal Code")
              }}</span>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Country') }"
            >
              <label>Country *</label>
              <select
                name="Country"
                v-validate="'required'"
                class="form-control"
                v-model="address.country"
                @change="addressChanged()"
              >
                <option value="South Africa">South Africa</option>
              </select>
              <span class="has-error" v-if="errors.has('Country')">{{
                errors.first("Country")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    addressStructure: {
      type: Object,
      required: false,
    },
    addressTypes: {
      type: Array,
      required: false,
    },
  },
  watch: {
    addressStructure: {
      handler(to, from) {
        this.address = this.deepCopyObject(this.addressStructure);
      },
      immediate: true,
    },
    addressTypes: {
      handler(to, from) {
        //set default address type to first in list
        if (
          to &&
          to.length > 0 &&
          (!this.address.addressType ||
            this.address.addressType === "" ||
            this.address.addressType.includes("None"))
        ) {
          this.address.addressType = this.addressTypes[0].name;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      address: {},
    };
  },
  methods: {
    addressChanged() {
      this.$validator.validateAll().then((result) => {
        this.$emit("addressValid", result);
      });
      this.$emit("addressChanged", this.address);
    },
  },
};
</script>

<style>
</style>
