<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <button
          v-if="userEditRight"
          type="button"
          class="btn btn-info"
          @click="createNewPackage()"
        >
          Create New Package
        </button>
      </div>
    </div>
    <div class="row row-spacing mb-3">
      <div class="col-md-12">
        <div
          class="table-responsive table-bordered"
          v-if="wanatelPackages.length > 0"
        >
          <table class="table">
            <thead>
              <tr class="text-nowrap">
                <th>Description</th>
                <th>Max Extensions</th>
                <th>Extra extension MRC (excl. VAT)</th>
                <th>MRC (excl. VAT)</th>
                <th>Expired</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="{ 'bg-primary': wanatelPackage.expired }"
                v-for="wanatelPackage in wanatelPackages"
                :key="wanatelPackage.wanatelCloudPbxPackageNumber"
              >
                <td>{{ wanatelPackage.description }}</td>
                <td class="text-nowrap text-center">
                  {{ wanatelPackage.maxExtensions }}
                </td>
                <td class="text-nowrap">
                  {{ getExtensionPrice(wanatelPackage.prices, period) }}
                </td>
                <td>
                  {{ getPrice(wanatelPackage.prices, period) }}
                  <span
                    v-if="userEditRight"
                    style="cursor: pointer"
                    @click="openPriceModal(wanatelPackage)"
                    v-tooltip="'Edit Row Price'"
                    class="pull-right"
                  >
                    <em class="fa fa-edit fa-fw text-green"></em>
                  </span>
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      wanatelPackage.expired ? 'badge-warning' : 'badge-green'
                    "
                    >{{ wanatelPackage.expired }}
                  </span>
                </td>
                <td class="text-center">
                  <button
                    v-if="userEditRight"
                    v-tooltip="'Edit Package'"
                    @click="editPackage(wanatelPackage)"
                    type="button"
                    class="btn btn-sm btn-info m-1"
                  >
                    <em class="fa fa-edit fa-fw"></em>
                  </button>
                  <button
                    v-if="userEditRight"                  
                    v-tooltip="'Delete Package'"
                    type="button"
                    @click="deletePackage(wanatelPackage)"
                    class="btn btn-sm btn-primary"
                  >
                    <em class="fa fa-trash fa-fw"></em>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="alert alert-info">No Wanatel Packages Available</div>
      </div>
    </div>
    <ModifyWanatelPackageModal
      ref="modifyPackageModal"
      :pricePackage="pricePackage"
      @packageAdded="packageAdded"
      @packageEdited="loadWanatelPackages()"
    />
    <WanatelPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="loadWanatelPackages()"
    />
  </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";
import WanatelPriceModal from "@/components/Admin/Modals/WanatelPriceModal";
import ModifyWanatelPackageModal from "@/components/Admin/Modals/ModifyWanatelPackageModal";

export default {
  extends: PriceFunctions,
  components: {
      ModifyWanatelPackageModal,
      WanatelPriceModal
  },
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      wanatelPackages: [],
      period: this.getCurrentPeriod(),
      rowPrice: [],
      pricePackage: {},
      newPrice: {},
    };
  },
  mounted() {
    this.loadWanatelPackages();
  },
  methods: {
    loadWanatelPackages() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/packages/product/" +
            this.productNumber + "/WanatelCloudPbxPackage"
        )
        .then(
          (response) => {
            this.wanatelPackages = response.data;
            this.setNewPrice();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Wanatel Packages", error);
          }
        );
    },
    setNewPrice() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/new/price/WanatelCloudPbxPackagePrice"
        )
        .then(
          (response) => {
            this.newPrice = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Price Template", error);
          }
        );
    },
    openPriceModal(wanatelPackage) {
      this.rowPrice = this.deepCopyObject(
        wanatelPackage.prices || []
      );
      this.newPrice.wanatelCloudPbxPackageNumber = wanatelPackage.wanatelCloudPbxPackageNumber;
      this.$refs.addPriceModal.open();
    },
    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
        }
      });
      this.rowPrice.push(newPrice);
      this.setNewPrice();
      this.newPrice.wanatelCloudPbxPackageNumber = newPrice.wanatelCloudPbxPackageNumber;
      
    },
    editPackage(wanatelPackage) {
      this.pricePackage = this.deepCopyObject(wanatelPackage);
      this.$refs.modifyPackageModal.open();
    },
    createNewPackage() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/new/package/WanatelCloudPbxPackage"
        )
        .then(
          (response) => {
            this.pricePackage = response.data;
            this.pricePackage.productNumber = this.productNumber;
            this.$refs.modifyPackageModal.open();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Package Template", error);
          }
        );
    },
    packageAdded(wanatelPackage) {
      this.wanatelPackages.push(wanatelPackage);
      this.openPriceModal(wanatelPackage);
    },
    deletePackage(wanatelPackage) {
      this.$swal({
        title: "Are you sure you want to delete this Package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/pricepackages/" +
                wanatelPackage.objKey +
                "?objClassName=" +
                wanatelPackage.class
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.loadWanatelPackages();
              },
              (error) => {
                this.showError("Error Deleting Price Package", error);
              }
            );
        }
      });
    },
    getExtensionPrice(data, period, vat) {
      if (data) {
        if (data.length > 0) {
          let price = data.filter((price) => period >= price.validFrom);
          price.forEach((newPrice) => {
            if (newPrice.validUntil != 0) {
              price = price.filter((price) => period <= price.validUntil);
            } else {
              price = newPrice;
            }
          });
          if (Array.isArray(price)) {
            if (price.length > 0) {
              if (vat) {
                return this.timesVAT(
                  parseFloat(price[0].pricePerAdditionalExtensionExVat)
                );
              } else {
                return this.currencyFormat(
                  parseFloat(price[0].pricePerAdditionalExtensionExVat)
                );
              }
            } else {
              return "R " + 0;
            }
          } else {
            if (vat) {
              return this.timesVAT(
                parseFloat(price.pricePerAdditionalExtensionExVat)
              );
            } else {
              return this.currencyFormat(
                parseFloat(price.pricePerAdditionalExtensionExVat)
              );
            }
          }
        } else {
          return "R " + 0;
        }
      } else {
        return 0;
      }
    },
  },
};
</script>
