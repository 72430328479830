<template>
    <div class="col-md-12">
        <div class="row">
            <button class="btn btn-info" @click="addSimChange">Add SIM Change</button>
        </div>
        <div class="row row-spacing">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Change</th>
                            <th>NRC (excl. VAT)</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="changes.length > 0">
                        <tr v-for="change in changes" :key="change.apnSimChangeNumber">
                            <td>{{ dateFormat(change.date) }}</td>
                            <td>{{ change.change }}</td>
                            <td>{{ change.nrcExclVat }}</td>
                            <td class="text-center">
                                <button class="btn btn-info" @click="editSimChange(change)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-primary" @click="deleteSimChange(change)"  v-allowed:view="['STAFF']">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">No changes</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <IonlineSimChangeModal 
            ref="simChangeModal"
            :modalTitle="modalTitle"
            :simChange="simChange"
            @saveSimChange="saveSimChange"
        />
    </div>
</template>

<script>
import IonlineSimChangeModal from '@/components/Admin/Modals/IonlineSimChangeModal.vue';

export default {
    components: {
        IonlineSimChangeModal
    },
    props: {
        apn: {
            type: Object,
            required: true
        },
        changes: {
            type: Array,
            required: true
        },
        changeObj: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            modalTitle: "",
            simChange: {}
        }
    },
    methods: {
        addSimChange: function() {
            this.modalTitle = "Add sim change";
            this.simChange = this.deepCopyObject(this.changeObj);
            this.$refs.simChangeModal.open();
        },
        editSimChange: function(change) {
            this.modalTitle = "Edit sim change";
            this.simChange = change;
            this.$refs.simChangeModal.open();
        },
        deleteSimChange: function(change) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.apn.saleNumber;

            this.$swal({
                title: "Are you sure you want to delete this sim change?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
            }).then((result) => {  
                if (result.value) {
                    this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete`, change).then(
                        (response) => {
                            this.showSuccess("Sim change successfully deleted");
                            this.$emit("reloadSale");
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error deleting sim change", error);
                    });
                }                  
            });
        },
        saveSimChange: function(change) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.apn.saleNumber;

            if (change.apnSimChangeNumber === 0) {
                this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, change).then(
                    (response) => {
                        this.showSuccess("Successfully added sim change");
                        this.$emit('reloadSale');
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error adding new sim change", error);
                    }
                ).finally(() => {
                    this.$refs.simChangeModal.$refs.modifySimChangeModal.close();
                });

            } else {
                this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, change).then(
                    (response) => {
                        this.showSuccess("Successfully edited sim change");
                        this.$emit('reloadSale');
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error editing new sim change", error);
                    }
                ).finally(() => {
                    this.$refs.simChangeModal.$refs.modifySimChangeModal.close();
                });
            }       
        }
    }
}
</script>

<style lang="scss" scoped>

</style>