<template>
<div>
    <ul class="nav nav-pills nav-fill" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="virtualserver-tab"
          data-toggle="tab"
          href="#virtualserverPackage"
          role="tab"
          aria-controls="virtualserverPackage"
          aria-selected="true"
          ><i class="fa fa-dropbox"></i> Virtual Server Packages</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="splaPackage-tab"
          data-toggle="tab"
          href="#splaPackage"
          role="tab"
          aria-controls="splaPackage"
          aria-selected="true"
          ><i class="fa fa-plus-square"></i> SPLA Packages</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane active"
        id="virtualserverPackage"
        role="tabpanel"
        aria-labelledby="virtualserverPackage-tab"
      >
        <VirtualServerPackagePrices
          ref="packages"
          :virtualServerPackages="virtualServerPackages"
          :emptyPackage="emptyPackage"
          :productNumber="productNumber"
          @get-virtualserver-packages="getVirtualServerPackages"
          :userEditRight="userEditRight"          
        > </VirtualServerPackagePrices> 
 
      </div>

      <div
        class="tab-pane"
        id="splaPackage"
        role="tabpanel"
        aria-labelledby="splaPackage-tab"
      >
        <SplaPackagePrices
            :productNumber="productNumber"
            :packages="splaPackages"
            :emptySplaPackage="emptySplaPackage"
            @get-spla-packages="getSplaPackages"
            :userEditRight="userEditRight"          
            />
      </div>
    </div>
    
</div>
</template>

<script>
import VirtualServerPackagePrices from "@/components/Admin/VirtualServerPackages/VirtualServerPackagePrices";
import SplaPackagePrices from "@/components/Admin/VirtualServerPackages/SplaPackagePrices";

export default {
    props: {
        productNumber: {
            type: Number,
            required: true
        },
        userEditRight: {
            type: Boolean,
            required: true
        }
    },
    components: {
        VirtualServerPackagePrices,
        SplaPackagePrices,
    },
    computed: {
       
    },
    data() {
        return {
          virtualServerPackages: [],
          splaPackages: [],
          emptyPackage: {},
          emptySplaPackage: {}
        }
    },
    mounted() {
        this.getSplaPackages();
        this.getVirtualServerPackages();
    },
    methods: {
        getVirtualServerPackages() {
            this.$http.get(this.$config.aimsAPI + 'pricing/virtualserverpackages/product/' + this.productNumber).then(response => {
                this.virtualServerPackages = this.deepCopyObject(response.data.virtualserverpackages);                
                this.emptyPackage = response.data;                 
            }, error => {
                console.error(error);
                this.showError('Error Fetching Virtual Server Packages', error);
            });
        },
        getSplaPackages() {
            this.$http.get(this.$config.aimsAPI + 'pricing/splapackages/product/' + this.productNumber).then(response => {                
                this.splaPackages = this.deepCopyObject(response.data.splapackages);                
                this.emptySplaPackage = response.data;                
            }, error => {
                console.error(error);
                this.showError('Error Fetching SPLA Packages', error);
            });
        }
 
    },
}
</script>
<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}

.nav-pills .nav-link {
  background-color: #f3f2ee;
}

a {
  color: rgba(0, 0, 0, 0.6);
}
</style>