<template>
    <modal
        ref="addSaleTagModal"
        :title="modalTitle"
        :size="'modal-lg'"
        @save="validateFields(saleTag, saleTagValue)"
        @close="close" 
        @dismiss="resetInputs"
    >
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" :class="{ 'has-error': errors.has('Name') }">
                        <label>Name</label>
                        <div class="row">
                            <div class="col-md-12">
                                <v-select
                                    ref="tagNameList"
                                    placeholder="Select Name"
                                    :options="allClientSaleTags"
                                    v-if="useExistingTags === true"
                                    v-model="existingTag.saleTagNumber"
                                    :get-option-label="getLabel"
                                    valueProp="saleTagNumber"
                                >
                                </v-select>
                                <input 
                                    ref="tagName"
                                    type="text" 
                                    class="form-control" 
                                    v-model="saleTag.name" 
                                    name="Name" 
                                    :class="{ 'has-error': errors.has('Name') }"
                                    v-validate="'required'"
                                    v-else
                                >
                                <small class="has-error" v-if="errors.has('Name')">{{ errors.first("Name") }}</small>
                            </div>
                        </div>
                        <div class="row row-spacing" v-allowed:view="['ADMIN', 'CLIENT_ADMIN', 'MANAGER']" v-if="allClientSaleTags.length > 0">
                            <div class="col-md-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" @change="toggleInput" ref="tagCheckbox" :checked="useExistingTags">
                                    <label class="form-check-label">Use existing Tag</label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" v-if="useExistingTags != true">
                    <div class="form-group" :class="{ 'has-error': errors.has('Colour') }">
                        <label>Colour</label>
                        <input 
                            ref="tagColour"
                            type="color" 
                            class="form-control" 
                            v-model="saleTag.colour" 
                            style="cursor: pointer" 
                            name="Colour"
                            :class="{ 'has-error': errors.has('Colour') }"
                            v-validate="'required'"
                        >
                        <small class="has-error" v-if="errors.has('Colour')">{{ errors.first("Colour") }}</small>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group" :class="{ 'has-error': errors.has('Value') }">
                        <label>Value</label>
                        <input 
                            ref="tagValue"
                            type="text" 
                            class="form-control" 
                            v-model="saleTagValue.value" 
                            name="Value"
                            :class="{ 'has-error': errors.has('Value') }"
                            v-validate="'required'"
                        >
                        <small class="has-error" v-if="errors.has('Value')">{{ errors.first("Value") }}</small>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal,
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        saleTag: {
            type: Object,
            required: true
        },
        saleTagValue: {
            type: Object,
            required: true
        },
        allClientSaleTags: {
            type: Array,
            required: true
        },
        saleTags: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            saleTagSaved: {},
            existingTag: {},
            useExistingTags: true,
        }
    },
    methods: {
        open: function() {
          this.$refs.addSaleTagModal.open();        
          
          if (this.allClientSaleTags.length > 0) {
            this.useExistingTags = true;
          } else {
            this.useExistingTags = false;
          }       
        },
        close: function() {
            this.resetInputs();
        },
        getLabel({ name }) {
            return name;
        },
        toggleInput: function() {
            if (this.$refs.tagCheckbox.checked === true) {
                this.useExistingTags = true;
            } else {
                this.useExistingTags = false;
            }
                       
        },
        validateFields: function(saleTag, saleTagValue) {
             this.$validator.validateAll().then((valid) => {        
                if (valid) { 
                    this.saveSaleTag(saleTag, saleTagValue);
                }
             })
        },
        async saveSaleTag(saleTag, saleTagValue) { 

            if (this.existingTag.saleTagNumber) {                
                saleTag.saleTagNumber = this.existingTag.saleTagNumber;
                saleTag.colour = this.existingTag.colour;
            }
            const hostUrl = this.$config.aimsAPI;

            if (saleTag) {
                //Saving SaleTag (for when you are creating a new one) needs required ADMIN permission
                if (this.userContainsPermission(['ADMIN', 'CLIENT_ADMIN', 'MANAGER'])) {
                    console.log('save saletag');
                    console.log(saleTag);
                    await this.$http.post(`${hostUrl}saletags/save`, saleTag).then(
                        (response) => {
                            this.saleTagSaved = response.data;  
                            console.log("saletag saved");
                            console.log(this.saleTagSaved); 
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error creating Sale Tag", error);
                        }
                    );
                } else {
                    //if you don't have right to save saletag, then use existing one
                    this.saleTagSaved = this.existingTag; 
                }

                if (this.saleTagSaved) {                    
                    saleTagValue.saleTagNumber = this.saleTagSaved.saleTagNumber;                    
                    console.log('save saletagvalue');   
                    console.log(saleTagValue);
                    await this.$http.post(`${hostUrl}saletags/save/value`, saleTagValue).then(
                        (response) => {
                            this.showSuccess("Sale Tag successfully created");
                            this.$emit("reloadTags");
                            this.$emit("reloadSaleTags");
                            this.$refs.addSaleTagModal.close(); 
                            this.resetInputs();
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error creating Sale Tag Value", error);
                        }
                    );
                } 
            }

        },
        resetInputs: function() {
            if (this.$refs.tagNameList) {
               this.$refs.tagNameList.clearSelection();
            }
            if (this.$refs.tagName) {
                this.$refs.tagName.value = null;
            }
            if (this.$refs.tagColour) {
                this.$refs.tagColour.value = null;
            }
            if (this.$refs.tagValue._data) {
                this.$refs.tagValue.value = null;
            }
            if (this.$refs.tagCheckbox) {
                this.$refs.tagCheckbox.checked = true;
            }

            this.useExistingTags = true;            
        }
    },
}
</script>
