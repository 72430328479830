import { render, staticRenderFns } from "./ClientStatement.vue?vue&type=template&id=4bc5bda0&scoped=true"
import script from "./ClientStatement.vue?vue&type=script&lang=js"
export * from "./ClientStatement.vue?vue&type=script&lang=js"
import style0 from "./ClientStatement.vue?vue&type=style&index=0&id=4bc5bda0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc5bda0",
  null
  
)

export default component.exports