<template>
  <div>
    <LoginLayout
      :loginHeading="'Forgot Password?'"
      :primaryButtonText="'Email Link'"
      :sendingMailErrorMessage="sendingMailError.message"
      :showErrorMessage="sendingMailError.show"
      :sendingMail="sendingMail"
      @submit="requestPassword"
    >
      <form class="mt-3" @submit.prevent="requestPassword()"  @keyup.enter="requestPassword()">
        <div class="row row-spacing" v-if="emailSent === false || sendingMailError.show">
          <div class="col-md-12">
             <p class="form-text text-white">Enter the email address linked to your account and a link will be emailed to you!</p>
          </div>
        </div>
        <div class="row row-spacing" v-if="emailSent === true && !sendingMailError.show">
          <div class="col-md-12">
             <p class="form-text text-white">A reset link has been sent to your email. Check your <strong>spam folder</strong> if you're unable to see it.</p>
          </div>
        </div>
        <div class="row row-spacing">
          <div class="col-md-12">
            <label class="text-white">Email</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Enter your email..." autocomplete="off" v-model="loginCredentials.userName" required>
              <div class="input-group-append">
                <span class="input-group-text bg-white"><i class="fa fa-envelope-o"></i></span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </LoginLayout>
  </div>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout';
  export default {
    components: {
      LoginLayout
    },
    data() {
      return {
        loginCredentials: {
          userName: "",
        },
        sendingMailError: {
          message: "",
          show: false
        },
        sendingMail: false,
        emailSent: false,
      }
    },
    mounted() {
      document.title = 'Forgot password | Service Panel';
    },
    methods: {
      requestPassword: function() {
        this.sendingMailError.show = false;
        this.sendingMail = true;
        this.emailSent = true;
        const hostUrl = this.$config.aimsAPI;
        const userCredentials = this.loginCredentials;

        this.$http.post(`${hostUrl}passwordrecovery/forgotpassword`, userCredentials).then(
          (response) => {
            setTimeout(this.showSuccess(response.data.message), 1500);
          },
          (error) => {
            if (error.status === 0) {
              error.data = {
                message: "Could not contact server"
              };
            };

            this.sendingMailError.message = error.data.message;
            this.sendingMailError.show = true;
          }).finally(() => {
            this.sendingMail = false;
          });
      }
    },  
  }
</script>