    var aimsAPI = 'http://localhost:8080/api/v1/';
    var aimsAPIv2 = 'http://localhost:8080/api/v2/';
    var aimsAPIvLegacy = 'http://localhost:8080/api/vLegacy/';
    var localUrl = 'http://localhost:8081/'

    var feasibilityAPI = 'http://localhost:9090/v1/';
    var servicePanelTheme = 'servicepanel.demo/theme.css';

    var isDev = true;
    var isDemo = true;
    //remove this after dashboard launch
    var enableDashboard = true;
    //
    var browserTitle = 'DEVELOPMENT - Service Panel';
    var baseUrl = 'https://adept.servicepanel.co.za';
    var urlToAIMS = 'https://staff.adept.co.za/portal/staff';
    var urlToHelpdesk = 'https://helpdesk.adept.co.za/helpdesk/WebObjects/Helpdesk.woa/wa/TicketActions/view?ticket='
    var urlToNCentral = 'https://manage.adept.co.za/deepLinkAction.do?method=deviceService&deviceID='
    var urlToPipedrive = 'https://adept-ict.pipedrive.com/deal/'
    var urlToServicePanel = baseUrl;
    var runInForeground = false;

   if(window.origin.indexOf('live-test.servicepanel.co.za') != -1){
         aimsAPI = 'https://live-test-api.servicepanel.co.za/api/v1/'
         aimsAPIv2 = 'https://live-test-api.servicepanel.co.za/api/v2/'
         aimsAPIvLegacy = 'https://live-test-api.servicepanel.co.za/api/vLegacy/'
         feasibilityAPI = 'http://live-test-api.servicepanel.co.za:8082/feasibility/api/'
         browserTitle = "Live Test - Adept Service Panel"
    }
    else if(window.origin.indexOf('dev-test.servicepanel.co.za') != -1){
                  aimsAPI = 'https://dev-test-api.servicepanel.co.za/api/v1/'
                  aimsAPIv2 = 'https://dev-test-api.servicepanel.co.za/api/v2/'
                  aimsAPIvLegacy = 'https://dev-test-api.servicepanel.co.za/api/vLegacy/'
                  feasibilityAPI = 'http://dev-test-api.servicepanel.co.za:8082/feasibility/api/'
                  browserTitle = "Dev Test - Adept Service Panel"
    } else if(window.origin.indexOf('adept.servicepanel.co.za') != -1){
        aimsAPI = 'https://api.servicepanel.co.za/api/v1/'
        aimsAPIv2 = 'https://api.servicepanel.co.za/api/v2/'
        aimsAPIvLegacy = 'https://api.servicepanel.co.za/api/vLegacy/'
        isDev = false;
        isDemo = false;
        browserTitle = "Adept Service Panel";
        servicePanelTheme = 'adept.servicepanel.co.za/theme.css';
    }else{
        console.warn('Could not determine env, defaulting to local');
    }

    export default {
        aimsAPI,
        aimsAPIv2,
        aimsAPIvLegacy,
        feasibilityAPI,
        isDev,
        isDemo,
        enableDashboard,
        browserTitle,
        servicePanelTheme,
        urlToAIMS,
        urlToHelpdesk,
        urlToServicePanel,
        runInForeground,
        urlToNCentral,
        urlToPipedrive
    }
