<template>
  <div class="card card-default">
    <div class="card-body">
      <div class="table-responsive table-bordered" v-if="managedDevices.length > 0">
        <table class="table">
          <thead>
            <tr>
              <th>Device Name</th>
              <th>Class</th>
              <th>Profile</th>
              <th>Supporting Service</th>
              <th></th>
            </tr>
          </thead>
          <thead>
            <tr v-for="device in managedDevices" :key="device.managedDeviceNumber">
              <td>{{device.nCentralDeviceName}}</td>
              <td>{{device.deviceClass}}</td>
              <td>{{device.profile}}</td>
              <td>
                <select class="form-control" v-model="device.supportPackageNumber">
                  <option v-for="supportPackage in supportPackages" :key="supportPackage.supportPackageNumber"
                    :value="supportPackage.supportPackageNumber">
                    {{supportPackage.description}}
                  </option>
                </select>
              </td>
              <td>
                <button class="btn btn-success" type="button" @click="updateDevicePackage(device)">Save</button>
              </td>
            </tr>
          </thead>
        </table>
      </div>
      <div>No managed devices available...</div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        managedDevices: [],
        supportPackages: []
      }
    },
    created() {
      this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
    mounted() {
      this.$http.get(this.$config.aimsAPI + "managedservices/supportpackages")
        .then(success => {
          this.supportPackages = success.data;
        }, error => {
          console.error(error);
          this.showError('Error fetching support packages', error)
        });
      this.$http.get(this.$config.aimsAPI + "managedservices/devices/notset")
        .then(success => {
          this.managedDevices = success.data;
        }, error => {
          console.error(error);
          this.showError('Error fetching managed devices', error)
        });
    },
    methods: {
      updateDevicePackage(managedDevice) {
        this.$http.put(this.$config.aimsAPI + "managedservices/devices/" + managedDevice.managedDeviceNumber + "/" +
            managedDevice.supportPackageNumber)
          .then(success => {
            this.managedDevices = success.data;
          }, error => {
            console.error(error);
            this.showError('Error updating managed devices', error);
          });
      }
    }
  }

</script>

<style>

</style>
