<template>
    <modal 
      :title="modalTitle" 
      ref="packageModal"
      @save="savePackage()"
    >
      <div class="row">
        <div class="form-group col-md-12">
          <label>Description</label>
          <input type="text" class="form-control" v-model="package.description">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label>Product ID</label>
          <input type="text" class="form-control" v-model="package.axxessProductId">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <label>Bundle Size</label>
          <div class="input-group">
            <input type="text" v-model="package.size" class="form-control">
            <div class="input-group-append">
              <span class="input-group-text">GB</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12" v-if="package.axxessFtthPackageNumber">
          <label class="control-label">Expired</label>
          <v-select :options="['true', 'false']" v-model="package.expired" placeholder="false"></v-select>
        </div>
      </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal.vue';

export default {
    components: {
      Modal
    },
    props: {
      package: {
        type: Object,
        required: true
      },
      modalTitle: {
        type: String,
        required: true
      },
    },
    methods: {
      open: function() {
        this.$refs.packageModal.open();
      },
      savePackage: function() {  
        this.$emit('savePackage', this.package);
        this.$refs.packageModal.close();
      },
    }
}
</script>

<style lang="scss" scoped>

</style>