<template>
  <div class="card-body">
    <hr />
    <div class="row">
      <div
        class="col-md-4 upgrade-tile"
        @click="regradeTypeChanged('package')"
        :class="{ active: this.regradeType == 'package' }"
      >
        <p>Data Package / Speed</p>
        <span class="fa fa-database" style="color: orange"></span>
        <span class="fa fa-bolt" style="color: yellow"></span>
      </div>
      <!-- <div class="col-md-4 upgrade-tile" @click="regradeTypeChanged('product')" v-if="isCappedService()"
        :class="{'active' : this.regradeType == 'product'}">
        <p>Go Uncapped!</p>
        <span class="fa fa-chain-broken" style="color:green"></span>
      </div>
      <div class="col-md-4 upgrade-tile" @click="regradeTypeChanged('product')" v-if="isUncappedService()"
        :class="{'active' : this.regradeType == 'product'}">
        <p>Change to Capped</p>
        <span class="fa fa-chain" style="color:green"></span>
      </div> -->
    </div>
    <hr />
    <div class="row row-spacing">
      <div v-if="regradeType == 'product'" class="col-md-12">
        <div v-if="isUncappedService()">
          <div class="form-group">
            <label>Select a Product</label>
            <select
              class="form-control"
              v-model="regradeToProductNumber"
              @change="regradeProductChanged()"
            >
              <option
                :value="product.productNumber"
                v-for="product in regradeCappedProducts"
                :key="product.productNumber"
              >
                {{ product.description }}
              </option>
            </select>
          </div>
          <div
            v-if="
              regradeRequirements && Object.keys(regradeRequirements).length > 0
            "
          >
            <div class="form-group">
              <label>Select a Package</label>
              <select
                class="form-control"
                v-model="
                  regradeRequirements.requiredObjs.cappedService
                    .connectivityPackageNumber
                "
              >
                <option
                  :value="connectivityPackage.connectivityPackageNumber"
                  v-for="connectivityPackage in regradeRequirements
                    .selectableOptions.connectivityPackage"
                  :key="connectivityPackage.connectivityPackageNumber"
                >
                  {{
                    getConnectivityPackageFullDescription(connectivityPackage)
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="isCappedService()">
          <div class="form-group">
            <label>Select a Product</label>
            <select
              class="form-control"
              v-model="regradeToProductNumber"
              @change="regradeProductChanged()"
            >
              <option
                :value="product.productNumber"
                v-for="product in regradeUncappedProducts"
                :key="product.productNumber"
              >
                {{ product.description }}
              </option>
            </select>
          </div>
          <div
            v-if="
              regradeRequirements && Object.keys(regradeRequirements).length > 0
            "
          >
            <div class="form-group">
              <label>Select a Package</label>
              <select
                class="form-control"
                v-model="
                  regradeRequirements.requiredObjs.uncappedService
                    .connectivityPackageNumber
                "
              >
                <option
                  :value="connectivityPackage.connectivityPackageNumber"
                  v-for="connectivityPackage in regradeRequirements
                    .selectableOptions.connectivityPackage"
                  :key="connectivityPackage.connectivityPackageNumber"
                >
                  {{
                    getConnectivityPackageFullDescription(connectivityPackage)
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <button
          class="btn btn-success"
          @click="confirmRegrade()"
          v-if="regradeToProductNumber"
        >
          Regrade
        </button>
      </div>
      <div v-if="regradeType == 'package'" class="col-md-12">
        <div v-if="isCappedService()">
          <div>
            <div class="form-group">
              <label>Select a Package</label>
              <select
                class="form-control"
                v-model="
                  saleDefinition.definition.cappedService
                    .connectivityPackageNumber
                "
              >
                <option
                  :value="connectivityPackage.connectivityPackageNumber"
                  v-for="connectivityPackage in saleDefinition.selectableOptions
                    .connectivityPackage"
                  :key="connectivityPackage.connectivityPackageNumber"
                >
                  {{
                    getConnectivityPackageFullDescription(connectivityPackage)
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="isUncappedService()">
          <div>
            <div class="form-group">
              <label>Select a Package</label>
              <select
                class="form-control"
                v-model="
                  saleDefinition.definition.uncappedService
                    .connectivityPackageNumber
                "
              >
                <option
                  :value="connectivityPackage.connectivityPackageNumber"
                  v-for="connectivityPackage in saleDefinition.selectableOptions
                    .connectivityPackage"
                  :key="connectivityPackage.connectivityPackageNumber"
                >
                  {{
                    getConnectivityPackageFullDescription(connectivityPackage)
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <button class="btn btn-success" @click="confirmChangePackage()">
          Change Package
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    regradeToProduct() {
      if (this.regradeToProductNumber) {
        return this.regradeProducts.find(
          (p) => p.productNumber === this.regradeToProductNumber
        );
      } else {
        return null;
      }
    },
    regradeCappedProducts() {
      var cappedProducts = [];
      this.regradeProducts.forEach((r) => {
        if (this.cappedProducts.indexOf(r.productDefinition) != -1) {
          cappedProducts.push(r);
        }
      });
      return cappedProducts;
    },
    regradeUncappedProducts() {
      var uncappedProducts = [];
      this.regradeProducts.forEach((r) => {
        if (this.uncappedProducts.indexOf(r.productDefinition) != -1) {
          uncappedProducts.push(r);
        }
      });
      return uncappedProducts;
    },
  },
  data() {
    return {
      saleDefinition: null,
      regradeProducts: [],
      regradeType: "package",
      regradeToProductNumber: null,
      regradeRequirements: null,
      shapedPools: [],
      cappedProducts: [
        "za.co.adept.aims.product.fttx.frogfoot.FrogfootCappedFtth",
        "za.co.adept.aims.product.fttx.linteg.LintegCappedFtth",
        "za.co.adept.aims.product.fttx.sadv.SadvCappedFtth",
      ],
      uncappedProducts: [
        "za.co.adept.aims.product.fttx.adept.AdeptUncappedFtth",
        "za.co.adept.aims.product.fttx.frogfoot.FrogfootUncappedFtth",
        "za.co.adept.aims.product.fttx.frogfoot.FrogfootUncappedFtto",
        "za.co.adept.aims.product.fttx.linteg.LintegUncappedFtth",
        "za.co.adept.aims.product.fttx.octotel.OctotelUncappedFttb",
        "za.co.adept.aims.product.fttx.octotel.OctotelUncappedFtth",
        "za.co.adept.aims.product.fttx.sadv.SadvUncappedFtth",
        "za.co.adept.aims.product.fttx.vumatel.gpon.VumatelUncappedGponFtth",
        "za.co.adept.aims.product.fttx.vumatel.activeethernet.VumatelUncappedActiveEthernetFtth",
        "za.co.adept.aims.product.fttx.dfa.DfaBroadbandFibre"
      ],
    };
  },
  mounted() {
    this.$http.get(this.$config.aimsAPI + "shapedpools").then(
      (response) => {
        this.shapedPools = response.body;
      },
      (error) => {
        this.showError("Error fetching Shaped Pools", error);
        console.error(error);
      }
    );
    this.$http
      .get(
        this.$config.aimsAPI +
          "sales/definition/sale/" +
          this.$route.params.saleNumber
      )
      .then(
        (response) => {
          this.saleDefinition = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Loading Sale Definition", error);
        }
      );

    this.$http
      .get(
        this.$config.aimsAPI +
          "sales/definition/" +
          this.$route.params.saleNumber +
          "/regrades/allowed"
      )
      .then(
        (response) => {
          this.regradeProducts = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Loading Regradable Products", error);
        }
      );
  },
  methods: {
    isCappedService() {
      if (this.saleDefinition) {
        return (
          this.cappedProducts.indexOf(
            this.saleDefinition.product.productDefinition
          ) != -1
        );
      } else {
        return false;
      }
    },
    isUncappedService() {
      if (this.saleDefinition) {
        return (
          this.uncappedProducts.indexOf(
            this.saleDefinition.product.productDefinition
          ) != -1
        );
      } else {
        return false;
      }
    },
    regradeTypeChanged(regradeType) {
      this.regradeType = regradeType;

      if (this.regradeType === "product") {
        if (this.regradeProducts.length == 1) {
          this.regradeToProductNumber = this.regradeProducts[0].productNumber;
          this.regradeProductChanged();
        }
      }
    },
    regradeProductChanged() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.$route.params.saleNumber +
            "/regrades/requirements?productNumber=" +
            this.regradeToProductNumber
        )
        .then(
          (response) => {
            this.regradeRequirements = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Loading Regrade Requirements", error);
          }
        );
    },
    getConnectivityPackageFullDescription(connectivityPackage) {
      var description = connectivityPackage.description;
      if (connectivityPackage.size == 0) {
        description += " - Uncapped";
      } else {
        description += " - " + connectivityPackage.size + "GB";
      }
      description +=
        " - " +
        this.speed_bitsToMbits(
          connectivityPackage.downloadSpeed
        ) +
        "/" +
        this.speed_bitsToMbits(
          connectivityPackage.uploadSpeed
        );

      description +=
        " - " +
        this.shapedPools.find(
          (sp) => sp.shapedPoolNumber == connectivityPackage.shapedPoolNumber
        ).name;

      return description;
    },
    confirmChangePackage() {
      this.$swal({
        title: "Are you sure you want to Change Package?",
        text: "The billing will change for this client",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.changePackage();
        }
      });
    },
    changePackage() {
      if (this.isCappedService()) {
        this.$http
          .put(
            this.$config.aimsAPI +
              "sales/definition/" +
              this.$route.params.saleNumber +
              "/obj/update",
            this.saleDefinition.definition.cappedService
          )
          .then(
            (response) => {
              this.showSuccess("Package Change Success");
              this.$router.push({
                name: "ftthservice",
                params: {
                  saleNumber: this.$route.params.saleNumber,
                },
              });
            },
            (error) => {
              console.error(error);
              this.showError("Error Changing Package", error);
            }
          );
      }
      if (this.isUncappedService()) {
        this.$http
          .put(
            this.$config.aimsAPI +
              "sales/definition/" +
              this.$route.params.saleNumber +
              "/obj/update",
            this.saleDefinition.definition.uncappedService
          )
          .then(
            (response) => {
              this.showSuccess("Package Change Success");
              this.$router.push({
                name: "ftthservice",
                params: {
                  saleNumber: this.$route.params.saleNumber,
                },
              });
            },
            (error) => {
              console.error(error);
              this.showError("Error Changing Package", error);
            }
          );
      }
    },
    confirmRegrade() {
      this.$swal({
        title: "Are you sure you want to Regrade?",
        text: "The billing will change for this client",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.doRegrade();
        }
      });
    },
    doRegrade() {
      const regradeRequiredObjs = [];

      Object.keys(this.regradeRequirements.requiredObjs).forEach(
        function (key) {
          regradeRequiredObjs.push(this.regradeRequirements.requiredObjs[key]);
        }.bind(this)
      );
      this.$http
        .post(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.$route.params.saleNumber +
            "/regrades?productNumber=" +
            this.regradeToProductNumber,
          regradeRequiredObjs
        )
        .then(
          (response) => {
            this.showSuccess("Service Regrade Success");
            this.$router.push({
              name: "ftthservice",
              params: {
                saleNumber: this.$route.params.saleNumber,
              },
            });
          },
          (error) => {
            console.error(error);
            this.showError("Error Regrading Sale", error);
          }
        );
    },
  },
};
</script>

<style>
.upgrade-tile {
  font-size: 20px;
  text-align: center;
  padding: 5px;
}

.upgrade-tile:hover {
  cursor: pointer;
  font-size: 22px;
}

.upgrade-tile.active {
  font-size: 22px;
}
</style>
