<template>
  <div class="card-body">
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-header-title">Sale Documentation</h3>
      </div>
      <div class="card-body">
        <div class="row row-spacing justify-content-between align-items-center">
          <div class="form-group col-md-4">
            <input class="form-control" v-model="searchText" placeholder="Filter on Description or Type" />
          </div>
          <div class="form-group col-auto">
            <div class="btn-toolbar">
              <button class="btn btn-success" type="button" @click="openLinkDocumentsModal()"><i
                  class="fa fa-2x fa-link"></i>
                <div>Link Documents</div>
              </button>
              <UploadDocument :paperTypes="paperTypes" :clientSales="saleDefinition.sale" :clientNumber="clientNumber"
                @updateCorrespondence="updateCorrespondence" />
            </div>
          </div>
        </div>
        <Correspondence :correspondence="filteredCorrespondence" :paperTypes="paperTypes" :context="'sale'" />
      </div>
    </div>
    <modal title="Link Documents" ref="linkDocumentsModal" size="modal-lg" @save="linkDocuments()">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="row row-spacing">
                <div class="col-md-5">
                  <input type="text" class="form-control" v-model="modalSearchText" placeholder="Filter Sales">
                </div>
                <div class="col-md-4 m-0">
                  <v-select :options="options" v-model="show"></v-select>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="selectAll" v-model="selectAll">
                  <label class="form-check-label" for="selectAll">{{selectAll ? 'Deselect' : 'Select'}}
                    All</label>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-wrapper-scroll-y my-custom-scrollbar" v-if="filteredDocuments.length > 0">
                <table class="table table-bordered table-hover mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Description</th>
                      <th>Type</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :class="selectedDocuments.includes(paper.paperNumber) ? 'new-table-row' : ''"
                      v-for="paper in filteredDocuments" :key="paper.paperNumber" @click="selectDocument(paper)">
                      <td scope="row">{{paper.description}}</td>
                      <td>{{paperType(paper, paperTypes)}}</td>
                      <td>{{paper.fileName}}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-danger text-center">*Click on table row to select/deselect document</div>
              </div>
              <div v-else>
                No {{ show.includes('All') ? 'Correspondence' : show.includes('Unselected') ? 
                        'Unselected Documents' : 'Selected Documents' }} Found
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import SaleFunctions from '@/pages/Services/SaleFunctions'
  import ServiceTitle from '@/components/Services/ServiceTitle'
  import Correspondence from '@/components/Documents/Correspondence'
  import UploadDocument from '@/components/Documents/UploadDocument'
  import Modal from '@/components/GeneralComponents/Modal'
  export default {
    extends: SaleFunctions,
    components: {
      Correspondence,
      ServiceTitle,
      UploadDocument,
      Modal
    },
    computed: {
      filteredCorrespondence() {
        return this.correspondence.filter(c => {
          if (c.saledocumentations) {
            return c.saledocumentations.findIndex(sd => sd.saleNumber == this.saleNumber) != -1 &&
              (c.description.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1 ||
                c.type.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1)
          }
        })
      },
      filteredDocuments() {
        if (this.show == 'Selected Documents') {
          return this.correspondence.filter(doc => this.selectedDocuments.includes(doc.paperNumber));
        }

        if (this.show == 'Unselected Documents') {
          return this.correspondence.filter(doc => !this.selectedDocuments.includes(doc.paperNumber));
        }

        return this.correspondence.filter(paper => {
          return paper.description.includes(this.modalSearchText) || paper.fileName.includes(this.modalSearchText)
        });
      },
      paperType: () => ({
        type
      }, paperTypes) => {
        const t = paperTypes.find(t => t.name === type);
        return t.description;
      }
    },
    watch: {
      selectAll(selectAll) {
        if (selectAll) {
          this.selectedDocuments = this.correspondence.map(doc => doc.paperNumber);
        } else {
          this.selectedDocuments = []
        }
      },
      selectedDocuments(paper) {
        if (paper.length == this.correspondence.length) {
          this.selectAll = true;
        } else if (paper.length == 0) {
          this.selectAll = false;
        }
      }
    },
    data() {
      return {
        saleDefinition: {
          sale: {}
        },
        document: {},
        paperTypes: [],
        correspondence: [],
        selectedDocuments: [],
        options: ['All Documents', 'Selected Documents', 'Unselected Documents'],
        show: 'All Documents',
        selectAll: false,
        searchText: '',
        modalSearchText: '',
        saleNumber: 0,
        clientNumber: 0
      }
    },
    mounted() {
      this.saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(this.saleNumber).then(response => {
          this.saleDefinition = response.data;
        },
        error => {
          this.showError("Error Fetching Sale Definition", error);
          console.error(error);
        });

      this.$http.get(this.$config.aimsAPI + 'clients/correspondence/types')
        .then(response => {
          this.paperTypes = response.data;
          this.fetchCorrespondence();
        }, error => {
          console.error(error);
          this.showError("Error Fetching Correspondence Types", error);
        });
    },
    methods: {
      fetchCorrespondence() {
        this.clientNumber = this.$route.params.clientNumber;
        this.$http.get(this.$config.aimsAPI + 'clients/' + this.clientNumber + '/correspondence')
          .then(response => {
            this.correspondence = response.data;
          }, error => {
            console.error(error);
            this.showError("Error Fetching Client Correspondence", error);
          });
      },
      openLinkDocumentsModal() {
        this.selectedDocuments = this.filteredCorrespondence.map(paper => paper.paperNumber) || [];
        this.$refs.linkDocumentsModal.open();
      },
      selectDocument({
        paperNumber
      }) {
        const i = this.selectedDocuments.indexOf(paperNumber);
        if (i == -1) {
          this.selectedDocuments.push(paperNumber);
        } else {
          this.selectedDocuments.splice(i, 1);
        }
      },
      linkDocuments() {
        this.$refs.linkDocumentsModal.isLoading();
        this.$http.post(this.$config.aimsAPI + 'clients/correspondence/sale/' + this.saleNumber,
          this.selectedDocuments).then(response => {
            this.$refs.linkDocumentsModal.close();
            this.showSuccess(response.data.message);
            this.fetchCorrespondence();
          },
          error => {
            this.showError("Error updating Sale Documentation", error);
            console.error(error);
          }).finally(() => {
          this.$refs.linkDocumentsModal.isLoading();
        });
      },
      updateCorrespondence(document) {
        this.correspondence.unshift(document);
      }
    }
  }

</script>

<style scoped>
  .text {
    font-size: 16px !important;
  }

  .btn-toolbar .btn {
    margin-right: .25rem;
    margin-bottom: .25rem;
  }

  .my-custom-scrollbar {
    width: 100% !important;
  }

</style>
