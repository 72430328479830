<style>
.ball_1 {
  background-color: #aadcd8 !important;
}

.ball_2 {
  background-color: #58b0c2 !important;
}

.ball_3 {
  background-color: #ededee !important;
}

.ball_4 {
  background-color: #e2231a !important;
}

.input-group {
  flex-wrap: unset !important;
}

.search-input {
  min-width: 300px;
}

body {
  overflow: overlay;
}

.aside-collapsed {
  overflow: overlay !important;
}

.wrapper .section-container {
  margin-bottom: 0 !important;
}

.content-heading {
  position: fixed;
  width: 100%;
  z-index: 120;
}

.app-view {
  margin-top: 67px;
}

.sidebar-removed {
  margin-left: 0px !important;
}

style .vs__search::placeholder,
.style .vs__dropdown-toggle,
.style .vs__dropdown-menu {
  border: none;
  padding: 0;
}

.v-select-toggle {
   overflow: hidden;
}

sup {
  top: -0.5em;
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  width: 600px;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}

td > input.form-control,
td > .input-group > input.form-control,
td > select.custom-select {
  width: auto;
}

@media (min-width: 768px) {
  #angle-item {
    position: fixed;
    height: calc(100vh - 70px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}

svg.mx-calendar-icon {
  height: auto !important;
}

.flatdoc-wrapper .flatdoc-content > h1 {
  padding: 9px 0 !important;
}

.gold {
  color: #ffd700;
}

.bg-gold {
  color: #ffd700;
  background: slategray;
}

.fa-stack-1x {
  line-height: inherit;
  font-size: small;
  position: absolute;
  top: -16;
}

.fa-stack-2x {
  font-size: 1em;
}

.table-responsive {
  overflow-y: scroll;
  min-height: 10vh;
  max-height: calc(100vh - 250px);
  display: block;
}

caption {
  caption-side: top;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<template>
  <div>
    <nprogress-container></nprogress-container>
    <loading :active="appLoading" :can-cancel="false"></loading>
    <div class="wrapper" v-if="appLoading == false">
      <!-- top navbar-->
      <header class="topnavbar-wrapper">
        <!-- START Top Navbar-->
        <nav class="navbar topnavbar" role="navigation">
          <!-- START navbar header-->
          <div class="navbar-header">
            <div class="brand-logo">
              <span class="img-fluid navbar-logo-expanded"
                ><img alt="App Logo"
              /></span>
            </div>
            <router-link
              class="navbar-brand"
              :to="{ name: 'dashboard' }"
              v-allowed:view="['STAFF', 'CLIENT', 'RESELLER']"
            >
              <div class="brand-logo-collapsed">
                <span
                  class="img-fluid navbar-logo-collapsed"
                  style="max-width: 30px"
                  ><img alt="App Logo"
                /></span>
              </div>
            </router-link>
          </div>
          <!-- END navbar header-->
          <!-- START Left navbar-->
          <ul
            class="navbar-nav mr-auto flex-row"
            v-allowed:view="['STAFF', 'RESELLER']"
          >
            <li class="nav-item">
              <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
              <a
                class="nav-link d-none d-md-block d-lg-block d-xl-block"
                data-trigger-resize=""
                data-toggle-state="aside-collapsed"
                style="cursor: pointer"
              >
                <em><i class="fa-solid fa-bars"></i></em>
              </a>
              <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
              <a
                class="nav-link sidebar-toggle d-md-none"
                data-toggle-state="aside-toggled"
                data-no-persist="true"
                style="cursor: pointer"
              >
                <em><i class="fa-solid fa-bars"></i></em>
              </a>
            </li>
            <li class="nav-item" v-allowed:view="['STAFF']">
              <a class="nav-link" data-search-open style="cursor: pointer">
                <em><i class="fa-solid fa-magnifying-glass"></i></em>
              </a>
            </li>
          </ul>
          <!-- END Left navbar-->
          <!-- START Right Navbar-->
          <ul class="navbar-nav flex-row align-items-center">
           <!--  <li class="nav-item">
              <a
                class="nav-link"
                style="color: transparent"
                id="saleNav"
                href="javascript:void(0)"
                data-toggle-state="offsidebar-open"
                data-no-persist="true"
              >
                <em class="icon-notebook"></em>
              </a>
            </li> -->
            <li class="nav-item" v-allowed:view="['STAFF']">
                 <router-link :to="{ name: 'notifications' }" class="nav-link notification-alerts" v-tooltip="'Check notifications'">
                 <i class="fa fa-bell"></i>
                 <span class="notify-count" v-if="notificationAlerts.length > 0"></span>
              </router-link>
            </li>
            <li class="nav-item dropdown mr-4">
              <a
                class="dropdown-toggle"
                href="#"
                style="color: #FFFFFF; font-size: 15px"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Signed in as
                <strong
                  >{{ userDetails.firstNames }}
                  {{ userDetails.lastName }}</strong
                >
              </a>
              <div class="dropdown-menu mr-4" aria-labelledby="navbarDropdown">
                <!--<div class="dropdown-divider" v-allowed:view="['STAFF']"></div> 
                UNCOMMENT WHEN WE ADD MORE OPTIONS TO DROPDOWN-->
                  <button
                    class="btn btn-link dropdown-item"
                    style="color: #656565; font-size: 15px"
                    type="button"
                    @click="backendLogout()"
                  >
                    Logout
                  </button>
              </div>
            </li>
          </ul>
          <!-- END Right Navbar-->
          <!-- START Search form-->
          <form class="navbar-form" role="search" @submit.prevent="doSearch()">
            <div class="form-group">
              <input
                class="form-control"
                type="text"
                v-model="searchTerm"
                placeholder="Type and hit enter ..."
              />
              <div
                class="fa fa-times navbar-form-close"
                data-search-dismiss=""
              ></div>
            </div>
            <button class="d-none" type="submit">Submit</button>
          </form>
          <!-- END Search form-->
        </nav>
        <!-- END Top Navbar-->
      </header>
      <!-- sidebar-->
      <aside class="aside-container" v-allowed:view="['STAFF', 'RESELLER']">
        <!-- START Sidebar (left)-->
        <div class="aside-inner">
          <nav class="sidebar pt-1" data-sidebar-anyclick-close="">
            <!-- START sidebar nav-->
            <ul class="sidebar-nav">
              <!-- START user info-->
              <li class="has-user-block">
                <div class="collapse" id="user-block">
                  <div class="item user-block">
                    <!-- Name and Job-->
                    <div class="user-block-info">
                      <span class="user-block-name"
                        >Hello, {{ loggedInUser.firstNames }}</span
                      >
                    </div>
                  </div>
                </div>
              </li>
              <!-- END user info-->
              <!-- Iterates over all sidebar items-->
              <li v-allowed:view="['STAFF']" title="Search">
                <a data-search-open>
                  <em><i class="fa-solid fa-magnifying-glass"></i></em>
                  <span class="">Search</span>
                </a>
              </li>
              <li class="nav-heading">
                <span>{{ navbarStructure.heading }}</span>
              </li>
              <li>
                <router-link
                  v-if="navbarStructure.backRoute"
                  :to="{
                    name: navbarStructure.backRoute.routeName,
                    params: navbarStructure.backRoute.routeParams,
                    query: navbarStructure.backRoute.routeQuery,
                  }"
                >
                  <em><i :class="navbarStructure.backRoute.iconClass"></i></em>
                  <span>{{ navbarStructure.backRoute.title }}</span>
                </router-link>
              </li>
              <li
                :title="menuItem.title"
                v-for="(menuItem, index) in navbarStructure.menu"
                :key="index"
              >
                <!-- IF CHILDREN -->
                <a
                  :href="'#' + menuItem.title"
                  :title="menuItem.title"
                  data-toggle="collapse"
                  aria-expanded="false"
                  v-if="menuItem.children.length > 0"
                >
                  <em><i :class="menuItem.iconClass"></i></em>
                  <span data-localize="sidebar.nav.extra.EXTRA">{{
                    formatSpaces(menuItem.title)
                  }}</span>
                </a>
                <ul
                  class="sidebar-nav sidebar-subnav collapse"
                  :id="menuItem.title"
                  v-if="menuItem.children.length > 0"
                >
                  <li
                    v-for="(subMenuItem, index) in menuItem.children"
                    :key="index"
                  >
                    <!-- IF CHILDREN -->
                    <template v-if="subMenuItem.children">
                      <a
                        :href="'#' + subMenuItem.title"
                        :title="subMenuItem.title"
                        data-toggle="collapse"
                        aria-expanded="false"
                        v-if="subMenuItem.children.length > 0"
                      >
                        <em><i :class="subMenuItem.iconClass"></i></em>
                        <span data-localize="sidebar.nav.extra.EXTRA">{{
                          formatSpaces(subMenuItem.title)
                        }}</span>
                      </a>
                      <ul
                        class="sidebar-nav sidebar-subnav collapse"
                        :id="subMenuItem.title"
                        v-if="subMenuItem.children.length > 0"
                      >
                        <li
                          v-for="(subSubMenuItem, index) in subMenuItem.children"
                          :key="index"
                        >
                          <router-link
                            style="white-space: pre-wrap !important"
                            class="sidebar-subchild"
                            :to="{
                              name: subSubMenuItem.routeName,
                              params: subMenuItem.routeParams,
                              query: subMenuItem.routeQuery,
                            }"
                          >
                            <em><i :class="subSubMenuItem.iconClass"></i></em>
                            <span>{{ subSubMenuItem.title }}</span>
                          </router-link>
                        </li>
                      </ul>
                    </template>


                    <!-- NO CHILDREN -->
                    <router-link
                      style="white-space: pre-wrap !important"
                      :to="{
                        name: subMenuItem.routeName,
                        params: menuItem.routeParams,
                        query: menuItem.routeQuery,
                      }"
                      v-else
                    >
                      <em><i :class="subMenuItem.iconClass"></i></em>
                      <span>{{ subMenuItem.title }}</span>
                    </router-link>
                  </li>
                </ul>

                <!-- IF NO CHILDREN -->
                <router-link
                  :to="{
                    name: menuItem.routeName,
                    params: menuItem.routeParams,
                  }"
                  v-if="menuItem.children.length == 0"
                >
                  <em><i :class="menuItem.iconClass"></i></em>
                  <span>{{ menuItem.title }}</span>
                </router-link>
              </li>
              <div class="row">
                <div class="col-md-12">
                  <hr />
                </div>
              </div>
              <li title="Changelog">
                <a @click="viewChangelog()">
                  <span class="text-muted">Changelog</span>
                  <sup
                    ><span
                      v-if="versionUpdated"
                      style="font-size: 8px"
                      class="d-inline badge badge badge-warning"
                      >1</span
                    ></sup
                  >
                </a>
              </li>
            </ul>
            <!-- END sidebar nav-->
          </nav>
        </div>
        <!-- END Sidebar (left)-->
      </aside>
      <!-- offsidebar-->
      <aside class="offsidebar d-none">
        <!-- START Off Sidebar (right)-->
        <nav>
          <ul></ul>
        </nav>
        <!-- END Off Sidebar (right)-->
      </aside>
      <!-- Main section-->
      <section
        class="section-container"
        :class="{ 'sidebar-removed': sidebarRemoved }"
      >
        <!-- Page content-->
        <div
          v-if="this.$route.path.includes('/client')"
          class="content-wrapper"
          id="service-panel-main"
        >
          <div class="flatdoc-wrapper">
            <div class="flatdoc">
              <div class="flatdoc-menu" id="client-menu">
                <ul>
                  <li id="root-item" class="level-0">
                    <ul class="level-1" id="root-list">
                      <li id="angle-item" class="level-1">
                        <a class="level-1 m-0">Client Menu</a>
                        <ul class="level-3" id="angle-list">
                          <li
                            class="level-3 cmItem"
                            v-for="(
                              menuItem, index
                            ) in clientNavbarStructure.menu"
                            :key="index"
                          >
                            <!-- IF CHILDREN -->
                            <div 
                              class="dropdown mr-5 icmItem" 
                              v-if="menuItem.children.length > 0"
                            >
                              <a 
                                class="nav-link dropdown-toggle caret-off mr-5" 
                                data-toggle="dropdown" 
                                href="#" 
                                role="button" 
                                aria-haspopup="true" 
                              >
                                <em><i :class="menuItem.iconClass"></i></em>
                                <span class="client-dropdown-items-text">{{ menuItem.title }}</span>
                              </a>
                              <div 
                                class="dropdown-menu"
                                v-if="menuItem.children.length > 0"
                              >
                                <li
                                  v-for="(subMenuItem, index) in menuItem.children"
                                  :key="index"
                                >
                                  <div v-if="userContainsPermission(subMenuItem.groupPermissions)">
                                    <div class="client-divider" :class="{'dropdown-divider': subMenuItem.divider}"></div>
                                    <div class="client-dropdown-item">
                                      <router-link
                                        class="dropdown-item client-dropdown-items"
                                        :to="{
                                          name: subMenuItem.routeName,
                                          params: menuItem.routeParams,
                                          query: menuItem.routeQuery,
                                        }"
                                      >
                                        <em class="client-dropdown-items-icon"><i :class="subMenuItem.iconClass"></i></em>
                                        <span class="client-dropdown-items-text">{{ subMenuItem.title }}</span>
                                      </router-link>
                                    </div>
                                  </div>
                                </li>
                              </div>
                            </div>

                            <!-- NO CHILDREN -->
                            <router-link
                              :to="{
                                name: menuItem.routeName,
                                params: clientNumber,
                              }"
                              v-if="menuItem.children.length == 0"
                            >
                              <em><i :class="menuItem.iconClass"></i></em>
                              <span class="">
                                {{ menuItem.title }}
                              </span>
                              <div
                                v-if="menuItem.badge"
                                class="float-right"
                                :class="menuItem.badge.class"
                              >
                                {{ menuItem.badge.text }}
                              </div>
                            </router-link>
                          </li>
                          <div class="row">
                            <div class="col-md-12">
                              <hr />
                            </div>
                          </div>
                          <li title="Feedback" class="clientLog" v-allowed:view="['CLIENT']">
                            <a @click="showFeedback('Feedback', 'What feedback would you like to give us?')">
                              <i class="fa-solid fa-comment-dots"></i>
                              <span class="clientFeedbackText">Feedback</span>
                            </a>
                          </li> 
                          <li title="Changelog" class="clientLog">
                            <a @click="viewClientChangelog()">
                              <span class="clientLogText">Changelog</span>
                              <sup
                                ><span
                                  v-if="versionUpdated"
                                  style="font-size: 8px"
                                  class="d-inline badge badge badge-warning"
                                  >1</span
                                ></sup
                              >
                            </a>
                          </li> 
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div class="flatdoc-content">
                <div class="page-header-section col-md-12 py-2" v-allowed:view="['STAFF', 'CLIENT', 'RESELLER']" v-if="showPageTitle || serverLoading == true">
                  <div class="page-header-section-wrapper row" v-if="showPageTitle">
                    <div class="client-menu-toggle-wrapper col-md-10 pl-0 d-flex align-items-center">
                      <div class="menu-toggle-wrapper">
                        <button class="btn btn-secondary" type="button" @click="toggleClientMenu">
                          <em class="fa fa-exchange d-none d-md-block"></em>
                        </button>
                      </div>
                      <div class="page-title-wrapper ml-2">
                        <span class="h3" v-allowed:view="['STAFF']" id="appPageTitle">{{ pageTitle }}</span>
                        <span class="client-title" v-allowed:view="['CLIENT']" id="appPageTitle">{{ clientPageTitle }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <h2 id="angle-section-a" class="h6 pt-1">
                  <router-view></router-view>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="content-wrapper" id="service-panel-main">
          <div
            class="content-heading"
            v-allowed:view="['STAFF', 'CLIENT', 'RESELLER']"
            v-if="showPageTitle || serverLoading == true"
          >
            <div>
              <div v-if="showPageTitle">
                {{ pageTitle }}
              </div>
            </div>
          </div>
          <!-- MAIN CONTENT -->
          <div class="app-view">
            <router-view></router-view>
          </div>
        </div>
      </section>
    </div>
    <modal
      title="What's changed?"
      ref="changelogModal"
      size="modal-lg"
      dismiss-text="Close"
      :save-needed="false"
    >
      <div v-if="changelog">
        <div
          v-for="(log, index) in changelog"
          :key="index"
          @click="toggleLog(log)"
          style="cursor: pointer"
        >
          <h5>
            <strong>{{ log.version }} - {{ log.date }}</strong>
          </h5>
          <div v-if="index == 0 || log.show">
            <span class="badge bg-green"
              ><i class="fa fa-check"></i> Features</span
            >
            <ul v-for="(feature, index) in log.features" :key="index">
              <li v-if="feature">
                <span class="font-weight-bold">{{ feature.title }}</span> -
                <span class="font-weight-light">{{ feature.description }}</span>
              </li>
            </ul>
          </div>
          <div v-if="index == 0 || log.show">
            <span class="badge bg-warning"
              ><i class="fa fa-bug"></i> Bugs Squashed</span
            >
            <ul v-for="(bug, index) in log.bugs" :key="index">
              <li>
                <span class="font-weight-bold">{{ bug.title }}</span> -
                <span class="font-weight-light">{{ bug.description }}</span>
              </li>
            </ul>
          </div>
          <div v-if="index == 0 || log.show">
            <span class="badge bg-danger"
              ><i class="fa fa-laptop"></i> Dev Stuff</span
            >
            <ul v-for="(dev, index) in log.dev" :key="index">
              <li>
                <span class="font-weight-bold">{{ dev.title }}</span> -
                <span class="font-weight-light">{{ dev.description }}</span>
              </li>
            </ul>
          </div>
          <hr />
        </div>
      </div>
      <div v-else>No new updates have been made yet...</div>
    </modal>

    <ClientChangelogModal ref="clientChangelogModal"/>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Autocomplete from "vuejs-auto-complete";
import Modal from "@/components/GeneralComponents/Modal";
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import ClientChangelogModal from "@/components/Admin/Modals/ClientChangelogModal"

const homeNavbarStructure = require("@/structures/homeNavbarStructure.json");

var token;

export default {
  components: {
    Modal,
    Loading,
    Autocomplete,
    NprogressContainer,
    ClientChangelogModal
  },
  computed: {
    authHeaders() {
      if (!token) {
        token = this.$localStorage.get("aims-token");
      }
      return {
        Authorization: "Bearer " + token,
      };
    },
  },
  data() {
    return {
      sidebarRemoved: false,
      pageTitle: "",
      clientPageTitle: "",
      navbarStructure: {},
      userDetails: {},
      clientNavbarStructure: {},
      loggedInUser: {},
      searchResults: [],
      serverLoading: false,
      showPageTitle: true,
      titleBadges: [],
      activeThemeName: "darcula",
      changelog: [],
      clientChangelog: [],
      searchTerm: "",
      clientNumber: null,
      notificationAlerts: [],
      segments: [],
      segmentsArr: [],
      options: [
        {
          name: "Profile",
          routeName: "clientdetail",
        },
        {
          name: "Statement",
          routeName: "clientstatement",
        },
        {
          name: "Correspondence",
          routeName: "clientcorrespondence",
        },
      ],
    };
  },
  created() {
    this.initAuth();
    this.versionCheck();
    //theres a chance that the user is still getting his/her user permissions from the server.
    //so we wait

    this.$root.$on(
      "START_PROGRESS",
      function () {
        this.serverLoading = true;
      }.bind(this)
    );

    this.$root.$on(
      "STOP_PROGRESS",
      function () {
        this.serverLoading = false;
      }.bind(this)
    );

    this.$root.$on(
      "SET_PAGE_TITLE",
      function (pageTitle) {
        this.pageTitle = pageTitle;
        document.title = this.pageTitle;
      }.bind(this)
    );
    this.$root.$on(
      "SET_CLIENT_PAGE_TITLE",
      function (clientPageTitle) {       
        this.clientPageTitle = clientPageTitle;  
        document.title = clientPageTitle;      
      }.bind(this)
    );
    this.$root.$on(
      "HIDE_PAGE_TITLE",
      function () {
        this.showPageTitle = false;
      }.bind(this)
    );
    this.$root.$on(
      "SET_PAGE_TITLE_BADGES",
      function (titleBadges) {
        this.titleBadges = titleBadges;
      }.bind(this)
    );
    this.$root.$on(
      "SET_HOME_NAVBAR",
      function () {
        this.clientNumber = null;
        this.clientNavbarStructure = {};
        this.navbarStructure = this.getRestrictedNavStructure(homeNavbarStructure);
      }.bind(this)
    );
    this.$root.$on(
      "SET_CLIENT_NAVBAR",
      function (clientNavbarStructure, clientNumber) {
        this.clientNumber = clientNumber;
        this.clientNavbarStructure = clientNavbarStructure;

        if (this.userContainsPermission(['CLIENT'])) {
          /* this.setSegments(); */
        }
      }.bind(this)
    );
  },
  mounted() {
    this.$http.get(this.$config.aimsAPI + "users/user/loggedInUser").then(
      (response) => {
        this.userDetails = response.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching User", error);
      }
    );

    var interval = 0;
    
    //WE CALL TO GET THE LOGGED IN USER PERMISSIONS, THIS REQUIRES AUTH ON THE TOKEN
    this.$http.get(this.$config.aimsAPI + "users/groups").then(
      (response) => {
        this.$root.userGroups = response.data;

        this.$root.$emit("SET_HOME_NAVBAR");

        this.appLoading = false;

        let templateScript = document.createElement("script");
        templateScript.setAttribute("src", "/template/js/app.js");
        document.head.appendChild(templateScript);

        if (!this.userContainsPermission(["STAFF", "RESELLER"])) {
          this.sidebarRemoved = true;
        }

        if (this.$root.userGroups.length == 0) {
          interval = 100;
        }

        const waitForPermissions = setInterval(() => {
          if (this.$root.userGroups.length >= 0) {
            if (this.userHasRouteAccess(this.$route.name) == false) {
              this.$swal({
                type: "warning",
                title: "Access Denied",
                text:
                  "You do not have access to view '" + this.$route.meta.title + "'",
              }).then(() => {
                this.$router.replace({
                  name: "login",
                });
              });
            }
            -clearInterval(waitForPermissions);
          }
        }, interval);

      },
      (error) => {
        console.warn(error);
        this.logout("tokenIssue=1");
      }
    );

    this.loadTheme();

    this.$http.get(this.$config.aimsAPI + "changelog").then(
      (response) => {
        this.changelog = response.data;
      },
      (error) => {
        this.showError("Error fetching changelog", error);
        console.error(error);
      }
    );

    this.$http.get(this.$config.aimsAPI + "changelog/client").then(
      (response) => {
        this.clientChangelog = response.data;
      },
      (error) => {
        this.showError("Error Fetching Client changelog", error);
        console.error(error);
      }
    );

    this.$http.get(this.$config.aimsAPI + "alerts").then(
      (response) => {
        this.notificationAlerts = response.data;
      },
      (error) => {
        this.showError("Error fetching alerts", error);
        console.error(error);
      }
    );
  },
  methods: {
    loadTheme() {
      if (this.activeThemeName && this.activeThemeName != "") {
        var link = document.createElement("link");
        link.href =
          "/servicepanel-themes/adept.servicepanel.co.za/" +
          this.activeThemeName +
          "-theme.css";
        link.type = "text/css";
        link.rel = "stylesheet";
        link.id = "activeTheme";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
    },
    formatSpaces(text) {
      var formattedText = text.replace("_", " ");

      return formattedText;
    },
    viewChangelog() {
      this.$refs.changelogModal.open();
    },
    viewClientChangelog() {
      this.$refs.clientChangelogModal.open();
    },
    toggleLog(log) {
      this.$set(log, "show", !log.show);
    },
    toggleClientMenu() {
      $("#client-menu").fadeToggle();
    },
    toggleSaleMenu() {
      document.getElementById("saleNav").click();
    },
    toggleDropdown() {
      $(".dropdown-menu").toggle();
    },
    doSearch() {
      this.$router.push({
        name: "search",
        query: {
          searchTerm: this.searchTerm,
        },
      });
    },
    /* setSegments() {
      this.$http.get(this.$config.aimsAPI + 'sales/client/' + this.clientNumber + '/segments').then(
        (response) => {
          this.segments = response.data; 
          this.segmentsArr = Object.keys(this.segments);

          for (let i = 0; i < this.clientNavbarStructure.menu.length; i++) {
            if (this.clientNavbarStructure.menu[i].id == "clientservices") {
              this.clientNavbarStructure.menu[i].children = [];

              if (this.segmentsArr.includes("Ad Hoc")) {
                this.clientNavbarStructure.menu[i].children.push(
                  {
                      title: "Ad Hoc",
                      iconClass: "fa fa-file",
                      groupPermissions: [
                      "*"
                      ],
                      routeName: "clientsalesadhoc",
                      routeParams: {},
                      routeQuery: {}
                  }
                );
              }
              if (this.segmentsArr.includes("Cloud Services")) {
                this.clientNavbarStructure.menu[i].children.push(
                  {
                      title: "Cloud Services",
                      iconClass: "fa fa-globe",
                      groupPermissions: [
                      "*"
                      ],
                      routeName: "clientsalescloud",
                      routeParams: {},
                      routeQuery: {}
                  }
                );
              }
              if (this.segmentsArr.includes("Connectivity")) {
                this.clientNavbarStructure.menu[i].children.push(
                  {
                      title: "Connectivity",
                      iconClass: "fa fa-wifi",
                      groupPermissions: [
                      "*"
                      ],
                      routeName: "clientsalesconnectivity",
                      routeParams: {},
                      routeQuery: {}
                  }
                );
              }
              if (this.segmentsArr.includes("Hardware")) {
                this.clientNavbarStructure.menu[i].children.push(
                  {
                      title: "Hardware",
                      iconClass: "fa fa-television",
                      groupPermissions: [
                      "*"
                      ],
                      routeName: "clientsaleshardware",
                      routeParams: {},
                      routeQuery: {}
                  }
                );
              }
              if (this.segmentsArr.includes("Labour")) {
                this.clientNavbarStructure.menu[i].children.push(
                  {
                      title: "Labour",
                      iconClass: "fa fa-briefcase",
                      groupPermissions: [
                      "*"
                      ],
                      routeName: "clientsaleslabour",
                      routeParams: {},
                      routeQuery: {}
                  }
                );
              }
              if (this.segmentsArr.includes("Managed Services")) {
                this.clientNavbarStructure.menu[i].children.push(
                  {
                      title: "Managed Services",
                      iconClass: "fa fa-laptop",
                      groupPermissions: [
                      "*"
                      ],
                      routeName: "clientsalesmanaged",
                      routeParams: {},
                      routeQuery: {}
                  }
                );
              }
              if (this.segmentsArr.includes("Outsourced Services")) {
                this.clientNavbarStructure.menu[i].children.push(
                  {
                      title: "Outsourced Services",
                      iconClass: "fa fa-question-circle",
                      groupPermissions: [
                      "*"
                      ],
                      routeName: "clientsalesoursourced",
                      routeParams: {},
                      routeQuery: {}
                  }
                );
              }
              if (this.segmentsArr.includes("Software Licenses")) {
                this.clientNavbarStructure.menu[i].children.push(
                  {
                      title: "Software Licenses",
                      iconClass: "fa fa-folder-o",
                      groupPermissions: [
                      "*"
                      ],
                      routeName: "clientsalessoftware",
                      routeParams: {},
                      routeQuery: {}
                  }
                );
              }
              if (this.segmentsArr.includes("Voice Services")) {
                this.clientNavbarStructure.menu[i].children.push(
                  {
                      title: "Voice Services",
                      iconClass: "fa fa-phone",
                      groupPermissions: [
                      "*"
                      ],
                      routeName: "clientsalesvoice",
                      routeParams: {},
                      routeQuery: {}
                  }
                );
              }
            }
          }
        },
        (error) => {
          this.showError("Error fetching Classification Segments for Client", error);
          console.error(error);
        }
      )
    }, */
  },
  watch: {
    $route(to, from) {
      this.showPageTitle = true;
      if (this.userContainsPermission(["STAFF", "RESELLER"])) {
        if (!to.path.includes("/client")) {
          $("body").removeClass("aside-collapsed");
          this.$root.$emit("SET_HOME_NAVBAR");
        }
      }
    },
  },
};
</script>

<style scoped>
.router-link-active {
  border-left: #e2231a 2px solid !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.h3 {
  line-height: 1.5 !important;
}

.client-title {
  line-height: 1.5 !important;
  font-size: 1.8rem;
  font-weight: 400;
}

.notification-alerts {
  position: relative;
}

.notification-alerts .fa-bell {
  font-size: 1.3em;
}

.notify-count {
  position: absolute;
  top: 28%;
  right: 28%;
  background: #ff902b;
  border-radius: 100%;
  width: 20%;
  height: 20%;
}

.caret-off::before {
  display: none;
}
.caret-off::after {
  display: none;
}

.flatdoc-menu{
  width: 13em;
}

.cmItem {
  width: 13em
}
.icmItem {
  width: 16.5em
}

.client-dropdown-item:hover em,
.client-dropdown-item:hover span
{
  color: #e2231a;
}

.clientLog {
  cursor: pointer;
}

.clientLogText {
  padding-left: 1em;
  color: #858585;
}

.clientFeedbackText {
  padding-left: 3px;
}

.client-dropdown-items-icon {
 margin-right: 3px;
}

.client-dropdown-items-text {
  padding-left: 3px;
}

.sidebar-subchild {
  margin-left: 1em;
}
</style>
