<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <button v-if="userEditRight" type="button" class="btn btn-info" @click="addNewPackage()">
          Create New Package
        </button>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-12">
        <div
          class="table-responsive table-bordered"
          v-if="repaymentPlanPackages.length > 0"
        >
          <table class="table">
            <thead>
              <tr class="text-nowrap">
                <th>Description</th>
                <th>Terms</th>
                <th>Once-off Price (excl. VAT)</th>
                <th>Incl. VAT</th>
                <th>Expired</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="{ 'bg-primary': repaymentPlanPackage.expired }"
                v-for="repaymentPlanPackage in repaymentPlanPackages"
                :key="repaymentPlanPackage.repaymentPlanPackageNumber"
              >
                <td>{{ repaymentPlanPackage.description }}</td>
                <td>
                  {{ repaymentPlanPackage.terms }}
                </td>
                <td>
                  {{
                    getPrice(
                      repaymentPlanPackage.repaymentplanpackageprices,
                      period
                    )
                  }}
                  <span
                    v-if="userEditRight"
                    style="cursor: pointer"
                    @click="openPriceModal(repaymentPlanPackage)"
                    v-tooltip="'Edit Row Price'"
                    class="pull-right"
                  >
                    <em class="fa fa-edit fa-fw text-green"></em>
                  </span>
                </td>
                <td class="text-nowrap">
                  {{
                    getPrice(
                      repaymentPlanPackage.repaymentplanpackageprices,
                      period,
                      true
                    )
                  }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      repaymentPlanPackage.expired
                        ? 'badge-warning'
                        : 'badge-green'
                    "
                    >{{ repaymentPlanPackage.expired }}
                  </span>
                </td>
                <td class="text-center">
                  <button
                    v-if="userEditRight"
                    v-tooltip="'Edit Package'"
                    type="button"
                    @click="editPackage(repaymentPlanPackage)"
                    class="btn btn-sm btn-info m-1"
                  >
                    <em class="fa fa-edit fa-fw"></em>
                  </button>
                  <button
                    v-if="userEditRight"
                    v-tooltip="'Delete Package'"
                    type="button"
                    @click="deletePackage(repaymentPlanPackage)"
                    class="btn btn-sm btn-primary"
                  >
                    <em class="fa fa-trash fa-fw"></em>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="alert alert-info">
          No Router Rental Packages Available
        </div>
      </div>
    </div>
    <ModifyRepaymentPlanPackageModal
      ref="modifyPackageModal"
      :pricePackage="pricePackage"
      @packageAdded="openPriceModal"
      @packageEdited="loadRepaymentPlanPackages()"
    />
    <AddPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="loadRepaymentPlanPackages()"
    />
  </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";
import ModifyRepaymentPlanPackageModal from "@/components/Admin/Modals/ModifyRepaymentPlanPackageModal";
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";

export default {
  extends: PriceFunctions,
  components: {
    ModifyRepaymentPlanPackageModal,
    AddPriceModal,
  },
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      repaymentPlanPackages: [],
      period: this.getCurrentPeriod(),
      rowPrice: [],
      newPricePackage: {},
      pricePackage: {},
      newPrice: {},
    };
  },
  mounted() {
    this.loadRepaymentPlanPackages();
  },
  methods: {
    loadRepaymentPlanPackages() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/repaymentplanpackages/product/" +
            this.productNumber
        )
        .then(
          (response) => {
            this.newPricePackage = response.data;
            this.setNewPrice();
            this.repaymentPlanPackages = response.data.repaymentplanpackages;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Repayment Plan Packages", error);
          }
        );
    },
    addNewPackage() {
      this.pricePackage = this.deepCopyObject(this.newPricePackage);
      this.$refs.modifyPackageModal.open();
    },
    editPackage(repaymentPlanPackage) {
      this.pricePackage = this.deepCopyObject(repaymentPlanPackage);
      this.$refs.modifyPackageModal.open();
    },
    setNewPrice() {
      this.newPrice = this.deepCopyObject(
        this.newPricePackage.repaymentplanpackageprice
      );
    },
    openPriceModal(repaymentPlanPackage) {
      this.rowPrice = this.deepCopyObject(
        repaymentPlanPackage.repaymentplanpackageprices || []
      );
      this.newPrice.repaymentPlanPackageNumber =
        repaymentPlanPackage.repaymentPlanPackageNumber;
      this.$refs.addPriceModal.open();
    },
    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
        }
      });
      this.rowPrice.push(newPrice);
      this.setNewPrice();
      this.newPrice.repaymentPlanPackageNumber =
        newPrice.repaymentPlanPackageNumber;
    },
    savePackage(repaymentPlanPackage) {
      this.saveData(repaymentPlanPackage).then(
        () => {
          this.showSuccess("Successfully Saved Package");
          this.loadRepaymentPlanPackages();
        },
        (error) => {
          this.showError("Error saving Package", error);
        }
      );
    },
    deletePackage(repaymentPlanPackage) {
      this.$swal({
        title: "Are you sure you want to delete this Package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/pricepackages/" +
                repaymentPlanPackage.objKey +
                "?objClassName=" +
                repaymentPlanPackage.class
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.loadRepaymentPlanPackages();
              },
              (error) => {
                this.showError("Error Deleting Price Package", error);
              }
            );
        }
      });
    },
  },
};
</script>
