<template>
  <modal
    :title="
      pricePackage.axxessLtePackageNumber
        ? 'Edit Axxess Package'
        : 'Add New Axxess Package'
    "
    ref="modifyPriceModal"
    size="modal-lg"
    @save="savePrice()"
    :disable-save="!pricePackage.axxessProductId"
  >
    <div class="row">
      <div class="form-group col-md-7">
        <v-select
          name="axxessProduct"
          :options="availableAxxessProducts"
          valueProp="guidProductId"
          v-validate="'required'"
          class="mb-2"
          label="strName"
          v-model="pricePackage.axxessProductId"
          placeholder="Choose Axxess Product"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Description </label>
        <input
          type="text"
          class="form-control"
          :disabled="!pricePackage.axxessProductId"
          v-model="pricePackage.description"
        />
      </div>
    </div>
    <div class="row">
      <div
        class="form-group col-md-10"
        v-if="pricePackage.axxessLtePackageNumber || pricePackage.axxessLteTopupNumber"
      >
        <label class="control-label"> Expired </label>
        <v-select :options="['true', 'false']" v-model="expired"></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Size </label>
        <div class="input-group">
          <input
            type="text"
            v-model="pricePackage.size"
            :disabled="!pricePackage.axxessProductId"
            class="form-control"
          />
          <div class="input-group-append">
            <span class="input-group-text">GB</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!isTopup">
      <div class="form-group col-md-10">
        <label class="control-label"> SIM activation fee (excl. VAT) </label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">R</span>
          </div>
          <input
            type="text"
            v-model="pricePackage.simActivationFee"
            :disabled="!pricePackage.axxessProductId"
            class="form-control"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  components: {
    Modal,
  },
  computed: {
    expired: {
      get() {
        return this.pricePackage.expired.toString();
      },
      set(expired) {
        this.pricePackage.expired = expired;
      },
    },
  },
  props: {
    pricePackage: {
      type: Object,
      required: true,
    },
    isTopup: {
      type: Boolean,
      default: false,
    },
    availableAxxessProducts: {
      type: Array,
      required: true,
    },
    supplier: {
      type: String,
      required: true,
    },
  },
  methods: {
    savePrice() {
      this.$emit("save-price", this.pricePackage);
      this.$refs.modifyPriceModal.close();
    },
    open() {
      this.$refs.modifyPriceModal.open();
    },
  },
};
</script>
