<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-default">
                <service-wizard-layout
                    title=""
                    subtitle=""
                    :parts="parts"
                    :onComplete="onComplete"
                    :validateStep="validateStep"
                >
                    <template slot="ionlineapn">
                        <form novalidate data-vv-scope="ionlineapn" class="col-md-6">
                            <div class="form-group":class="{'has-error': errors.has('apn.name')}">
                                <label>APN Name</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="filledInSaleDefinition.requiredObjs.apn.name"
                                    v-validate="'required'"
                                    name="name"
                                    placeholder="APN name"
                                >
                                <span class="has-error" v-if="errors.has('apn.name')">
                                    {{ errors.first("apn.name") }}
                                </span>
                            </div>
                            <div class="form-group":class="{'has-error': errors.has('apn.apnPackageNumber')}">
                                <label>Package</label>
                                <v-select 
                                    v-model="filledInSaleDefinition.requiredObjs.apn.apnPackageNumber" 
                                    :options="filledInSaleDefinition.selectableOptions.apnPackage" 
                                    valueProp="apnPackageNumber" 
                                    label="description" 
                                    placeholder="Select an APN package">
                                </v-select>
                                <span class="has-error" v-if="errors.has('apn.apnPackageNumber')">
                                    {{ errors.first("apn.apnPackageNumber") }}
                                </span>
                            </div>
                        </form>
                    </template>
                </service-wizard-layout>
            </div>
        </div>
    </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";

export default {
    components: {
        ServiceWizardLayout,
    },
    props: {
        emptySaleDefinition: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            parts: [
                {
                    name: "ionlineapn",
                    displayName: "iOnline APN",
                    icon: "fa fa-info",
                },
            ],
            filledInSaleDefinition: {
                requiredObjs: {
                    apn: {
                        
                    }
                },
                selectableOptions: {
                    apnPackage: []
                }
            }
        }
    },
    mounted() {
        this.filledInSaleDefinition = this.emptySaleDefinition;            
    },
    methods: {
        onComplete: function() {      
            this.$emit("createSale", this.filledInSaleDefinition);
        },
        validateStep: function(scope) {
            return this.$validator.validateAll(scope).then((result) => {
                if (result) {
                    return true;
                } else {
                    return false;
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>

</style>