<template>
  <div>
    <div class="row mb-2">
      <button v-if="userEditRight" type="button" @click="addPackage()" class="btn btn-info">Add new Price</button>
    </div>
    <div class="row">
      <div class="table-responsive table-bordered" v-if="packages.length > 0">
        <table class="table col-md-8">
        <thead>
          <tr class="text-nowrap">
            <th>Description</th>
            <th>Current Price (excl. VAT)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cPanelPackage in packages" :key="cPanelPackage.cPanelPackageNumber">
            <td>
              <input :disabled="!userEditRight" type="text" v-model="cPanelPackage.description" class="form-control">
            </td>
            <td>
              {{getPrice(cPanelPackage.cpanelpackageprices, period)}}
              <span v-if="userEditRight" style="cursor: pointer" v-tooltip="'Edit Row Price'" @click="openPriceModal(cPanelPackage)"
                class="pull-right">
                <em class="fa fa-edit fa-fw text-green"></em>
              </span>
            </td>
            <td class="text-center">
              <button v-if="userEditRight" v-tooltip="'Save Row'" type="button" @click="savePackage(cPanelPackage)"
                class="btn btn-sm btn-info m-1">
                <em class="fa fa-save fa-fw"></em>
              </button>
              <button v-if="userEditRight" v-tooltip="'Delete Row'" type="button" @click="deletePackage(cPanelPackage)"
                class="btn btn-sm btn-primary">
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div v-else class="alert alert-info col-md-12">No price packages to display</div>
    </div>
   <AddPriceModal ref="addPriceModal" :row-price="rowPrice" :new-price="newPrice"
      @addPrice="addPrice" @pricesAdded="getPackages()" />
    <modal title="Add New Price" ref="addPackage" size="modal-lg" @save="saveNewPackage()">
      <div class="row">
          <div class="form-group col-md-10">
            <label class="control-label">
              Description
            </label>
            <input type="text" class="form-control" v-model="newPricePackage.description">
          </div>
        </div>
    </modal>
  </div>
</template>

<script>
  import AddPriceModal from '@/components/Admin/Modals/AddPriceModal'
  import Modal from '@/components/GeneralComponents/Modal'
  import PriceFunctions from './PriceFunctions'

  export default {
    extends: PriceFunctions,
    components: {
      AddPriceModal,
      Modal
    },
    props: {
      productNumber: {
        type: Number,
        required: true
      },
      userEditRight: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        period: this.getCurrentPeriod(),
        newPrice: {},
        newPricePackage: {},
        rowPrice: [],
        packages: [],
      }
    },
    mounted() {
      this.getPackages();
    },
    methods: {
      getPackages() {
        this.$http.get(this.$config.aimsAPI + 'pricing/cpanelpackages/' + this.productNumber)
          .then(response => {
              this.packages = response.data;
            },
            error => {
              this.showError('Error fetching cPanel Packages', error)
              console.error(error);
            });
      },
      openPriceModal(cPanelPackage) {
        this.rowPrice = this.deepCopyObject(cPanelPackage.cpanelpackageprices);
        this.newPrice = this.deepCopyObject({
          cPanelPackageNumber: cPanelPackage.cPanelPackageNumber,
          class: cPanelPackage.class + 'Price',
          validFrom: 0,
          validUntil: 0
        });
        this.$refs.addPriceModal.open();
      },
      addPrice(newPrice) {
        this.rowPrice.forEach(price => {
          if (price.validUntil == 0) {
            if (newPrice.validFrom % 100 > 1) {
              price.validUntil = newPrice.validFrom - 1;
            } else {
              price.validUntil = (newPrice.validFrom - 100) + 11;
            }
          }
        });
        this.rowPrice.push(newPrice);
        this.newPrice = this.deepCopyObject({
          cPanelPackageNumber: newPrice.cPanelPackageNumber,
          class: newPrice.class,
          validFrom: 0,
          validUntil: 0
        });
      },
      addPackage() {
        this.newPricePackage.productNumber = this.productNumber;
        this.newPricePackage.class = 'za.co.adept.aims.domain.CPanelPackage';
        this.$refs.addPackage.open();
      },
      savePackage(pricePackage) {
        this.$http.put(this.$config.aimsAPI + 'pricing/cpanelpackages/update', pricePackage)
          .then(response => {
              this.showSuccess('Updated cPanel Packages Successfully')
              this.getPackages();
            },
            error => {
              this.showError('Error fetching cPanel Packages', error)
              console.error(error);
            });
      },
      saveNewPackage() {
        this.$refs.addPackage.isLoading();
        this.$http.post(this.$config.aimsAPI + 'pricing/cpanelpackages/create', this.newPricePackage)
          .then(response => {
              this.showSuccess('Saved Package Successfully');
              this.$refs.addPackage.close();
              response.data.cpanelpackageprices = [];
              this.openPriceModal(response.data);
            },
            error => {
              this.showError('Error fetching cPanel Packages', error);
              console.error(error);
            }).finally(() => {
              this.$refs.addPackage.isLoading();
            });
      },
      deletePackage(cPanelPackage) {
        this.$swal({
          title: 'Are you sure you want to delete this package?',
          text: cPanelPackage.description,
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then(result => {
          if (result.value) {
            this.$http.delete(this.$config.aimsAPI + 'pricing/cpanelpackages/' + cPanelPackage.cPanelPackageNumber)
              .then(response => {
                  this.showSuccess('Successfully deleted cPanel Package');
                  this.getPackages();
                },
                error => {
                  this.showError('Error deleting cPanel Package', error)
                  console.error(error);
                });
          }
        })
      }
    }
  }

</script>
