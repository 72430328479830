<template>
    <Modal
        ref="editPersonModal"
        :size="'modal-xl'"
        :title="modalTitle"
        :saveNeeded="false"
        :dismissNeeded="false"
        :footerNeeded="false"
        @close="close"
    >
        <div class="row">
            <div class="col-md-12">
                <form-wizard
                    title=""
                    subtitle=""
                    stepSize="md"
                    color="#3a3f51"
                    errorColor="none"
                    ref="personModal"
                    @on-complete="savePersonDetails(personSelected)" 
                >
                    <tab-content 
                        title="Person Details"
                        icon="fa fa-user"
                        :before-change="()=>validateStep('person-details')"
                    >
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group mb-0">
                                    <label>Allow User Portal Access</label>
                                    <div class="input-group">
                                        <label class="switch">
                                            <input type="checkbox" class="form-control" v-model="personSelected.user.allowPortalAccess">
                                            <span class="slider round"></span>
                                        </label>
                                        <span><strong>{{ personSelected.user.allowPortalAccess === true ? 'Enabled' : 'Disabled' }}</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" v-allowed:view="['STAFF', 'CLIENT_ADMIN']">
                                <div class="form-group mb-0 float-right">
                                    <button class="btn btn-success" @click="resetOTP(personSelected.user.personNumber)">Reset OTP</button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <form data-vv-scope="person-details">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="text" class="form-control" v-model="personSelected.user.emailAddress" disabled>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <MaskedPasswordInput 
                                            :password="personSelected.user.password"
                                            :userCanEdit="true"
                                            :showPasswordToggle="true"
                                            @changePassword="changePassword"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group" :class="{'has-error': errors.has('person-details.Firstnames')}">
                                        <label>Firstnames</label>
                                        <input type="text" class="form-control" v-model="personSelected.user.firstNames" name="Firstnames" v-validate="'required'" :class="{ 'has-error': errors.has('person-details.Firstnames') }" placeholder="Enter a Firstname">
                                        <span class="has-error" v-if="errors.has('person-details.Firstnames')">{{errors.first('person-details.Firstnames')}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" :class="{'has-error': errors.has('person-details.Lastname')}">
                                        <label>Lastname</label>
                                        <input type="text" class="form-control" v-model="personSelected.user.lastName" name="Lastname" v-validate="'required'" :class="{ 'has-error': errors.has('person-details.Lastname') }" placeholder="Enter a Lastname"> 
                                        <span class="has-error" v-if="errors.has('person-details.Lastname')">{{ errors.first('person-details.Lastname') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Job Title</label>
                                        <input type="text" class="form-control" v-model="personSelected.user.jobTitle" placeholder="Enter a Job Title">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" :class="{ 'has-error': errors.has('person-details.Mobile Number') }">
                                        <label>
                                            Mobile Number <i class="fa fa-info-circle mx-2" v-tooltip="'This number may be used for SMS notifications'" style="cursor: pointer;"></i>
                                        </label>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            v-model="personSelected.user.telephonecell"
                                            name="Mobile Number"
                                            placeholder="Enter a Mobile number"
                                            :class="{ 'has-error': errors.has('person-details.Mobile Number') }"
                                            v-validate="{ regex: /^([0-9]+)*$/ }"
                                        >
                                        <span class="has-error mt-1" v-if="errors.has('person-details.Mobile Number')">{{ errors.first("person-details.Mobile Number") }}, it should only contain numeric values [0-9]</span>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </form>
                    </tab-content>
                    <tab-content 
                        title="Access Rights Management"
                        icon="fa fa-key"
                    >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <h4>User Access Rights</h4>
                                    <div class="form-group">
                                        <label>Assign Rights</label>
                                        <div class="row" v-if="showRightMessage === true">
                                            <div class="col-md-12">
                                                <p class="badge bg-danger" style="font-size: .95em; padding: 8px 16px"><i class="fa fa-warning mr-2"></i><strong>{{ rightMessage }}</strong></p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-10" :class="{ 'col-md-12' :  rightObj === ''}">
                                                <v-select
                                                    ref="rightsOptions"
                                                    :options="availablePersonRights"
                                                    v-model="rightObj"
                                                    :placeholder="'Assign right'"
                                                    label="description"
                                                    v-if="availablePersonRights.length > 0"
                                                >
                                                </v-select>
                                            </div>
                                            <div class="col-md-2" v-if="availablePersonRights.length > 0 && rightObj != ''">
                                                <button class="btn btn-success float-right" @click="assignRightToPerson(rightObj)">Add Right</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" style="height: 275px; overflow-y: scroll; overflow-x: hidden">
                                        <ul class="list-group" v-if="personSelected.rights.length > 0">
                                            <li class="list-group-item align-items-center" v-for="(right, name) in personSelected.rights" :key="name">
                                                <div class="row">
                                                    <div class="d-flex align-items-center justify-content-between" style="width: 93%">
                                                        <div :class="showAdditionalRights(right)">
                                                            <span><i class="fa fa-user-plus mr-2"></i>{{ right.description }}</span> 
                                                        </div>
                                                        <div class="d-flex justify-content-around col-md-4 border rounded" v-if="right.options.length > 0" style="padding: .34em 0">
                                                            <div class="d-flex justify-content-between" v-for="option in right.options" :key="option.name">
                                                                <input type="checkbox" class="form-control mr-2" v-model="option.enabled" v-on="option.enabled === false ? { click: () => assignRightToPerson(option) } : { click: () => unassignPersonRight(option) }" style="width: 15px">
                                                                <strong class="">
                                                                    {{ option.description }}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <button class="btn btn-primary" @click="unassignPersonRight(right)"><i class="fa fa-trash"></i></button>                                      
                                                    </div>                                                              
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="list-group" v-else-if="availablePersonRights.length > 0 && personSelected.rights.length <= 0">
                                            <li class="list-group-item">
                                                <span><i class="fa fa-user-plus mr-2"></i>Assign rights to person</span>
                                            </li>
                                        </ul>
                                        <ul class="list-group" v-else>
                                            <li class="list-group-item">
                                                <span><i class="fa fa-user-times mr-2"></i>No rights available</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab-content>
                </form-wizard>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import MaskedPasswordInput from "@/components/GeneralComponents/InputControls/MaskedPasswordInput";
import {FormWizard, TabContent} from 'vue-form-wizard'
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { Validator } from "vee-validate";

export default {
    components: {
        Modal,
        MaskedPasswordInput,
        FormWizard,
        TabContent
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        personSelected: {
            type: Object,
            required: true,
        },
        availablePersonRights: {
            type: Array,
            required: true,
        },
        assignedRights: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            rightObj: '',
            addRights: [],
            showOptionalRights: false,
            rightMessage: 'Assign right in dropdown in order to finish',
            showRightMessage: false,
        }
    },
    methods: {
        open: function() {
            this.$refs.editPersonModal.open();
            this.$refs.personModal.reset();
        },
        showAdditionalRights(right) {            
            return right.options.length > 0 ? 'col-md-8' : 'col-md-12';
        },
        changePassword: function(data) {
            if (data) {
                this.personSelected.user.password = data;
            }
        },
        validateStep: async function(stepName) {            
            const result = await this.$validator.validateAll(stepName);
            if (result) {
                return true;
            } else {
                return false;
            }
        },
        getLabel: function(option) {
            return option.description;
        },
        savePersonDetails: function(personSelected) {  
            if (Object.keys(this.rightObj).length !== 0) {
                this.showRightMessage = true
            } else {
                this.$emit('savePerson', personSelected);
                this.closeModal();
            }  
        },
        assignRightToPerson: function(rightObj) {
            this.showRightMessage = false
            this.$emit('assignRightToPerson', rightObj);
            this.$refs.rightsOptions._data.selectedValue = null;       
            this.rightObj = "";
        },
        unassignPersonRight: function(rightObj) {
            this.$emit('removeAssignedPersonRight', rightObj);
        },
        resetOTP(data) {
            this.$swal({
                title: "Are you sure you want to reset this user's OTP?",
                type: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.$http.post(this.$config.aimsAPIv2 + 'users/person/' + data + '/otp/reset')
                    .then(response => {
                        this.showSuccess('Reset Successful');
                        this.closeModal();
                    },
                    error => {
                        this.showError('Error trying to reset OTP', error)
                        console.error(error);
                    });
                }
            })
        },
        close() {   
            this.errors.clear()
        },
        closeModal: function() {
            this.$emit('reloadPersons');
            this.showOptionalRights = false;
            this.$refs.editPersonModal.close();
            this.$refs.personModal.reset();
        }
    },
}
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #37bc9b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.cursor {
    cursor: pointer;
}
</style>
