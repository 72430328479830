<template>
  <service-screen-layout :parts="parts">
    <template v-slot:account>
      <div class="row">
        <div class="col-md-6">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label class="control-label">Username</label>
              <div class="input-group">
                <input
                  id="userText"
                  v-model="saleDefinition.definition.account.username"
                  type="text"
                  class="form-control"
                  disabled=""
                />
                <div class="input-group-append">
                  <span
                    @click="
                      copyText(saleDefinition.definition.account.username)
                    "
                    class="input-group-text btn btn-info rounded text-white border-left-0"
                  >
                    <i class="fa fa-clipboard"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label">Realm</label>
              <div class="input-group">
                <input
                  v-model="saleDefinition.definition.account.realm.name"
                  type="text"
                  class="form-control"
                  disabled=""
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="control-label">Service</label>
              <div class="input-group">
                <input
                  disabled
                  class="form-control"
                  v-model="saleDefinition.definition.account.bundle.description"
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="control-label">Password</label>
              <PasswordInput
                :password="saleDefinition.definition.account.password"
                :userCanEdit="false"
                @changePassword="changePassword"
              />
            </div>
          </div>
        </div>

        <div class="col-md-6" v-allowed:view="['STAFF', 'RESELLER', 'CLIENT', 'SYSTEM']">

          <!-- div for geolocation -->
          <div
            class="card card-default"
            v-allowed:view="['ADMIN','ACCOUNTS_ADMIN', 'ACCOUNTS_STAFF']"
            v-if="Object.keys(pendingDeleteAccountInstruction).length == 0 && notRAIN"
          >
            <div class="card-body">
              <div class="row row-spacing">
                <div class="col-md-12">
                  <label class="control-label">Geolocation</label>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-xl-6">
                  <label class="control-label">Latitude</label>
                  <div class="input-group">
                    <input
                      id="latitude"
                      v-model="saleDefinition.definition.account.geolocation.latitude"
                      type="text"
                      class="form-control"
                    />
                    <div class="input-group-append">
                      <span
                        @click="
                          copyText(saleDefinition.definition.account.geolocation.latitude)
                        "
                        class="input-group-text btn btn-info rounded text-white border-left-0"
                      >
                        <i class="fa fa-clipboard"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group col-xl-6">
                  <label class="control-label">Longitude</label>
                  <div class="input-group">
                    <input
                      id="longitude"
                      v-model="saleDefinition.definition.account.geolocation.longitude"
                      type="text"
                      class="form-control"
                    />
                    <div class="input-group-append">
                      <span
                        @click="
                          copyText(saleDefinition.definition.account.geolocation.longitude)
                        "
                        class="input-group-text btn btn-info rounded text-white border-left-0"
                      >
                        <i class="fa fa-clipboard"></i>
                      </span>
                    </div>
                  </div>
                </div>

              </div>


              <div class="form-row">

                <!-- <div class="form-group col-xl-3">
                  <button
                    type="button"
                    @click="checkFeasibility()"
                    class="btn btn-danger"
                  >
                    Check Feasibility
                  </button>
                </div> -->

                <div class="form-group col-md-auto" v-allowed:view="['STAFF']">
                  <button
                    type="button"
                    @click="saveLteAccount()"
                    class="btn btn-green"
                  >
                    Save
                  </button>
                </div>

                <div class="form-group col-md-auto" v-allowed:view="['STAFF']">
                  <button
                    type="button"
                    @click="sendGeoUnlockInstruction()"
                    class="btn btn-danger"
                  >
                    Device Unlock
                  </button>
                </div>


              </div>
            </div>
          </div>


        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-row justify-content-between">
            <div class="form-group col-md-auto">
              <div class="form-row">
                <div class="form-group col-auto">
                  <button
                    v-allowed:view="['ADMIN','ACCOUNTS_ADMIN', 'ACCOUNTS_STAFF']"
                    type="button"
                    v-if="Object.keys(pendingDeleteAccountInstruction).length > 0"
                    @click="sendRestoreAccountInstruction()"
                    class="btn btn-danger"
                  >
                    Cancel Delete Instruction
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    v-allowed:view="['ADMIN','ACCOUNTS_ADMIN', 'ACCOUNTS_STAFF']"
                    type="button"
                    :disabled="
                      Object.keys(pendingDeleteAccountInstruction).length > 0
                    "
                    v-if="
                      Object.keys(pendingSuspendAccountInstruction).length == 0 && notRAIN
                    "
                    @click="sendSuspendAccountInstruction()"
                    class="btn btn-danger"
                  >
                    Send Suspend Instruction
                  </button>
                  <button
                    v-allowed:view="['ADMIN','ACCOUNTS_ADMIN', 'ACCOUNTS_STAFF']"
                    type="button"
                    :disabled="
                      Object.keys(pendingDeleteAccountInstruction).length > 0
                    "
                    v-else-if="notRAIN"
                    @click="sendRestoreAccountInstruction()"
                    class="btn btn-green"
                  >
                    Send Restore Instruction
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- div for service termination -->
        <div
          class="col-md-6"
          v-allowed:view="['STAFF', 'RESELLER', 'CLIENT', 'SYSTEM']"
        >
          <div
            class="card card-default"
            v-allowed:view="['ADMIN','ACCOUNTS_ADMIN', 'ACCOUNTS_STAFF']"
            v-if="Object.keys(pendingDeleteAccountInstruction).length == 0 && notRAIN"
          >
            <div class="card-body">
              <div class="row row-spacing">
                <div class="col-md-12">
                  <label class="control-label"
                    >Service termination reason</label
                  >
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-xl-4">
                  <select class="form-control" v-model="reasonSelected">
                    <option
                      :value="reason"
                      v-for="reason in reasonCodes"
                      :key="reason"
                    >
                      {{ reason }}
                    </option>
                  </select>
                </div>
                <div class="col-xl-4">
                  <button
                    type="button"
                    @click="sendDeleteAccountInstruction()"
                    class="btn btn-danger"
                  >
                    Terminate Service
                  </button>
                </div>
              </div>

              <div class="row row-spacing">
                <div class="col-md-12">
                  <label class="control-label"
                    >Swap Out Device reason</label
                  >
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-xl-4">
                  <select class="form-control" v-model="swapReasonSelected">
                    <option
                      :value="swapreason"
                      v-for="swapreason in swapReasonCodes"
                      :key="swapreason"
                    >
                      {{ swapreason }}
                    </option>
                  </select>
                </div>
                <div class="col-xl-4">
                  <button
                    type="button"
                    @click="sendSwapOutInstruction()"
                    class="btn btn-danger"
                  >
                    Swap Out Device
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </template>
    <template v-slot:data>
      <UsageSummary v-if="notRAIN"
        :usageSummary="saleDefinition.definition.account.currentUsageSummary"
      />
      <span v-else >RAIN Services no longer supported. Please contact MWEB Reseller for support.</span>
    </template>
    <template v-slot:usage>
      <div class="row row-spacing" style="font-size: 20px" v-if="notRAIN">
        <div class="col-md-5">
          <button
            type="button"
            class="btn btn-default"
            @click="previousMonthUsage()"
          >
            <i class="fa fa-arrow-left"></i> Previous Month
          </button>
        </div>
        <div class="col-md-2" style="text-align: center">
          <h3>{{ prettifyBillingPeriod(usageMonth) }}</h3>
        </div>
        <div class="col-md-5">
          <button
            type="button"
            :disabled="getCurrentPeriod() == usageMonth"
            class="btn btn-default pull-right"
            @click="nextMonthUsage()"
          >
            Next Month <i class="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="row row-spacing" v-if="notRAIN">
        <div class="col-md-12" style="text-align: center">
          <h4>Total for Month: {{ sizeBytesToGb(totalUsageForMonth) }} GB</h4>
        </div>
      </div>
      <div class="row row-spacing" v-if="notRAIN">
        <div class="col-md-12">
          <UsageBarGraph
            :title="'LTE Usage'"
            :usageData="currentDailyUsageData"
            :showDownloadedOnly="true"
            :height="150"
          />
        </div>
      </div>
      <span v-else>RAIN Services no longer supported. Please contact MWEB Reseller for support.</span>
    </template>
    <template v-slot:order>
      <div class="row">
        <div class="col-md-5">
          <div class="card card-default">
            <div class="card-header">LTE Order</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">Created:</div>
                <div class="col-md-6">
                  <strong>{{
                    dateTimeFormat(saleDefinition.definition.account.order.created)
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Device type:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.lteDevice
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Order status:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.statusRain
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Delivered on:</div>
                <div class="col-md-6">
                  <strong>{{
                    dateTimeFormat(saleDefinition.definition.account.order.dateDelivered)
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">AIMS status:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.statusAims
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Order reference:</div>
                <div class="col-md-6">
                  <strong style="color: dodgerblue">{{
                    saleDefinition.definition.account.order.orderRequestId
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Courier:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.courier
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Courier Reference:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.courierReference
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Courier Comment:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.courierComment
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Router serial number:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.routerSerialNumber
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">MSISDSN number:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.msisdsnNumber
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">SIM number:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.simNumber
                  }}</strong>
                </div>
              </div>
            </div>
            <div class="card card-footer" v-if="notRAIN">
              <p v-if="Object.keys(pendingCancelOrderInstruction).length > 0">
                <i class="fa fa-info-circle"></i>
                Order is pending cancellation, instruction was created
                {{ dateTimeFormat(pendingCancelOrderInstruction.created) }}
              </p>
              <button
                v-allowed:view="['ADMIN', 'ACCOUNTS-WITH-AUDIT']"
                :disabled="
                  Object.keys(pendingCancelOrderInstruction).length > 0 ||
                  saleDefinition.definition.account.order.statusAims ==
                    'Active' ||
                  saleDefinition.definition.account.order.statusAims ==
                    'Cancelled'
                "
                class="btn btn-danger"
                type="button"
                @click="sendCancelOrderInstruction()"
              >
                Cancel Order
              </button>

            </div>
          </div>
        </div>

        <div class="col-md-5">
          <div class="card card-default">
            <div class="card-header">Delivery Address</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">Address type:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress
                      .addressType
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Sub premise:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress
                      .subpremise
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Street number:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress
                      .streetNumber
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Street name:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress
                      .streetName
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Suburb:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress.suburb
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">City:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress.city
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Province:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress
                      .province
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Country:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress.country
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Postal Code:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress.postalCode
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Latitude:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress.latitude
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Longitude:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.order.orderAddress.longitude
                  }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <div class="card card-default">
            <div class="card-header">LTE Package</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">LTE Provider:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.bundle.description
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Package:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.bundle.packageId
                  }}</strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">Package size:</div>
                <div class="col-md-6">
                  <strong
                    >{{
                      saleDefinition.definition.account.bundle.size
                    }}
                    GB</strong
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">&nbsp;</div>
                <div class="col-md-6"></div>
              </div>
              <div class="row" v-allowed:view="['STAFF']">
                <div class="col-md-6">LTE Account number:</div>
                <div class="col-md-6">
                  <strong>{{
                    saleDefinition.definition.account.lteAccountNumber
                  }}</strong>
                </div>
              </div>
              <div class="row" v-allowed:view="['STAFF']">
                <div class="col-md-6">&nbsp;</div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <RicaRegistration
            v-allowed:view="['STAFF', 'RESELLER']"
            :ricaDetails="saleDefinition.definition.account.rica"
          />
        </div>
      </div>
    </template>
    <template v-slot:topups  v-if="saleDefinition.definition.account.bundle.size > 0">
      <div class="row">
        <div class="col-md-12" >
          <AllocatedTopups v-if="notRAIN"
            :heading="'Topups'"
            :showStatus="true"
            :hideUsage="true"
            @topupAdded="topupAdded"
            :availableTopups="saleDefinition.selectableOptions.topup"
            :allocatedTopups="saleDefinition.definition.account.allocatedTopups"
          />
          <span v-else>RAIN Services no longer supported. Please contact MWEB Reseller for support.</span>
        </div>

      </div>
    </template>
    <template v-slot:currentsessions>
      <div class="row" v-if="notRAIN">
        <div class="col-md-12">
          <button
            class="btn btn-primary"
            type="button"
            @click="getSessions()"
            :disabled="loadingSessions"
          >
            Get Current Sessions
          </button>
        </div>
      </div>
      <div class="row row-spacing" v-if="notRAIN">
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Connect Time</th>
                <th>Session ID</th>
                <th>Subscriber IP</th>
                <th>MSISDSN</th>
              </tr>
            </thead>
            <tbody v-if="currentSessions.length > 0">
              <tr
                v-for="currentSession in currentSessions"
                :key="currentSession.session_id"
              >
                <td>{{ currentSession.connect_time }}</td>
                <td>{{ currentSession.session_id }}</td>
                <td>{{ currentSession.subscriber_ip }}</td>
                <td>{{ currentSession.MSISDSN }}</td>
              </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="4">No Session info to display</td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-slot:notifications>
      <div class="row">
        <div class="col-md-12">
          <UsageRecipients  v-if="notRAIN"
            :usageRecipients="saleDefinition.definition.account.usageRecipients"
            @recipientAdded="addUsageRecipient"
            @recipientRemoved="removeUsageRecipient"
          />
          <span v-else>RAIN Services no longer supported. Please contact MWEB Reseller for support.</span>
        </div>
      </div>
    </template>
    <template v-slot:instructions>
      <div class="row">
        <div class="col-md-9">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Created</th>
                <th>Instruction</th>
                <th>Effective Date</th>
                <th>Status</th>
                <th>Request ID</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="cursor: pointer"
                v-for="instruction in saleDefinition.definition.account
                  .instructions"
                :key="instruction.lteInstructionNumber"
                v-tooltip="instruction.requestMessage"
              >
                <td>{{ dateTimeFormat(instruction.created) }}</td>
                <td>{{ instruction.instructionType }}</td>
                <td>{{ dateTimeFormat(instruction.effectiveDate) }}</td>
                <td>{{ instruction.instructionStatus }}</td>
                <td>{{ instruction.requestId}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";
import UsageBarGraph from "@/components/Graphs/UsageBarGraph";
import RicaRegistration from "@/components/ObjParts/SingleCaptured/RicaRegistration";
import AllocatedTopups from "@/components/ObjParts/MultipleCaptured/AllocatedTopups";
import UsageRecipients from "@/components/ObjParts/MultipleCaptured/UsageRecipients";
import UsageSummary from "@/components/ObjParts/SingleConstructed/UsageSummary";

export default {
  components: {
    ServiceScreenLayout,
    RicaRegistration,
    UsageBarGraph,
    AllocatedTopups,
    PasswordInput,
    UsageRecipients,
    UsageSummary,
  },
  watch: {
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.sale.description != newSaleDef.sale.description && this.$parent.$refs.serviceTitle) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
  },
  data() {
    return {
      parts: [
        {
          name: "account",
          icon: "fa fa-info-circle",
          displayName: "Account",
          permissions: ["*"],
        },
        {
          name: "data",
          icon: "fa fa-database",
          displayName: "Usage Summary",
          permissions: ["*"],
        },
        {
          name: "usage",
          icon: "fa fa-bar-chart-o",
          displayName: "Monthly Usage",
          permissions: ["*"],
        },
        {
          name: "order",
          icon: "fa fa-address-book",
          displayName: "Order Info",
          permissions: ["*"],
        },
        {
          name: "topups",
          icon: "fa fa-plus-square",
          displayName: "Topups",
          permissions: ["*"],
        },
        {
          name: "currentsessions",
          icon: "fa fa-clock-o",
          displayName: "Current Sessions",
          permissions: ["*"],
        },
        {
          name: "notifications",
          icon: "fa fa-bullhorn",
          displayName: "Notifications",
          permissions: ["*"],
        },
        {
          name: "instructions",
          icon: "fa fa-arrows-v",
          displayName: "Instructions History",
          permissions: ["*"],
        },
      ],
      loadingSessions: false,
      usageMonth: null,
      saleDefinition: {
        sale: {
          label: "",
        },
        definition: {
          account: {
            realm: {},
            bundle: {},
            order: {
              orderAddress: {},
            },
            rica: {
              ricaAddress: {},
            },
            geolocation: {
              latitude: '',
              longitude: ''
            },
            currentUsageSummary: {},
            dailyUsageSummary: [],
            allocatedBundles: [],
            allocatedTopups: [],
            instructions: [],
            usageRecipients: [],
          },
          discount: {
            description: "",
            amount: 0.0,
          },
        },
        supportingObjs: {
          discount: {},
          emptyObject: {},
        },
        selectableOptions: {
          topup: [],
        },
        supportingEnums: {
          instructions: {
            reasonCode: [],
            instructionStatus: []
          },
        }
      },
      reasonCodes: [
        "CHANGEPROVIDER",
        "POORSERVICE",
        "CONNECTIVITY",
        "MOVINGCONNECTIVITY",
        "PRICING",
        "RESELLERTERMINATIONADMIN",
        "RESELLERTERMINATIONBREACH",
        "ISTERMINATION",
      ],
      swapReasonCodes: [
        "OUTOFBOX",
        "FAULTYDEVICE",
        "FAULTYSIM",
        "LOSTSIM"
      ],
      reasonSelected: null,
      swapReasonSelected: null,
      billingTree: null,
      currentSessions: [],
      currentDailyUsageData: [],
      totalUsageForMonth: 0,
      histories: [],
      tooltip: "",
      notRAIN: true,
      // clientReadOnly: false,
      vendor: {}
    };
  },
  computed: {
    pendingCancelOrderInstruction: function () {
      var cancelOrderInstruction = {};
      this.saleDefinition.definition.account.instructions.map((ci) => {
        if (
          this.sameMonth(new Date(ci.created), new Date()) &&
          ci.instructionType === "Cancel order"
        ) {
          cancelOrderInstruction = ci;
          return;
        }
      });

      return cancelOrderInstruction;
    },
    pendingDeleteAccountInstruction() {
      var deleteAccountInstruction = {};
      this.saleDefinition.definition.account.instructions.map((ci) => {
        if (
          this.sameMonth(new Date(ci.created), new Date()) &&
          ci.instructionType === "Delete account"
        ) {
          deleteAccountInstruction = ci;
        } else if (
          this.sameMonth(new Date(ci.created), new Date()) &&
          ci.instructionType === "Restore account"
        ) {
          deleteAccountInstruction = {};
        }
      });

      return deleteAccountInstruction;
    },
    pendingSuspendAccountInstruction: function () {
      var suspendAccountInstruction = {};
      this.saleDefinition.definition.account.instructions.map((ci) => {
        if (
          this.sameMonth(new Date(ci.created), new Date()) &&
          ci.instructionType === "Suspend account"
        ) {
          suspendAccountInstruction = ci;
        } else if (
          this.sameMonth(new Date(ci.created), new Date()) &&
          ci.instructionType === "Restore account"
        ) {
          suspendAccountInstruction = {};
        }
      });

      return suspendAccountInstruction;
    },
    pendingSwapOutInstruction: function () {
      var swapOutInstruction = {};
      this.saleDefinition.definition.account.instructions.map((ss) => {
        if (
          this.sameMonth(new Date(ss.created), new Date()) &&
          ss.instructionType === "Swapout Device"
        ) {
          swapOutInstruction = ss;
          return;
        }
      });

      return swapOutInstruction;
    },
  },
  mounted: function () {
    this.reloadService();
  },
  methods: {
    reloadService() {
      this.usageMonth = this.getCurrentPeriod();
      const saleNumber = this.$route.params.saleNumber;
      this.$http
        .get(this.$config.aimsAPI + "sales/definition/sale/" + saleNumber)
        .then(
          (response) => {
            this.saleDefinition = response.data;

            //use this to disable all components for non RAIN support
            this.getProductVendor(this.saleDefinition.product.vendorNumber);

            if (this.saleDefinition.definition.account.bundle.size == 0) {
              this.parts = this.parts.filter(data => data.name != 'topups');
            }
                      
            // //for now nothing is needed to be done, there is staff level restriction on all critical components already
            // if ((this.saleDefinition.product.clientAccessRestriction === 'Client Read-Only')
            //      || (this.saleDefinition.product.clientAccessRestriction === 'Client Usage Allowed')){
            //   this.clientReadOnly = true;
            // }
            // console.log("reloadService");
            // console.log(this.saleDefinition.definition.account.currentUsageSummary);
            
            //todo this seems to show the last month that had usage, if there is no usage in current month
            this.translateCurrentDailyUsageData(
              this.saleDefinition.definition.account.dailyUsageSummary
            );
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching LTE Account", error);
          }
        )
        .finally(() => {
            
        });       
    },
    addUsageRecipient(newUsageRecipient) {
      const newRecipient = {};
      newRecipient.class = "za.co.adept.aims.lte.LteUsageRecipient";
      newRecipient.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;
      newRecipient.recipient = newUsageRecipient;
      this.$http
        .post(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/create/",
          newRecipient
        )
        .then(
          (response) => {
            this.saleDefinition.definition.account.usageRecipients.push(
              response.data
            );
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Usage Recipient", error);
          }
        );
    },
    previousMonthUsage() {
      if (("" + this.usageMonth).substring(4, 6) == "01") {
        this.usageMonth = this.usageMonth - 100 + 11; //dec last year
      } else {
        this.usageMonth--;
      }
      this.$http
        .get(
          this.$config.aimsAPI +
            "mweb/mtn/" +
            this.saleDefinition.definition.account.lteAccountNumber +
            "/usage/" +
            this.usageMonth
        )
        .then(
          (response) => {
            this.translateCurrentDailyUsageData(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Usage", error);
          }
        );
    },
    nextMonthUsage() {
      if (this.usageMonth < this.getCurrentPeriod()) {
        if (("" + this.usageMonth).substring(4, 6) == "12") {
          this.usageMonth = this.usageMonth + 100 - 11; //jan next year
        } else {
          this.usageMonth++;
        }
        this.$http
          .get(
            this.$config.aimsAPI +
              "mweb/mtn/" +
              this.saleDefinition.definition.account.lteAccountNumber +
              "/usage/" +
              this.usageMonth
          )
          .then(
            (response) => {
              this.translateCurrentDailyUsageData(response.data);
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Usage", error);
            }
          );
      }
    },
    removeUsageRecipient(recipient) {
      this.$http
        .post(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/delete/",
          recipient
        )
        .then(
          (response) => {
            const indexOfRec = this.saleDefinition.definition.account.usageRecipients.findIndex(
              (r) => r.objKey === recipient.objKey
            );
            this.saleDefinition.definition.account.usageRecipients.splice(
              indexOfRec,
              1
            );
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Usage Recipient", error);
          }
        );
    },
    saleDiscountChanged: function (modifiedDiscount) {
      this.saleDefinition.definition.discount = modifiedDiscount;
    },
    changePassword: function(data) {
        if (data) {
          this.saleDefinition.definition.account.password = data;
        }
    },
    saveLteAccount: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving LTE Account", error);
              }
            );
        }
      });
    },
    translateCurrentDailyUsageData: function (usageData) {
      // console.log(usageData);
      this.currentDailyUsageData = [];
      this.totalUsageForMonth = 0;
      usageData.map((c) => {
        this.totalUsageForMonth += c.usedBytes;
        const translatedDataUsageDay = {
          label: ("" + c.date).substring(8, 10) + " ",
          downloaded: c.usedBytes,
        };
        this.currentDailyUsageData.push(translatedDataUsageDay);
      });
    },
    getSessions: function () {
      this.loadingSessions = true;
      this.$http
        .get(
          this.$config.aimsAPI +
            "mweb/mtn/sessions/" +
            this.saleDefinition.definition.account.lteAccountNumber
        )
        .then(
          (response) => {
            this.currentSessions = response.data;
            this.loadingSessions = false;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching LTE Session", error);
            this.loadingSessions = false;
          }
        );
    },
    topupAdded: function (topupSelected) {
      const accountTopup = {};
      accountTopup.class = "za.co.adept.aims.lte.LteAllocatedTopup";
      accountTopup.lteTopupNumber = topupSelected.lteTopupNumber;
      accountTopup.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;

      this.$http
        .post(
          // this.$config.aimsAPI +
          //   "sales/definition/" +
          //   this.saleDefinition.sale.saleNumber +
          //   "/obj/create/",
          // accountTopup

          this.$config.aimsAPIv2 +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/create?commit=true",
          accountTopup

        )
        .then(
          (response) => {
            response.data.isNew = true;
            response.data.topup = this.deepCopyObject(topupSelected);
            this.saleDefinition.definition.account.allocatedTopups.unshift(
              response.data
            );
            this.showSuccess("Topup successfully added");
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Topup", error);
          }
        );
    },
    sendSwapOutDeviceInstruction: function () {
      this.$swal({
        title: "Are you sure you want to request a DEVICE swap?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, request DEVICE swap",
        cancelButtonText: "No, don't",
      }).then((result) => {
        if (result.value) {
          const swapOutInstruction = {};
          swapOutInstruction.class = "za.co.adept.aims.lte.LteInstruction";
          swapOutInstruction.instructionType = "SWAPOUTDEVICE";
          swapOutInstruction.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;
          swapOutInstruction.lteBundleNumber = this.saleDefinition.definition.account.lteBundleNumber;
          this.createLteInstruction(swapOutInstruction);
        } else {

        }
      });
    },
    sendCancelOrderInstruction: function () {
      this.$swal({
        title: "Are you sure you want to cancel this order?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel it",
        cancelButtonText: "No, don't",
      }).then((result) => {
        if (result.value) {
          const cancelOrderInstruction = {};
          cancelOrderInstruction.class = "za.co.adept.aims.lte.LteInstruction";
          cancelOrderInstruction.instructionType = "CANCELORDER";
          cancelOrderInstruction.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;
          cancelOrderInstruction.lteBundleNumber = this.saleDefinition.definition.account.lteBundleNumber;

          this.createLteInstruction(cancelOrderInstruction);
        } else {
        }
      });
    },
    sendDeleteAccountInstruction: function () {
      if (this.reasonSelected && this.reasonSelected != "Select a reason") {
        this.$swal({
          title: "Are you sure you want to Terminate this account's service?",
          text: "This will be effective end of the month",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, terminate it",
        }).then((result) => {
          if (result.value) {
            const deleteAccountInstruction = {};
            deleteAccountInstruction.class =
              "za.co.adept.aims.lte.LteInstruction";
            deleteAccountInstruction.instructionType = "DELETEACC";
            deleteAccountInstruction.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;
            deleteAccountInstruction.lteBundleNumber = this.saleDefinition.definition.account.lteBundleNumber;
            deleteAccountInstruction.reasonCode = this.reasonSelected;
            this.createLteInstruction(deleteAccountInstruction);
          } else {
          }
        });
      }
    },
    sendSuspendAccountInstruction: function () {
      this.$swal({
        title: "Are you sure you want to suspend this account?",
        text: "You can restore the account later",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, suspend it",
      }).then((result) => {
        if (result.value) {
          const suspendAccountInstruction = {};
          suspendAccountInstruction.class =
            "za.co.adept.aims.lte.LteInstruction";
          suspendAccountInstruction.instructionType = "SUSPENDACC";
          suspendAccountInstruction.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;
          suspendAccountInstruction.lteBundleNumber = this.saleDefinition.definition.account.lteBundleNumber;

          this.createLteInstruction(suspendAccountInstruction);
        } else {
        }
      });
    },
    sendRestoreAccountInstruction: function () {
      const restoreAccountInstruction = {};
      restoreAccountInstruction.class = "za.co.adept.aims.lte.LteInstruction";
      restoreAccountInstruction.instructionType = "RESTOREACC";
      restoreAccountInstruction.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;
      restoreAccountInstruction.lteBundleNumber = this.saleDefinition.definition.account.lteBundleNumber;

      this.createLteInstruction(restoreAccountInstruction);
    },
    createLteInstruction: function (instruction) {
      this.$http
        .post(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/create",
          instruction
        )
        .then(
          (response) => {
            this.showSuccess("Instruction Sent");
            this.saleDefinition.definition.account.instructions.push(
              response.data
            );
            this.reloadService();
          },
          (error) => {
            console.error(error);
            this.showError("Error Creating Instruction", error);
          }
        );
    },

    // checkFeasibility() {
    //   this.$http.get(
    //       this.$config.aimsAPI + "connectivityaccounts/lte/checkcoverage"
    //                                             + "/" + this.saleDefinition.definition.account.geolocation.latitude
    //                                             + "/" + this.saleDefinition.definition.account.geolocation.longitude
    //                                             + "/" + "MTN")
    //     .then(
    //       (response) => {
        //       },
    //       (error) => {
    //         console.error(error);
    //         this.showError("Error Checking Feasibilty", error);
    //       }
    //     );
    // },

    sendGeoUnlockInstruction: function () {
      this.$swal({
        title: "Are you sure you want to unlock the coordinates?",
        text: this.saleDefinition.definition.account.geolocation.latitude + " " + this.saleDefinition.definition.account.geolocation.longitude,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Update",
      }).then((result) => {
        if (result.value) {
          const geoUnlockInstruction = {};
          geoUnlockInstruction.class = "za.co.adept.aims.lte.LteInstruction";
          geoUnlockInstruction.instructionType = "UNLOCKMTNSIM";
          geoUnlockInstruction.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;
          geoUnlockInstruction.lteBundleNumber = this.saleDefinition.definition.account.lteBundleNumber;
          this.createLteInstruction(geoUnlockInstruction);
        } else {
        }
      });
    },

    sendSwapOutInstruction: function () {
      if (this.swapReasonSelected && this.swapReasonSelected != "Select a reason") {
        this.$swal({
          title: "Are you sure you want to Swap Device?",
          text: "This will required a new device delivery",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, do Swap Out",
        }).then((result) => {
          if (result.value) {
            const swapOutInstruction = {};
            swapOutInstruction.class =
              "za.co.adept.aims.lte.LteInstruction";
            swapOutInstruction.instructionType = "SWAPOUTDEVICE";
            swapOutInstruction.lteAccountNumber = this.saleDefinition.definition.account.lteAccountNumber;
            swapOutInstruction.lteBundleNumber = this.saleDefinition.definition.account.lteBundleNumber;
            swapOutInstruction.reasonCode = this.swapReasonSelected;
            swapOutInstruction.instructionStatus = "PENDING";
            swapOutInstruction.requestId = "0";
            this.createLteInstruction(swapOutInstruction);
          } else {
          }
        });
      }
    },
    getProductVendor: function(vendorNumber) {
        const hostUrl = this.$config.aimsAPIv2;
            this.$http.get(`${hostUrl}vendors/`+vendorNumber).then(
              (response) => {
                  this.vendor = response.data;   
                  // console.log("vendor: " + this.vendor.name);    
                  if (this.vendor.name) {
                      this.notRAIN = this.vendor.name.localeCompare("RAIN");
                  }
              },
              (error) => {
                  console.error(error);
                  this.showError("Error fetching product Vendor", error);
              }
          );
    }


  }
};
</script>
