<template>
  <modal
    :title="
      pricePackage.connectivityPackageNumber
        ? 'Edit Package'
        : 'Add New Package'
    "
    ref="modifyPriceModal"
    size="modal-lg"
    @save="savePricePackage()"
  >
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Shaped Pool </label>
        <v-select
          ref="shapedPool"
          name="Shaped Pool"
          :options="shapedPools"
          v-model="pricePackage.shapedPoolNumber"
          :get-option-label="getLabel"
          placeholder="Select Shaped Pool"
          valueProp="shapedPoolNumber"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Description </label>
        <input
          type="text"
          class="form-control"
          v-model="pricePackage.description"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Term </label>
        <v-select
          ref="contractTerm"
          name="Term"
          :options="contractTerms"
          v-model="pricePackage.contractTerm"
          placeholder="Select Term"></v-select>
      </div>  
    </div>
    <div class="row">
      <div
        class="form-group col-md-10"
        v-if="pricePackage.connectivityPackageNumber"
      >
        <label class="control-label"> Expired </label>
        <v-select :options="['true', 'false']" v-model="expired"></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Size </label>
        <div class="input-group">
          <input type="text" v-model="pricePackage.size" class="form-control" />
          <div class="input-group-append">
            <span class="input-group-text">GB</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Download Speed </label>
        <div class="input-group">
          <input type="text" v-model="downloadSpeed" class="form-control" />
          <div class="input-group-append">
            <span class="input-group-text">Mbit/s</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Upload Speed </label>
        <div class="input-group">
          <input type="text" v-model="uploadSpeed" class="form-control" />
          <div class="input-group-append">
            <span class="input-group-text">Mbit/s</span>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  props: {
    pricePackage: {
      type: Object,
      required: true,
    },
    shapedPools: {
      type: Array,
      required: true,
    },
    contractTerms: {
      type: Array,
      required: true,
    }
  },
  computed: {
    expired: {
      get() {
        return this.pricePackage.expired.toString();
      },
      set(expired) {
        this.pricePackage.expired = expired;
      },
    },
    downloadSpeed: {
      get() {
        return this.speed_bitsToMbits(
          this.pricePackage.downloadSpeed
        );
      },
      set(downloadSpeed) {
        this.pricePackage.downloadSpeed = this.speed_mbitsToBits(
          downloadSpeed
        );
      },
    },
    uploadSpeed: {
      get() {
        return this.speed_bitsToMbits(
          this.pricePackage.uploadSpeed
        );
      },
      set(uploadSpeed) {
        this.pricePackage.uploadSpeed = this.speed_mbitsToBits(
          uploadSpeed
        );
      },
    },
  },
  components: {
    Modal,
  },
  methods: {
    open() {
      this.$refs.modifyPriceModal.open();
    },
    getLabel({ name }) {
      return name;
    },
    savePricePackage() {
      this.$refs.modifyPriceModal.isLoading();
      this.$http
        .post(
          this.$config.aimsAPI + "pricing/pricepackages/save",
          this.pricePackage
        )
        .then(
          (response) => {
            this.$refs.modifyPriceModal.close();
            if (this.pricePackage.connectivityPackageNumber) {
              this.showSuccess("Successfully Edited Package");
              this.$emit("packageEdited");
            } else {
              this.showSuccess("Successfully Added Package");
              this.$emit("packageAdded", response.data);
            }
          },
          (error) => {
            this.showError("Error Saving Pricing", error);
            console.error(error);
          }
        )
        .finally(() => {
          this.$refs.modifyPriceModal.isLoading();
        });
    },
  },
};
</script>
