<template>
  <modal
    :title="this.pricePackage.solarwindsBackupPackageNumber ?  'Edit Package' : 'Add New Package'"
    ref="modifyPriceModal"
    @save="savePricePackage()"
  >
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Description </label>
        <input
          type="text"
          class="form-control"
          v-model="pricePackage.description"
        />
      </div>
    </div>
    <div class="row">
      <div
        class="form-group col-md-10"
        v-if="pricePackage.solarwindsBackupPackageNumber"
      >
        <label class="control-label"> Expired </label>
        <v-select :options="['true', 'false']" v-model="expired"></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Licence Type </label>
        <v-select
          :options="[
            { name: 'SERVER', description: 'Server' },
            { name: 'DESKTOP', description: 'Desktop' },
          ]"
          valueProp="name"
          label="description"
          v-model="pricePackage.licenceType"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Management Type </label>
        <v-select
          :options="[
            { name: 'ADEPT', description: 'Adept-Managed' },
            { name: 'SELF', description: 'Self-Managed' },
          ]"
          valueProp="name"
          label="description"
          v-model="pricePackage.managementType"
        ></v-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label"> Included Storage </label>
        <div class="input-group">
          <input type="text" v-model="storage" class="form-control" />
          <div class="input-group-append">
            <span class="input-group-text">GB</span>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  props: {
    pricePackage: {
      type: Object,
      required: true,
    },
  },
  components: {
    Modal,
  },
  computed: {
    expired: {
      get() {
        return this.pricePackage.expired.toString();
      },
      set(expired) {
        this.pricePackage.expired = expired;
      },
    },
    storage: {
      get() {
        return this.sizeBytesToGb(
          this.pricePackage.includedStorage
        );
      },
      set(storage) {
        this.pricePackage.includedStorage = this.sizeGbToBytes(
          storage
        );
      },
    },
  },
  methods: {
    open() {
      this.$refs.modifyPriceModal.open();
    },
    savePricePackage() {
      this.$refs.modifyPriceModal.isLoading();
      if (this.pricePackage.solarwindsBackupPackageNumber) {
        this.$http
        .put(
          this.$config.aimsAPI + "pricing/packages/update",
          this.pricePackage
        )
        .then(
          (response) => {
            this.$refs.modifyPriceModal.close();
            this.showSuccess("Successfully Edited Package");
            this.$emit("packageEdited");
          },
          (error) => {
            this.showError("Error Saving Pricing", error);
            console.error(error);
          }
        )
        .finally(() => {
          this.$refs.modifyPriceModal.isLoading();
        });
      } else {
          this.$http
            .post(
              this.$config.aimsAPI + "pricing/packages/create", this.pricePackage
            )
          .then(
            (response) => {
              this.$refs.modifyPriceModal.close();
              this.showSuccess("Successfully Added Package");
              this.$emit("packageAdded", response.data);
            },
            (error) => {
              this.showError("Error Saving Pricing", error);
              console.error(error);
            }
          )
          .finally(() => {
            this.$refs.modifyPriceModal.isLoading();
          });
      }
    },
  },
};
</script>