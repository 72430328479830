<template>
    <div>
       <div>
            <ul class="nav nav-pills nav-fill" role="tablist">
                <li class="nav-item">
                    <a 
                        href="#apnPackage" 
                        class="nav-link active"
                        role="tab"
                        aria-controls="apnPackage"
                        aria-selected="true"
                        data-toggle="tab"
                    >
                        <i class="fa fa-dropbox"></i>
                        iOnline APN Packages
                    </a>
                </li>
            </ul>
       </div>
       <div class="row row-spacing mb-2">
        <div class="col-md-12">
            <button class="btn btn-info" @click="createNewPackage()">
                Add New Package
            </button>
        </div>
       </div>
       <div class="row row-spacing mb-2">
        <div class="col-md-12">
            <div class="table-responsive table-bordered" v-if="apnPackages.length > 0">
                <table class="table">
                    <thead>
                        <tr class="text-nowrap">
                            <th>Description</th>
                            <th>Bundle Size (GB)</th>
                            <th>Max No. of Sims</th>
                            <th>Price (excl. VAT)</th>
                            <th>Out of Bundle (excl. VAT) per MB</th>
                            <th>Per SIM (excl. VAT)</th>
                            <th>Expired</th>
                            <th v-if="userEditRight"></th>
                        </tr>
                    </thead>
                    <tbody 
                        v-for="apnPackage in apnPackages"
                        :key="apnPackage.apnPackageNumber"
                        :class="{ 'bg-primary': apnPackage.expired }"
                    >
                        <tr>
                            <td class="text-nowrap text-center">{{ apnPackage.description }}</td>
                            <td class="text-nowrap text-center">{{ apnPackage.dataBundleSize }}</td>
                            <td class="text-nowrap text-center">{{ apnPackage.maxNumberOfSims }}</td>
                            <td>
                                {{ getPrice(apnPackage.prices, period) }}
                                <span v-if="userEditRight" @click="openPriceModal(apnPackage)" v-tooltip="'Edit Row Price'" class="pull-right price-edit">
                                    <em class="fa fa-edit fa-fw text-green"></em>
                                </span>
                            </td>
                            <td class="text-nowrap">
                                {{ getApnPackagePrice(apnPackage.prices, period) }}
                            </td>
                            <td>
                                {{ getApnPerSimPrice(apnPackage.prices, period) }}
                            </td>
                            <td>
                                <span class="badge" :class="apnPackage.expired ? 'badge-warning' : 'badge-green'">
                                    {{ apnPackage.expired }}
                                </span>
                            </td>
                            <td class="text-center" v-if="userEditRight">
                                <button v-tooltip="'Edit Package'" @click="editPackage(apnPackage)" type="button" class="btn btn-sm btn-info m-1">
                                    <em class="fa fa-edit fa-fw"></em>
                                </button>
                                <button v-tooltip="'Delete Package'" type="button" @click="deletePackage(apnPackage)" class="btn btn-sm btn-primary">
                                    <em class="fa fa-trash fa-fw"></em>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="alert alert-info">No APN Packages Available</div>
        </div>
       </div>
       <IonlineApnPriceModal 
            ref="addPriceModal"
            :row-price="rowPrice"
            :new-price="newPrice"
            @addPrice="addPrice"
            @pricesAdded="getApnPackages"
       />
       <ModifyIonlineApnPackageModal 
            ref="modifyPackageModal"
            :pricePackage="pricePackage"
            @savePricePackage="savePricePackage"
       />
    </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";
import IonlineApnPriceModal from '@/components/Admin/Modals/IonlineApnPriceModal';
import ModifyIonlineApnPackageModal from '@/components/Admin/Modals/ModifyIonlineApnPackageModal'

export default {
    extends: PriceFunctions,
    components: {
        IonlineApnPriceModal,
        ModifyIonlineApnPackageModal
    },
    props: {
        productNumber: {
            type: Number,
            required: true,
        },
        userEditRight: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            apnPackages: [],
            pricePackage: {},
            period: this.getCurrentPeriod(),
            rowPrice: [],
            newPrice: {},
        }
    },
    mounted() {
        this.getApnPackages();
    },
    methods: {
        getApnPackages: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}pricing/packages/product/${this.productNumber}/ApnPackage`).then(
                (response) => {
                    this.apnPackages = response.data;          
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching Apn Packages", error);
                }
            );
        },
        createNewPackage: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}pricing/new/package/ApnPackage`).then(
                (response) => {
                    this.pricePackage = response.data;
                    this.pricePackage.productNumber = this.productNumber;
                    this.$refs.modifyPackageModal.open();
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Fetching Package Template", error);
                }
            );
        },
        savePricePackage: function(pricePackage) {
            const hostUrl = this.$config.aimsAPI;            

            if (pricePackage.apnPackageNumber) {               
                this.$http.put(`${hostUrl}pricing/packages/update`, pricePackage).then(
                    (response) => {
                        this.$refs.modifyPackageModal.$refs.modifyPriceModal.close();
                        this.showSuccess("Successfully Edited Package");
                    },
                    (error) => {
                        this.showError("Error Saving Pricing", error);
                        console.error(error);
                    }
                ).finally(() => {
                    this.$refs.modifyPackageModal.$refs.modifyPriceModal.isLoading();
                });
            } else {                
                this.$http.post(`${hostUrl}pricing/packages/create`, pricePackage).then(
                    (response) => {
                        this.$refs.modifyPackageModal.$refs.modifyPriceModal.close();
                        this.showSuccess("Successfully Added Package");
                        this.openPriceModal(response.data);
                    },
                    (error) => {
                        this.showError("Error Saving Pricing", error);
                        console.error(error);
                    }
                ).finally(() => {
                    this.$refs.modifyPackageModal.$refs.modifyPriceModal.isLoading();
                });
            }
        },
        getApnPackagePrice: function(data, period, vat) {
            if (data) {
                if (data.length > 0) {
                let price = data.filter((price) => period >= price.validFrom);
                price.forEach((newPrice) => {
                    if (newPrice.validUntil != 0) {
                    price = price.filter((price) => period <= price.validUntil);
                    } else {
                    price = newPrice;
                    }
                });
                if (Array.isArray(price)) {
                    if (price.length > 0) {
                    if (vat) {
                        return this.timesVAT(
                        parseFloat(price[0].outOfBundlePriceExclVat)
                        );
                    } else {
                        return this.currencyFormat(
                        parseFloat(price[0].outOfBundlePriceExclVat)
                        );
                    }
                    } else {
                    return "R " + 0;
                    }
                } else {
                    if (vat) {
                    return this.timesVAT(
                        parseFloat(price.outOfBundlePriceExclVat)
                    );
                    } else {
                    return this.currencyFormat(
                        parseFloat(price.outOfBundlePriceExclVat)
                    );
                    }
                }
                } else {
                return "R " + 0;
                }
            } else {
                return 0;
            }
        },
        getApnPerSimPrice: function(data, period, vat) {
            if (data) {
                if (data.length > 0) {
                let price = data.filter((price) => period >= price.validFrom);
                price.forEach((newPrice) => {
                    if (newPrice.validUntil != 0) {
                        price = price.filter((price) => period <= price.validUntil);
                    } else {
                        price = newPrice;
                    }
                });
                if (Array.isArray(price)) {
                    if (price.length > 0) {
                        if (vat) {
                            return this.timesVAT(parseFloat(price[0].perSimPriceExclVat));
                        } else {
                            return this.currencyFormat(parseFloat(price[0].perSimPriceExclVat));
                        }
                    } else {
                        return "R " + 0;
                    }
                } else {
                    if (vat) {
                        return this.timesVAT(parseFloat(price.perSimPriceExclVat));
                    } else {
                        return this.currencyFormat(parseFloat(price.perSimPriceExclVat));
                    }
                }
                } else {
                    return "R " + 0;
                }
            } else {
                return 0;
            }
        },
        openPriceModal: function(apnPackage) {
            this.rowPrice = this.deepCopyObject(apnPackage.prices || []);
            this.newPrice.apnPackageNumber = apnPackage.apnPackageNumber;
            
            this.$refs.addPriceModal.open();
        },
        editPackage: function(apnPackage) {
            this.pricePackage = this.deepCopyObject(apnPackage);
            this.$refs.modifyPackageModal.open();
        },
        deletePackage: function(apnPackage) {
            const hostUrl = this.$config.aimsAPI; 

            this.$swal({
                title: "Are you sure you want to delete this Package?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    this.$http.delete(`${hostUrl}pricing/pricepackages/${apnPackage.objKey}?objClassName=${apnPackage.class}`).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                            this.getApnPackages();
                        },
                        (error) => {
                            this.showError("Error Deleting Price Package", error);
                        }
                    );
                }
            });
        },
        addPrice: function(newPrice) {
            this.rowPrice.forEach((price) => {
                if (price.validUntil == 0) {
                    price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
                }
            });
            newPrice.class = "za.co.adept.aims.protocol.gsm.ApnPackagePrice";
            this.rowPrice.push(newPrice);
            this.setNewPrice();
            this.newPrice.apnPackageNumber = newPrice.apnPackageNumber;
        },
        setNewPrice: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}pricing/new/price/ApnPackagePrice`).then(
                (response) => {
                    this.newPrice = response.data;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Fetching Price Template", error);
                }
            );
        },
    }
}
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}

.nav-pills .nav-link {
  background-color: #f3f2ee;
}

a {
  color: rgba(0, 0, 0, 0.6);
}

.price-edit {
    cursor: pointer;
}
</style>