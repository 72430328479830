<template>
  <div class="row row-spacing">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-8">
           <label>Search Contacts</label>
          <div class="input-group">  
            <input type="search" class="form-control col-md-6" placeholder="Search Person [Firstnames, Lastnames, Email, Mobile Number]..." v-model="searchTerm">
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2"><i class="fa fa-search"></i></span>
              </div>
          </div>
        </div>
        <div class="col-md-4" v-allowed:view="['ADMIN', 'CLIENT_ADMIN', 'STAFF']">
          <div class="form-group float-right">
            <button class="btn btn-primary" style="margin-top: 28px" @click="addPerson">Add Person</button>
          </div>
        </div>
      </div>
      <hr>
      <div class="row row-spacing mt-4">
        <div class="col-md-12">
          <div class="card table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile Number</th>
                  <th>Roles</th>
                  <th v-allowed:view="['ADMIN', 'CLIENT_ADMIN', 'STAFF']"></th>
                </tr>
              </thead>
              <tbody v-if="filteredPersonContacts.length > 0">
                <tr v-for="person in filteredPersonContacts" :key="person.user.personNumber" :class="{ 'highlight' : highlightTableRow(person) }">
                  <td>
                    <span class="mr-2">{{ person.user.firstNames + " " + person.user.lastName  }}</span>
                  </td>
                  <td>
                    <a :href="`mailto:${person.user.emailAddress}`" target="_blank"><span>{{ person.user.emailAddress }}</span></a>
                    <span 
                      class="pull-right"
                      @click.stop="copyText(person.user.emailAddress)"
                      v-tooltip="'Copy to clipboard'"
                    >
                      <i class="fa fa-clipboard text-info"></i>
                    </span>
                  </td>
                  <td>
                    <span class="blueLink" @click="openPhoneModal(person)">{{ person.user.telephonecell }}</span>
                    <span 
                      class="pull-right"
                      @click.stop="copyText(person.user.telephonecell)"
                      v-tooltip="'Copy to clipboard'"
                      v-if="person.user.telephonecell"
                    >
                      <i class="fa fa-clipboard text-info"></i>
                    </span>
                  </td>
                  <td>
                    <div>
                      <div>
                        <i class="badge badge-warning text-white badge-role" v-if="isPersonAdmin(person.rights)">
                          Admin 
                        </i>
                      </div>
                      <div>
                        <i class="badge badge-active text-white badge-role" v-if="person.user.allowPortalAccess">
                          Portal Access 
                        </i>
                      </div>
                      <div v-for="(role, index) in person.rights" :key="index">
                        <i class="badge badge-info badge-role" v-if="role.name != 'CLIENT_ADMIN'">
                          {{ role.description }} 
                        </i>
                      </div>
                    </div>
                  </td>
                  <td class="text-center" v-allowed:view="['ADMIN', 'CLIENT_ADMIN', 'STAFF']">
                    <div>
                      <button class="btn btn-success" v-tooltip="'Click to Edit'" @click="editPerson(person)"><i class="fa fa-pencil-square-o"></i></button>
                      <button class="btn btn-primary" v-tooltip="'Click to Delete'" @click="deletePerson(person)"><i class="fa fa-trash"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6">No Contacts available</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <AddPersonModal 
      ref="openAddPersonModal"
      :modalTitle="modalTitle"
      :personSelected="personSelected"
      :availablePersonRights="availablePersonRights"
      :assignedRights="assignedRights"
      @createPerson="createPerson"
      @assignRightToPerson="assignRightToPerson"
      @removeAssignedPersonRight="removeAssignedPersonRight"
      @reloadPersons="getPersons"
    />
    <PersonManagementModal 
      ref="openPersonManangementModal"
      :modalTitle="modalTitle"
      :personSelected="personSelected"
      :availablePersonRights="availablePersonRights"
      :assignedRights="assignedRights"
      @assignRightToPerson="assignRightToPerson"
      @removeAssignedPersonRight="removeAssignedPersonRight"
      @savePerson="savePerson"
      @reloadPersons="getPersons"
    />
    <modal
      :title="modalTitle"
      ref="phoneModal"
      :size="'modal-sm'"
      :footerNeeded="false"
    >
      <form>
        <div class="text-center">
          <a 
            v-tooltip="'Call'"
            type="button"
            class="btn btn-green phoneModalBtn"
            :href="`tel:${personSelected.user.telephonecell}`" target="_blank">
            <em class="fa-solid fa-phone"></em>
          </a>
          <button
            v-tooltip="'SMS'"
            type="button"
            @click="openSmsModal()"
            class="btn btn-info phoneModalBtn"
          >
            <em class="fa-solid fa-envelope"></em>
          </button>
        </div>
      </form>
    </modal>
    <modal
      :title="modalTitle"
      ref="smsModal"
      :size="'modal-md'"
      :saveText="'Send SMS'"
      :dismissNeeded="false"
      @save="sendSms()"
    >
      <div>
        <div class="row">
          <div class="form-group col-md-12">
          <label class="col-form-label">SMS:</label>
            <div class="input-group">
              <textarea :class="{'has-error': errors.has('message')}" name="message" v-validate="'required'"
                v-model="smsMessage.message" maxlength="159" class="form-control"
                rows="5" placeholder="Enter SMS Message"></textarea>
              <div class="word-counter text-muted">{{smsMessage.message.length}}/159</div>
            </div>
            <span class="has-error col-md-12" v-if="errors.has('message')">{{errors.first('message')}}</span>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from "@/components/GeneralComponents/Modal";
import AddPersonModal from '@/components/Admin/Modals/AddPersonModal';
import PersonManagementModal from '@/components/Admin/Modals/PersonManagementModal';

export default {
  components: {
    Modal,
    AddPersonModal,
    PersonManagementModal,
  },
  data() {
    return {
      clientNumber: null,
      persons: [],
      modalTitle: '',
      personSelected: {
        user: {},
        rights: [
          {
            description: '',
            name: '',
            options: []
          }
        ]
      },
      smsMessage: {
        recipient: '',
        message: ''
      },
      availablePersonRights: [],
      assignedRights: [],
      searchTerm: '',
      searchedPersonsList: [],
      showAdmin: false,
      highLightedPersonNumber: null,
    }
  },
  created() {
    if (this.$route.params.personNumber) {
      this.highLightedPersonNumber = this.$route.params.personNumber;
    }
    
  },
  mounted() {
    this.clientNumber = this.$route.params.clientNumber;  
    if (this.userContainsPermission(["CLIENT"])) {
        this.$root.$emit("SET_CLIENT_PAGE_TITLE", 'Contacts'); 
    }
    this.getPersons();
    /* this.autoGeneratePassword(); */
  },
  computed: {
    filteredPersonContacts() {
     this.searchedPersonsList = this.persons;

     this.searchedPersonsList = this.searchedPersonsList.filter((searchedPerson) => 
        searchedPerson.user.emailAddress.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        searchedPerson.user.firstNames.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        searchedPerson.user.lastName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        searchedPerson.user.telephonecell.toString().includes(this.searchTerm)
     );  

     return this.searchedPersonsList;
    },
  },
  methods: {
    isPersonAdmin(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].name == "CLIENT_ADMIN") {
          return true;
        }
      }
      return false;
    },
    getPersons: function() {
      const hostUrl = this.$config.aimsAPIv2;
      const clientNumber = this.clientNumber;

      this.$http.get(`${hostUrl}users/persons/client/${clientNumber}`).then(
        (response) => {          
          this.persons = response.data;  
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Client Users", error);
        }
      )
    },
    getPerson: function() {
      const hostUrl = this.$config.aimsAPIv2;
      const personNumber = this.personSelected.user.personNumber;

      this.$http.get(`${hostUrl}users/person/${personNumber}`).then(
        (response) => {
          this.personSelected = response.data;          
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Person", error);
        }
      )
    },
    addPerson: function() {
      this.$refs.openAddPersonModal.open();      
      this.modalTitle = "Add Person";
      this.assignedRights = [];
      
      this.personSelected = {
        class: 'adept.aims.classes.contact.Person',
        user: {},
        rights: []
      };      
    },
    createPerson: function(person) {
      const hostUrl = this.$config.aimsAPIv2;
      const clientNumber = this.clientNumber;

       this.$http.post(`${hostUrl}users/person/client/${clientNumber}`, person).then(
        (response) => {
          this.personSelected = response.data;
          const personNumber = this.personSelected.user.personNumber;          
          this.getAvailableRightsForPerson(personNumber);
          this.getPersons();
        },
        (error) => {
          console.error(error);
          this.showError("Error creating Person", error);
        }
      )
    },
    getAvailableRightsForPerson: function(personNumber) {      
      const hostUrl = this.$config.aimsAPIv2;

      this.$http.get(`${hostUrl}users/person/${personNumber}/rights`).then(
        (response) => {
            this.availablePersonRights = response.data;        
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching available person rights", error);
        }
      )
    },
    assignRightToPerson: function(rightName) {      
      const hostUrl = this.$config.aimsAPIv2;
      const personNumber = this.personSelected.user.personNumber;
      const rightEnumName = rightName.name;
      
      this.$http.post(`${hostUrl}users/person/${personNumber}/rights/${rightEnumName}`).then(
        (response) => {
          this.personSelected = response.data;
          this.assignedRights = this.personSelected.rights;
          this.getAvailableRightsForPerson(personNumber);          
          this.showSuccess(`${rightName.description} - right added`);
          this.getPersons();
        },
        (error) => {
          console.error(error);
          this.showError("Error assigning right to person", error);
        }
      )
    },
    removeAssignedPersonRight: function(rightName) {
      const hostUrl = this.$config.aimsAPIv2;
      const personNumber = this.personSelected.user.personNumber;
      const rightEnumName = rightName.name;
      
      this.$http.delete(`${hostUrl}users/person/${personNumber}/rights/${rightEnumName}`).then(
        (response) => {
          this.personSelected = response.data;
          this.assignedRights = this.personSelected.rights;
          this.getAvailableRightsForPerson(personNumber);
          this.showSuccess(`${rightName.description} - right removed`);
          this.getPersons();
        },
        (error) => {
          console.error(error);
          this.showError("Error removing person right", error);
        }
      )
    },
    editPerson: function(person) {
        this.personSelected = this.deepCopyObject(person);
        // this.highlightTableRow(person);
        const personNumber = this.personSelected.user.personNumber;
        this.getAvailableRightsForPerson(personNumber);    
        this.$refs.openPersonManangementModal.open();
        this.modalTitle = `Edit ${this.personSelected.user.firstNames} ${this.personSelected.user.lastName}`;
        this.highLightedPersonNumber = null;
    },
    savePerson: function(person) {
      const personSaved = person.user;     
      const hostUrl = this.$config.aimsAPIv2;
      this.$http.put(`${hostUrl}users/person`, personSaved).then(
        () => {
          this.showSuccess("Updated person");
          this.getPersons();
        },
        (error) => {
          console.error(error);
          this.showError("Error saving person", error);
        }
      )
    },
    deletePerson: function(person) {  
      this.$swal({
            title: "Are you sure you want to delete this Person?",
            type: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                  const hostUrl = this.$config.aimsAPIv2;
                  const personDeleted = person.user.personNumber;           
                    this.$http.delete(`${hostUrl}users/person/${personDeleted}`).then(
                    () => {
                        this.showSuccess("Successfully deleted person");
                        this.getPersons();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error deleting person", error);
                    });
                }
            });     
    },
    highlightTableRow(person) {       
      if (this.highLightedPersonNumber === person.user.personNumber) {
        this.scrollToPerson();
        return true;
      } 
    },
    scrollToPerson: function() {      
      const element = document.querySelector(".highlight"); 

      if (element !== null) {
        element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }
    },
    openPhoneModal(contact) {
      this.personSelected = contact;
      this.$refs.phoneModal.open();
      this.modalTitle = `Contact ${contact.user.firstNames} ${contact.user.lastName}`;
    },
    openSmsModal() {
      this.$refs.phoneModal.close();
      this.$refs.smsModal.open();
    },
    sendSms: function() {
      const hostUrl = this.$config.aimsAPI;
      this.smsMessage.recipient = this.personSelected.user.telephonecell;
      this.$http.put(`${hostUrl}notifications/client/contact`, this.smsMessage).then(
        (response) => {
          this.$refs.smsModal.close();
          this.showSuccess(response.data.message);
        },
      (error) => {
          this.showError("Could not send SMS Message", error);
          console.error(error);
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.highlight {
  background-color: #58b0c26c;

  &:hover {
    background-color: #58b0c26c;
  }

  td > * {
    color: #474747 !important;
    font-weight: 600;
    outline: none;
    text-decoration: none;
  }

}

.table>thead>tr>th {
  padding: 1em;
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.pull-right {
  float: right;
}

.badge {
  float: left;
  margin: 1px;
}

.badge-role {
  font-weight: 600;
  letter-spacing: .9px;
}

.phoneModalBtn {
  margin-left: 1em;
  margin-right: 1em;
}

.blueLink {
  color: #629EEB;
}

.blueLink:hover {
  cursor: pointer;
  color: #307fe6;
  text-decoration: underline;
}

.word-counter {
  position: absolute;
  right: 18;
  bottom: 0;
  z-index: 1000;
}
</style>
