var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{ref:"modifyPriceModal",attrs:{"title":this.pricePackage.solarwindsBackupPackageNumber ?  'Edit Package' : 'Add New Package'},on:{"save":function($event){return _vm.savePricePackage()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-10"},[_c('label',{staticClass:"control-label"},[_vm._v(" Description ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pricePackage.description),expression:"pricePackage.description"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.pricePackage.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.pricePackage, "description", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[(_vm.pricePackage.solarwindsBackupPackageNumber)?_c('div',{staticClass:"form-group col-md-10"},[_c('label',{staticClass:"control-label"},[_vm._v(" Expired ")]),_c('v-select',{attrs:{"options":['true', 'false']},model:{value:(_vm.expired),callback:function ($$v) {_vm.expired=$$v},expression:"expired"}})],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-10"},[_c('label',{staticClass:"control-label"},[_vm._v(" Licence Type ")]),_c('v-select',{attrs:{"options":[
          { name: 'SERVER', description: 'Server' },
          { name: 'DESKTOP', description: 'Desktop' },
        ],"valueProp":"name","label":"description"},model:{value:(_vm.pricePackage.licenceType),callback:function ($$v) {_vm.$set(_vm.pricePackage, "licenceType", $$v)},expression:"pricePackage.licenceType"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-10"},[_c('label',{staticClass:"control-label"},[_vm._v(" Management Type ")]),_c('v-select',{attrs:{"options":[
          { name: 'ADEPT', description: 'Adept-Managed' },
          { name: 'SELF', description: 'Self-Managed' },
        ],"valueProp":"name","label":"description"},model:{value:(_vm.pricePackage.managementType),callback:function ($$v) {_vm.$set(_vm.pricePackage, "managementType", $$v)},expression:"pricePackage.managementType"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-10"},[_c('label',{staticClass:"control-label"},[_vm._v(" Included Storage ")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.storage),expression:"storage"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.storage)},on:{"input":function($event){if($event.target.composing)return;_vm.storage=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("GB")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }