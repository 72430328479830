<template>
    <div class="d-flex justify-content-center align-items-center h-100">
        <div class="card">
            <div class="col-md-12">
                <div class="row">
                    <div class="card-left">
                            <div class="card-body">
                                <h2 class="card-title text-white">
                                    {{ loginHeading }}
                                </h2>
                                <div class="login-form pt-2">
                                    <div class="error-container" v-if="showErrorMessage">
                                        <p v-if="loginErrorMessage"><i class="fa fa-exclamation-triangle"></i> {{ loginErrorMessage }}</p>
                                        <p v-if="sendingMailErrorMessage"><i class="fa fa-exclamation-triangle"></i> {{ sendingMailErrorMessage }}</p>
                                        <p v-if="changePasswordErrorMessage"><i class="fa fa-exclamation-triangle"></i> {{ changePasswordErrorMessage }}</p>
                                    </div>
                                    <slot></slot>
                                </div>
                                <div class="card-buttons d-flex justify-content-between">
                                    <button type="button" class="btn btn-primary p-2" :class="showSwitchUser ? 'w-50' : 'w-100'" @click="submitEvent()" @keyup.enter="submitEvent()" :disabled="logUserIn || sendingMail">{{ primaryButtonText }}</button>
                                    <button v-if="showSwitchUser" type="button" class="btn btn-info w-50 p-2" @click="switchUser()">Switch User</button>
                                </div>
                                <div class="forgot-password-link mt-3 float-right" v-if="showForgotPassword">
                                    <router-link target="_blank" class="text-white" :to="{ name: 'forgotpassword' }">Forgot Password</router-link>
                                </div>
                            </div>      
                            <div class="card-body mt-2" v-if="isLoginRoute">
                                <hr/>      
                                <div class="card-buttons d-flex justify-content-between mt-4">
                                    <button type="button" class="btn btn-provider w-50 p-2" @click="loginProvider('microsoft')">
                                        <img
                                            src="/img/provider-logins/microsoft.png"
                                            class="provider-icon"
                                        />
                                        <span class="providerLabel">Sign in with Microsoft</span>
                                    </button>
                                    <button type="button" class="btn btn-provider w-50 p-2" @click="loginProvider('google')">
                                        <img
                                            src="/img/provider-logins/google.png"
                                            class="provider-icon"
                                        />
                                        <span class="providerLabel">Sign in with Google</span>
                                    </button>
                                </div>
                            </div>          
                    </div>
                    <div class="card-right">
                        <div class="card-body">
                            <div class="otp-container d-flex flex-column justify-content-center align-items-center" v-if="customImage" >
                                <div class="top-image">
                                    <img src="/servicepanel-themes/adept.servicepanel.co.za/logo.png" alt="" srcset="">
                                </div>
                                <div class="otp-image"> 
                                    <img :src="'data:image/png;base64,'+customImage" alt="" srcset="">
                                </div>
                            </div>
                            <div class="image-container d-flex flex-column justify-content-center align-items-center" v-else> 
                                <div class="top-image">
                                    <img src="/servicepanel-themes/adept.servicepanel.co.za/logo.png" alt="" srcset="">
                                </div>
                                <div class="bottom-image">
                                    <img src="/img/Service-Panel-Login-Image.png" alt="" srcset="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            loginHeading: {
                type: String,
                required: true,
            },
            primaryButtonText: {
                type: String,
                required: true,
            },
            loginErrorMessage: {
                type: String,
                required: false
            },
            sendingMailErrorMessage: {
                type: String,
                required: false
            },
            changePasswordErrorMessage: {
                type: String,
                required: false
            },
            showErrorMessage: {
                type: Boolean,
                required: false
            },
            showSwitchUser: {
                type: Boolean,
                required: false
            },
            logUserIn: {
                type: Boolean,
                required: false
            },
            customImage: {
                type: String,
                required: false
            },
            showForgotPassword: {
                type: Boolean,
                default: false
            },
            sendingMail: {
                type: Boolean,
                required: false
            },
        },
        computed: {
            isLoginRoute() {
                return this.$route.path === '/login';
            }
        },
        methods: {
            submitEvent: function() {
                this.$emit('submit');
            },
            switchUser: function() {
                this.$emit('switchUser');
            },
            navigateToForgotPassword: function() {
                this.$emit('navigateToForgotPassword')
            },
            loginProvider: function(provider) {
                this.$localStorage.set("prov", provider);
                this.$emit('loginProvider', provider)
            }
        },
    }
</script>

<style lang="scss" scoped>
    .card-left {
        background-color: #3a3f51;
        border-radius: 5px 0px 0px 5px;
        padding: 2em 1em 2em 1em;
        width: 480px;
        min-height: 450px;
        max-height: 550px;

        .card-body {
            .login-form {
                .error-container {
                    background-color: #e2231a;
                    color: #ffffff;
                    padding: .8em;
                    border-radius: 5px;

                    p {
                        margin: 0;
                    }
                }
            }
            .forgot-password-link {
                a {
                    &:hover {
                        color: #23b7e5 !important;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .card-right {
        width: 400px;
        min-height: 450px;
        max-height: 550px;
        .card-body {
            .image-container {
                margin: 2em 0 auto;              
                transform: translate(0, 50%);
                
            }
            .otp-container {
                margin: 2em 0 auto;          
            }
        }
    }

    .top-image {
        width: 35%;
        margin: 1em 0;
        img {
            width: 100%;
            display: block;
        }
    }

    .otp-image {
        width: 75%;
        margin: 1em 0;
        img {
            width: 100%;
            display: block;
        }
    }

    .bottom-image {
        width: 45%;
        margin: 1em 0;
        img {
            width: 100%;
            display: block;
        }
    }

    .btn-provider {
        display: flex;
        align-items: left;
        background-color: #f0f0f0;
    }

    .btn-provider:hover {
        background-color: #dadada;
    }
    
    .providerLabel {
        color: #3A3F51;
    }

    .fa-brands {
        color: #1f1d4d;
    }

    .provider-icon {
        max-width: 24px;
        max-height: 24px;
        margin-left: 12px; 
        margin-right: 12px;
    }

</style>