<template>
  <ClientHelpdeskTicketLayout :parts="parts">
    <template v-slot:tickets>
      <TicketList
        :ticketList="ticketList"
        :statuses="statuses"
        :createdDate="createdDate"
        @getAllTickets="getAllTickets"
      />
    </template>
    <template v-slot:no-reply-tickets>
      <NoReplyTicketList 
        :noReplyTickets="noReplyTickets"
        :statuses="statuses"
        @getAllTickets="getAllTickets"
      />
    </template>
  </ClientHelpdeskTicketLayout>
</template>

<script>
import TicketList from "@/components/ObjParts/MultipleLoaded/ClientHelpdeskTickets/TicketList";
import NoReplyTicketList from "@/components/ObjParts/MultipleLoaded/ClientHelpdeskTickets/NoReplyTicketList";
import ClientHelpdeskTicketLayout from "@/layouts/ClientHelpdeskTicketLayout"

export default {
  components: {
    TicketList,
    NoReplyTicketList,
    ClientHelpdeskTicketLayout,
  },
  data() {
    return {
      parts: [
        {
          name: "tickets",
          icon: "fa fa-ticket",
          displayName: "All Helpdesk Tickets",
          permissions: ["STAFF", "CLIENT"],
        },
        {
          name: "no-reply-tickets",
          icon: "fa fa-commenting-o",
          displayName: "Helpdesk Tickets with no reply",
          permissions: ["STAFF"],
        },
      ],
      ticketList: [],
      noReplyTickets: [],
      OldTickets: [],
      statuses: [],
      createdDate: '',
    };
  },
  mounted() {
      if (this.userContainsPermission(["CLIENT"])) {
          this.$root.$emit("SET_CLIENT_PAGE_TITLE", 'Helpdesk Tickets'); 
      }
      this.getAllTickets();
      this.getTicketsWithoutReply();
      this.getTicketStatuses();
  },
  methods: {
    getAllTickets: function(data) {
      let url = `${this.$config.aimsAPI}helpdesk/${this.$route.params.clientNumber}/tickets/list`;

      if (data) {
        url = `${this.$config.aimsAPI}helpdesk/${this.$route.params.clientNumber}/tickets/list?since=${data}`;
      }
      
      this.$http.get(url).then(
        (response) => {
          this.ticketList = response.data;
          this.createdDate = this.dateTimeFormat(this.ticketList.at(-1).created);
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching client helpdesk tickets without reply", error);
        }
      );
    },
    getTicketsWithoutReply: function() {
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.$route.params.clientNumber;

      this.$http.get(`${hostUrl}helpdesk/${clientNumber}/tickets/withoutreply`).then(
          (response) => {
            this.noReplyTickets = response.data;            
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching client helpdesk tickets without reply", error);
          }
        );
    },
    getTicketStatuses: function() {
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.$route.params.clientNumber;

      this.$http.get(`${hostUrl}helpdesk/${clientNumber}/tickets/statuses`).then(
        (response) => {
          this.statuses = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Client helpdesk ticket statuses", error);
        }
      );
    }
  },
};
</script>
