<template>
    <div>
        <div class="billing-information-wrapper my-3">
            <h4>Billing Information</h4>
            <hr />
            <div class="form-wrapper">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Client Type</label>
                        <div v-if="!isResellerClient">
                            <select
                                name="clientType"
                                v-model="clientDetail.type"
                                class="form-control"
                            >
                                <option
                                    :value="clientType.description"
                                    v-for="clientType in clientObjEnums.type"
                                    :key="clientType.name"
                                    :disabled="
                                        clientType.name != 'PRIVATE' &&
                                        clientType.name != 'CORPORATE' &&
                                        clientType.name != 'RESELLER_CLIENT'
                                    "
                                >
                                    {{ clientType.description }}
                                </option>
                            </select>
                        </div>
                        <div v-else class="d-flex align-items-center justify-content-between">
                            <label class="mb-0 border p-2 w-100 rounded bg-gray">Reseller</label>
                            <button type="button" class="btn btn-primary">Remove Reseller Status</button>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Payment Type:</label>
                        <v-select
                            name="paymentType"
                            :options="clientObjEnums.paymenttype"
                            v-model="clientDetail.paymenttype"
                            label="description"
                            valueProp="name"
                             :disabled="!isAuthorizedUser" 
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Terms:</label>
                        <v-select
                            name="paymentTerms"
                            :options="clientObjEnums.paymentTerms"
                            v-model="clientDetail.paymentTerms"
                            valueProp="name"
                            label="description"
                            :disabled="!isAuthorizedUser" 
                        >
                        </v-select>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Credit Rating:</label>
                        <v-select
                            name="creditRating"
                            :options="clientObjEnums.creditRating"
                            v-model="clientDetail.creditRating"
                            valueProp="name"
                            label="description"
                            :disabled="!isAuthorizedUser" 
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Maximum Debit:</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">R</span>
                            </div>
                            <input type="text" class="form-control" v-model="clientDetail.maxDebitAmount" :disabled="!isAuthorizedUser" >
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Preferred Debit Day:</label>
                        <input type="text" class="form-control" v-model="clientDetail.preferredDebitDay" :disabled="!isAuthorizedUser" >
                    </div>
                </div>
                <div v-if="isDebitPaymentType">
                    <h4>Banking Information</h4>
                    <hr />
                    <div class="form-row">
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('accounttype') }">
                            <label>Type of Account:</label>
                            <v-select
                                :options="clientObjEnums.accounttype"
                                v-model="clientDetail.accounttype"
                                label="description"
                                valueProp="name"
                                placeholder="Select Account Type"
                                name="accounttype"
                                data-vv-as="Account Type"
                                v-validate="'verify_selected'"
                                :disabled="!isAuthorizedUser" 
                            >
                            </v-select>
                            <small class="has-error mt-1" v-if="errors.has('accounttype')">{{ errors.first("accounttype") }}</small>
                        </div>
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('accountHolder') }">
                            <label>Account Holder</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="clientDetail.accountholder"
                                name="accountHolder"
                                data-vv-as="Account Holder"
                                v-validate="'required_if:paymenttype,Debited'"
                                :class="{ 'has-error': errors.has('accountHolder') }"
                                :disabled="!isAuthorizedUser" 
                            >
                            <small class="has-error mt-1" v-if="errors.has('accountHolder')">{{ errors.first("accountHolder") }}</small>
                        </div>
                    </div>
                    <div v-if="notCreditAccount">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label>Bank Account Number:</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="clientDetail.accountnumber"
                                    name="accountNumber"
                                    data-vv-as="Bank Account Number"
                                    v-validate="'required_if:paymenttype,Debited'"
                                    :class="{ 'has-error': errors.has('accountNumber') }"
                                    :disabled="!isAuthorizedUser"  
                                >
                                <small class="has-error mt-1" v-if="errors.has('accountnumber')">{{ errors.first("accountnumber") }}</small>
                            </div>
                            <div class="form-group col-md-6" :class="{ 'has-error': errors.has('bank') }">
                                <label>Bank Name:</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="clientDetail.bank"
                                    name="bank"
                                    data-vv-as="Bank Name"
                                    v-validate="'required_if:paymenttype,Debited'"
                                    :class="{ 'has-error': errors.has('bank') }"
                                    :disabled="!isAuthorizedUser" 
                                >
                                <small class="has-error mt-1" v-if="errors.has('bank')">{{ errors.first("bank") }}</small>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label>Branch:</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="clientDetail.branch"
                                    name="branch"
                                    data-vv-as="Branch"
                                    v-validate="'required_if:paymenttype,Debited'"
                                    :class="{ 'has-error': errors.has('branch') }"
                                    :disabled="!isAuthorizedUser" 
                                >
                                <small class="has-error mt-1" v-if="errors.has('branch')">{{ errors.first("branch") }}</small>
                            </div>
                            <div class="form-group col-md-6" :class="{ 'has-error': errors.has('branchCode') }">
                                <label>Branch Code:</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    v-model="clientDetail.branchcode"
                                    name="branchCode"
                                    data-vv-as="Branch Code"
                                    v-validate="'required_if:paymenttype,Debited|digits:6'"
                                    :class="{ 'has-error': errors.has('branchCode') }"
                                    :disabled="!isAuthorizedUser"  
                                >
                                <small class="has-error mt-1" v-if="errors.has('branchCode')">{{ errors.first("branchCode") }}</small>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label>Bank Account Number:</label>
                                <input type="text" class="form-control" v-model="clientDetail.accountnumber" :disabled="!isAuthorizedUser" >
                            </div>
                            <div class="form-group col-md-6">
                                <label>Card Type:</label>
                                <v-select
                                    name="cardType"
                                    :options="clientObjEnums.cardtype"
                                    v-model="clientDetail.cardtype"
                                    label="description"
                                    valueProp="name"
                                    placeholder="Select Card Type"
                                    :disabled="!isAuthorizedUser" 
                                >
                                </v-select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label>Expiry Date:</label>
                                <input type="text" class="form-control" v-model="clientDetail.expirydate" :disabled="!isAuthorizedUser" >
                            </div>
                            <div class="form-group col-md-6" :class="{ 'has-error': errors.has('cvv2') }">
                                <label>CVV2</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    name="cvv2" 
                                    v-model="clientDetail.cvv2" 
                                    :disabled="!isAuthorizedUser" 
                                    v-validate="'required_if:accounttype,Debited|numeric|min:3|max:3'"
                                    data-vv-as="CVV2"
                                    :class="{ 'has-error': errors.has('cvv2') }"
                                >
                                <small class="has-error mt-1" v-if="errors.has('cvv2')">{{ errors.first("cvv2") }}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isClientOfReseller">
                    <h4>Reseller Details</h4>
                    <hr />
                    <div class="form-row" v-if="hasResellerData">
                        <div class="form-group col-md-6">
                            <label>Reseller:</label>
                            <input type="text" class="form-control" :value="resellerCompanyName" disabled>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Reseller Reference:</label>
                            <input type="text" class="form-control" v-model="clientDetail.reseller.resellerReference" disabled>
                        </div>
                    </div>
                    <div class="form-row" v-else>
                        <div class="form-group col-md-6">
                            <label>Reseller:</label>
                            <v-select
                                :options="resellers"
                                v-model="clientDetail.resellerNumber"
                                label="companyname"
                                valueProp="resellerNumber"
                                placeholder="Select Reseller"
                                name="reseller"
                                v-validate="'verify_selected'"
                                :disabled="!isAuthorizedUser" 
                            >
                            </v-select>
                            <small class="has-error mt-1" v-if="errors.has('reseller')">{{ errors.first("reseller") }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="save-button-wrapper" v-allowed:view="['ADMIN', 'ACCOUNTS_STAFF', 'ADMIN_STAFF']">
            <button class="btn btn-info m-0" @click="updateClientDetails(clientDetail)">Save</button>
        </div>
    </div>
</template>

<script>
    export default {
         props: {
            userType: {
                type: String,
                required: true,
            },
            isAuthorizedUser: {
                type: Boolean,
                required: true,
            },
            clientDetail: {
                type: Object,
                required: true,
            },
            clientObjEnums: {
                type: Object,
                required: true,
            },
            resellers: {
                type: Array,
                required: true,
            }
        },
        computed: {
            isResellerClient() {
                return this.clientDetail.type === 'Reseller';
            },
            isClientOfReseller() {
                return this.clientDetail.type === 'Client of reseller'
            },
            isDebitPaymentType() {
                return this.clientDetail.paymenttype === 'Debited' || this.clientDetail.paymenttype === 'DEBIT';
            },
            notCreditAccount() {
                return this.clientDetail.accounttype !== 'CREDITCARD';
            },
            hasResellerData() {
                return this.clientDetail.reseller;
            },
            resellerCompanyName() {
                return `${this.clientDetail.reseller.companyname}  [${this.clientDetail.reseller.resellerNumber}]`
            }
        },
        methods: {
            updateClientDetails: function(clientDetail) {
                this.$emit('updateClientDetails', clientDetail)
            }
        },
    }
</script>

<style scoped>

</style>