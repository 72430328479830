<template>
<div class="card card-default">
    <div class="card-body">
        <div class="row">
            <div class="col-lg-12">
                <button class="btn btn-success" @click="addNewSetting()" type="button">
                    New Setting
                </button>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-responsive table-bordered">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Setting Name</th>
                                <th>Setting Description</th>
                                <th>Value</th>
                                <th>Version</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :class="{'new-table-row' : setting.isNew}" v-for="setting in systemSettings" :key="setting.settingNumber">
                                <td>
                                    {{setting.name}}
                                </td>
                                <td v-if="setting.edit">
                                    <input type="text" class="form-control" v-model="setting.description">
                                </td>
                                <td v-else>
                                    {{setting.description}}
                                </td>
                                <td v-if="setting.edit">
                                    <input type="text" class="form-control" v-model="setting.value">
                                </td>
                                <td v-else>
                                    {{setting.value}}
                                </td>
                                <td>
                                    {{setting.version}}
                                </td>
                                <td>
                                    {{setting.type}}
                                </td>
                                <td class="text-center">
                                    <button v-if="setting.edit" v-tooltip="'Save Setting'" @click="saveSetting(setting)" type="button" class="btn btn-sm btn-info m-1">
                                        <em class="fa fa-save fa-fw"></em>
                                    </button>
                                    <button v-else v-tooltip="'Edit Setting'" @click="getSystemSettings(setting)" type="button" class="btn btn-sm btn-info m-1">
                                        <em class="fa fa-pencil fa-fw"></em>
                                    </button>
                                    <button v-tooltip="'Delete Setting'" @click="deleteSetting(setting.settingNumber)" type="button" class="btn btn-sm btn-primary" v-allowed:view="['ADMIN']">
                                        <em class="fa fa-trash fa-fw"></em>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <modal title="Create New System Setting" ref="addNewSetting" @save="createSetting()">
        <div class="row">
            <div class="form-group col-md-10">
                <label class="control-label">
                    Setting Name
                </label>
                <input type="text" class="form-control" v-model="newSetting.name">
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-10">
                <label class="control-label">
                    Setting Description
                </label>
                <input type="text" class="form-control" v-model="newSetting.description">
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-10">
                <label class="control-label">
                    Value
                </label>
                <input type="text" class="form-control" v-model="newSetting.value">
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-10">
                <label class="control-label">
                    Type
                </label>
                <v-select :options="types" placeholder="Select Type" :hideProp="['__NONE__']" v-model="newSetting.type"></v-select>
            </div>
        </div>
    </modal>
</div>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';
export default {
    components: {
        Modal
    },
    data() {
        return {
            systemSettings: [],
            types: [],
            newSetting: {}
        }
    },
    created() {
        this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
    mounted() {
        this.getSystemSettings();

        this.$http.get(this.$config.aimsAPI + "system/setting/types").then(response => {
                this.types = response.data;
            },
            error => {
                this.showError("Error fetching System Setting Types", error);
                console.error(error);
            });
    },
    methods: {
        getSystemSettings(setting) {
            this.$http.get(this.$config.aimsAPI + "system/settings").then(response => {
                    if (setting) {
                        response.data.forEach(s => {
                            if (s.settingNumber == setting.settingNumber) {
                                s.edit = true;
                            }
                        });
                    }
                    this.systemSettings = response.data;
                },
                error => {
                    this.showError("Error fetching System Settings", error);
                    console.error(error);
                });
        },
        addNewSetting() {
            this.$http.get(this.$config.aimsAPI + "system/setting/new").then(response => {
                    this.newSetting = response.data;
                    this.newSetting.type = null;
                    this.$refs.addNewSetting.open();
                },
                error => {
                    this.showError("Error fetching New System Setting", error);
                    console.error(error);
                });
        },
        saveSetting(setting) {
            this.$http.post(this.$config.aimsAPI + "system/setting/save", setting).then(response => {
                    this.showSuccess(response.data.message);
                    this.getSystemSettings();
                },
                error => {
                    this.showError("Error saving System Setting", error);
                    console.error(error);
                });
        },
        createSetting() {
            this.$refs.addNewSetting.isLoading();
            this.$http.post(this.$config.aimsAPI + "system/setting/create", this.newSetting).then(response => {
                    response.data.isNew = true;
                    this.systemSettings.unshift(response.data);
                    this.$refs.addNewSetting.close();
                },
                error => {
                    this.showError("Error saving System Setting", error);
                    console.error(error);
                }).finally(() => {
                this.$refs.addNewSetting.isLoading();
            });
        },
        deleteSetting(objKey) {
            this.$swal({
                title: "Are you sure you want to delete System Setting?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then(result => {
                if (result.value) {
                    this.$http.delete(this.$config.aimsAPI + "system/setting/" + objKey).then(response => {                            
                            this.showSuccess(response.data.message);
                            this.getSystemSettings();
                        },
                        error => {
                            this.showError("Error deleting System Setting", error);
                            console.error(error);
                        });
                }
            });
        }
    },
}
</script>
