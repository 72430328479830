<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <button
          v-if="userEditRight"
          type="button"
          class="btn btn-info"
          @click="$refs.modifyPackageModal.open()"
        >
          Create New Package
        </button>
      </div>
    </div>
    <div class="row row-spacing mb-3">
      <div class="col-md-12">
        <div
          class="table-responsive table-bordered"
          v-if="solarwindsBackupPackages.length > 0"
        >
          <table class="table">
            <thead>
              <tr class="text-nowrap">
                <th>Description</th>
                <th>Licence Type</th>
                <th>Management Type</th>
                <th>Included Storage</th>
                <th>Overusage Price (Per GB)</th>
                <th>Price (excl. VAT)</th>
                <th>Incl. VAT</th>
                <th>Expired</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="{ 'bg-primary': solarwindsBackupPackage.expired }"
                v-for="solarwindsBackupPackage in solarwindsBackupPackages"
                :key="solarwindsBackupPackage.virtualServerPackageNumber"
              >
                <td>{{ solarwindsBackupPackage.description }}</td>
                <td class="text-nowrap">
                  {{ solarwindsBackupPackage.licenceType }}
                </td>
                <td>
                  {{ solarwindsBackupPackage.managementType }}
                </td>
                <td class="text-nowrap">
                  {{ sizeBytesToGb(solarwindsBackupPackage.includedStorage) }}
                  GB
                </td>
                <td class="text-nowrap">
                  {{
                    getOverUsagePrice(
                      solarwindsBackupPackage.solarwindsbackuppackageprices,
                      period
                    )
                  }}
                </td>
                <td>
                  {{
                    getPrice(
                      solarwindsBackupPackage.solarwindsbackuppackageprices,
                      period
                    )
                  }}
                  <span
                    v-if="userEditRight"
                    style="cursor: pointer"
                    @click="openPriceModal(solarwindsBackupPackage)"
                    v-tooltip="'Edit Row Price'"
                    class="pull-right"
                  >
                    <em class="fa fa-edit fa-fw text-green"></em>
                  </span>
                </td>
                <td class="text-nowrap">
                  {{
                    getPrice(
                      solarwindsBackupPackage.solarwindsbackuppackageprices,
                      period,
                      true
                    )
                  }}
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      solarwindsBackupPackage.expired
                        ? 'badge-warning'
                        : 'badge-green'
                    "
                    >{{ solarwindsBackupPackage.expired }}
                  </span>
                </td>
                <td class="text-center">
                  <button
                    v-if="userEditRight"
                    v-tooltip="'Edit Package'"
                    @click="editPackage(solarwindsBackupPackage)"
                    type="button"
                    class="btn btn-sm btn-info m-1"
                  >
                    <em class="fa fa-edit fa-fw"></em>
                  </button>
                  <button
                    v-if="userEditRight"
                    v-tooltip="'Delete Package'"
                    type="button"
                    @click="deletePackage(solarwindsBackupPackage)"
                    class="btn btn-sm btn-primary"
                  >
                    <em class="fa fa-trash fa-fw"></em>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="alert alert-info">
          No Solarwind Backup Packages Available
        </div>
      </div>
    </div>
    <ModifySolarwindsBackupPackageModal
      ref="modifyPackageModal"
      :pricePackage="pricePackage"
      @packageAdded="packageAdded"
      @packageEdited="loadSolarwindsBackupPackages()"
    />
    <AddPriceModal
      ref="addPriceModal"
      :row-price="rowPrice"
      :new-price="newPrice"
      @addPrice="addPrice"
      @pricesAdded="loadSolarwindsBackupPackages()"
    />
  </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";
import ModifySolarwindsBackupPackageModal from "@/components/Admin/Modals/ModifySolarwindsBackupPackageModal";
import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";

export default {
  extends: PriceFunctions,
  components: {
    ModifySolarwindsBackupPackageModal,
    AddPriceModal,
  },
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },

  },
  data() {
    return {
      solarwindsBackupPackages: [],
      period: this.getCurrentPeriod(),
      rowPrice: [],
      pricePackage: {},
      newPrice: {},
    };
  },
  mounted() {
    this.loadSolarwindsBackupPackages();
  },
  methods: {
    loadSolarwindsBackupPackages() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/solarwindbackuppackages/product/" +
            this.productNumber
        )
        .then(
          (response) => {
            this.pricePackage = response.data;
            this.setNewPrice();
            this.solarwindsBackupPackages =
              response.data.solarwindsbackuppackages;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Solarwinds Backup Packages", error);
          }
        );
    },
    setNewPrice() {
      this.newPrice = this.deepCopyObject(
        this.pricePackage.solarwindsbackuppackageprice
      );
    },
    openPriceModal(solarwindsBackupPackage) {
      this.rowPrice = this.deepCopyObject(
        solarwindsBackupPackage.solarwindsbackuppackageprices || []
      );
      this.newPrice.solarwindsBackupPackageNumber =
        solarwindsBackupPackage.solarwindsBackupPackageNumber;
      this.$refs.addPriceModal.open();
    },
    addPrice(newPrice) {
      this.rowPrice.forEach((price) => {
        if (price.validUntil == 0) {
          price.validUntil = this.decreaseBillingPeriod(newPrice.validFrom);
        }
      });
      this.rowPrice.push(newPrice);
      this.setNewPrice();
      this.newPrice.solarwindsBackupPackageNumber =
        newPrice.solarwindsBackupPackageNumber;
    },
    editPackage(solarwindsBackupPackage) {
      this.pricePackage = this.deepCopyObject(solarwindsBackupPackage);
      this.$refs.modifyPackageModal.open();
    },
     createNewPackage() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/new/package/SolarwindsBackupPackage"
        )
        .then(
          (response) => {
            this.pricePackage = response.data;
            this.pricePackage.productNumber = this.productNumber;
            this.$refs.modifyPackageModal.open();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Package Template", error);
          }
        );
    },
    packageAdded(solarwindBackupPackage) {
      solarwindBackupPackage.solarwindsbackuppackageprices = [];
      this.solarwindsBackupPackages.push(solarwindBackupPackage);
      this.openPriceModal(solarwindBackupPackage);
    },
    deletePackage(solarwindsBackupPackage) {
      this.$swal({
        title: "Are you sure you want to delete this Package?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "pricing/pricepackages/" +
                solarwindsBackupPackage.objKey +
                "?objClassName=" +
                solarwindsBackupPackage.class
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.loadSolarwindsBackupPackages();
              },
              (error) => {
                this.showError("Error Deleting Price Package", error);
              }
            );
        }
      });
    },
    getOverUsagePrice: function (data, period, vat) {
      if (data) {
        if (data.length > 0) {
          let price = data.filter((price) => period >= price.validFrom);
          price.forEach((newPrice) => {
            if (newPrice.validUntil != 0) {
              price = price.filter((price) => period <= price.validUntil);
            } else {
              price = newPrice;
            }
          });
          if (Array.isArray(price)) {
            if (price.length > 0) {
              if (vat) {
                return this.timesVAT(
                  parseFloat(price[0].overUsagePrice)
                );
              } else {
                return this.currencyFormat(
                  parseFloat(price[0].overUsagePrice)
                );
              }
            } else {
              return `R ${0}`;
            }
          } else {
            if (vat) {
              return this.timesVAT(
                parseFloat(price.overUsagePrice)
              );
            } else {
              return this.currencyFormat(
                parseFloat(price.overUsagePrice)
              );
            }
          }
        } else {
          return `R ${0}`;
        }
      } else {
        return 0;
      }
    },
  },
};
</script>
