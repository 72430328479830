<template>
  <div class="card-body">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a
          href="#apn"
          class="nav-link active"
          role="tab"
          data-toggle="tab"
          id="apn-tab"
          aria-controls="apn"
          aria-selected="true"
        >
          <i class="fa fa-wifi"></i>&nbsp;&nbsp;MTN APN
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#ipv4networks"
          class="nav-link"
          role="tab"
          data-toggle="tab"
          id="ipv4networks-tab"
          aria-controls="ipv4networks"
          aria-selected="true"
        >
          <i class="fa fa-plus"></i>&nbsp;&nbsp;Private IPv4 Ranges
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#gsmaccounts"
          class="nav-link"
          role="tab"
          data-toggle="tab"
          id="gsmaccounts-tab"
          aria-controls="gsmaccounts"
          aria-selected="true"
        >
          <i class="fa fa-microchip"></i>&nbsp;&nbsp;SIMs
        </a>
      </li>
    </ul>
    <form class="tab-content">
      <div
        class="tab-pane fade show active"
        id="apn"
        role="tabpanel"
        aria-labelledby="apn-tab"
      >
        <div class="card-body">
          <div class="form-group row row-spacing">
            <label class="col-md-2 col-form-label">APN Name:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.sale.apn.name"
                disabled
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label">Package:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.sale.apn.apnPackage.description"
                disabled
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label">Bundle Size:</label>
            <div class="col-md-4">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="saleDefinition.sale.apn.apnPackage.dataBundleSize"
                  disabled
                  aria-describedby="bundle-size-unit"
                />
                <span class="input-group-text" id="bundle-size-unit">GB</span>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label">Billed SIMs:</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.sale.apn.accounts.length"
                disabled
              />
            </div>
          </div>
          <div class="row" v-allowed:view="['STAFF']">
            <div class="col-md-12">
              <div class="form-group row-spacing">
                <button class="btn btn-success" @click="saveSale()" type="button">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tab-pane"
        id="ipv4networks"
        role="tabpanel"
        aria-labelledby="ipv4networks-tab"
      >
        <div class="col-md-12">
          <RoutedIpv4Network
            :ipv4Networks="saleDefinition.sale.apn.ipv4Networks"
            @addRouteModal="addRoutedNetwork"
            @editRouteModal="editRoutedNetwork"
            @routeAdded="routeAdded"
            @routeRemoved="routeRemoved"
          />
        </div>
      </div>
      <modal
        :title="modalTitle"
        ref="addRoutedNetwork"
        @save="saveRoutedNetwork(routedNetwork)"
      >
        <div class="row">
          <div class="form-group col-md-10">
            <label class="control-label">IPv4 Range:</label>
            <div class="input-group">
              <input class="form-control" v-model="routedNetwork.address" />
              <div class="input-group-append">
                <select class="form-control" v-model="routedNetwork.mask">
                  <option value="12">/12</option>
                  <option value="13">/13</option>
                  <option value="14">/14</option>
                  <option value="15">/15</option>
                  <option value="16">/16</option>
                  <option value="17">/17</option>
                  <option value="18">/18</option>
                  <option value="19">/19</option>
                  <option value="20">/20</option>
                  <option value="21">/21</option>
                  <option value="22">/22</option>
                  <option value="23">/23</option>
                  <option value="24">/24</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </modal>

      <div
        class="tab-pane"
        id="gsmaccounts"
        role="tabpanel"
        aria-labelledby="gsmaccounts-tab"
      >
        <div class="col-md-12" style="z-index:1000;">
          <GsmAccountSims
            :gsmAccounts="saleDefinition.sale.apn.accounts"
            @viewSims="viewGsmAccount"
            @addNewSim="addNewSimModal"
            @editSimDesc="editSimDescModal"
            @editSimCED="editSimCEDModal"
            @editSimStatus="editSimStatusModal"
            @deleteSim="removeSim"
          />
        </div>
      </div>
      <modal
        :title="modalTitle"
        ref="addNewSimModal"
        @save="addNewSim(gsmAccount)"
      >
        <div class="row">
          <div class="form-group col-md-10">
            <label class="control-label mt-2">MSISDN:</label>
            <div class="input-group">
              <input class="form-control" v-model="gsmAccount.username" />
            </div>
            <label class="control-label mt-2">Serial Number:</label>
            <div class="input-group">
              <input
                class="form-control"
                v-model="gsmAccount.gsmSerialNumber"
              />
            </div>
          </div>
        </div>
      </modal>

      <modal
        title="Edit SIM Details"
        ref="editSimDescModal"
        size="modal-md"
        @save="saveSim(simEdit)"
      >
        <div class="row">
          <div class="form-group col-md-10">
            <label class="control-label mt-2">MSISDN:</label>
            <div class="input-group">
              <input :disabled="!userEditRight" class="form-control" v-model="simEdit.username"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-10">
            <label class="control-label mt-2">Serial Number:</label>
            <div class="input-group">
              <input  :disabled="!userEditRight" class="form-control" v-model="simEdit.gsmSerialNumber"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-10">
            <label class="control-label mt-2">Description:</label>
            <div class="input-group">
              <input class="form-control" v-model="simEdit.description" />
            </div>
          </div>
        </div>
      </modal>

      <modal
        title="Edit SIM Contract End Date"
        ref="editSimCEDModal"
        size="modal-md"
        @save="saveSim(simEdit)"
      >
        <div class="row">
          <div class="form-group col-md-10">
            <label class="control-label mt-2">Contract End Date:</label>
            <div class="input-group">
              <input type="date" class="form-control" :min="simEdit.firstConnected" v-model="simEdit.contractEndDate" />
            </div>
          </div>
        </div>
      </modal>

      <modal
        title="Edit SIM Status"
        ref="editSimStatusModal"
        size="modal-sm"
        @save="saveSim(simEdit)"
        :footer-needed="false"
      >
        <div class="row">
          <div class="form-group col-md-12" style="text-align:center" v-if="simEdit.simStatus == 'Active'" v-allowed:view="['TECHNICAL_STAFF', 'TECHNICAL', 'ADMIN']">
            <button
              type="button"
              @click="changeSimStatus(simEdit, 'Suspended')"
              class="btn btn-sm btn-warning"
            >
              Suspend
            </button>
          </div>
          <div class="form-group col-md-12" style="text-align:center" v-if="simEdit.simStatus == 'Suspended'" v-allowed:view="['TECHNICAL_STAFF', 'TECHNICAL', 'ADMIN']">
            <button
              type="button"
              @click="changeSimStatus(simEdit, 'Active')"
              class="btn btn-sm btn-green"
            >
              Unsuspend
            </button>
          </div>
          <div class="form-group col-md-12" style="text-align:center" v-if="simEdit.simStatus != 'Inactive'" v-allowed:view="['TECHNICAL_STAFF', 'SENIOR_TECHNICAL_STAFF', 'ADMIN']">
            <button
              type="button"
              @click="changeSimStatus(simEdit, 'Inactive')"
              class="btn btn-sm btn-red text-light"
            >
              Deactivate
            </button>
          </div>
          <div class="form-group col-md-12" style="text-align:center" v-if="simEdit.simStatus == 'Inactive'" v-allowed:view="['TECHNICAL_STAFF', 'SENIOR_TECHNICAL_STAFF', 'ADMIN']">
            <button
              type="button"
              @click="changeSimStatus(simEdit, 'Active')"
              class="btn btn-sm btn-green"
            >
              Activate
            </button>
          </div>
        </div>
      </modal>

      <modal
        :title="'GSM Account: ' + selectedAccount.username"
        ref="viewSimModal"
        size="modal-md"
        :save-needed="false"
        dismiss-text="Close"
      >
        <div class="cardbody">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                href="#info"
                class="nav-link active"
                role="tab"
                data-toggle="tab"
                id="info-tab"
                aria-controls="info"
                aria-selected="true"
              >
                <i class="fa fa-info"></i>&nbsp;&nbsp;Info
              </a>
            </li>
            <!-- this might be needed for calmex, but we don't display any data yet.
              <li class="nav-item">
              <a
                href="#allocateddata"
                class="nav-link"
                role="tab"
                data-toggle="tab"
                id="allocateddata-tab"
                aria-controls="allocateddata"
                ><i class="fa fa-database"></i>&nbsp;&nbsp;Allocated Data</a
              >
            </li> -->
          </ul>
          <form class="tab-content">
            <div
              class="tab-pane fade show active"
              id="info"
              role="tabpanel"
              aria-labelledby="info-tab"
            >
              <div class="row row-spacing">
                <div class="col-md-12">
                  <div class="row align-items-center ml-2">
                    <label class="m-0 col-md-4">Status</label>
                    <input
                      type="text"
                      class="col-md-8 form-control-plaintext"
                      disabled
                      v-model="selectedAccount.simStatus"
                    />
                  </div>
                </div>
              </div>
              <div class="row row-spacing">
                <div class="col-md-12">
                  <div class="row align-items-center ml-2">
                    <label class="m-0 col-md-4">MSISDN</label>
                    <input
                      type="text"
                      class="col-md-8 form-control-plaintext"
                      disabled
                      v-model="selectedAccount.username"
                    />
                  </div>
                </div>
              </div>
              <div class="row row-spacing">
                <div class="col-md-12">
                  <div class="row align-items-center ml-2">
                    <label class="m-0 col-md-4">First Connected</label>
                    <input
                      type="text"
                      class="col-md-8 form-control-plaintext"
                      disabled
                      :value="dateTimeFormat(selectedAccount.firstConnected)"
                    />
                  </div>
                </div>
              </div>
              <div class="row row-spacing">
                <div class="col-md-12">
                  <div class="row align-items-center ml-2">
                    <label class="m-0 col-md-4">Contract End Date</label>
                    <input
                      type="text"
                      class="col-md-8 form-control-plaintext"
                      disabled
                      :value="dateTimeFormat(selectedAccount.contractEndDate)"
                    />
                  </div>
                </div>
              </div>


            </div>
            <!-- this might be needed for calmex, but we don't display any data yet.
              <div
              class="tab-pane"
              id="allocateddata"
              role="tabpanel"
              aria-labelledby="allocateddata-tab"
            >
              <div class="row">
                <div class="col-md-12">
                  <AllocatedData
                    :heading="'Allocated Data'"
                    :dataAlloc="getDataAllocations"
                  />
                </div>
              </div>
            </div> -->
          </form>
        </div>
      </modal>
    </form>
  </div>
</template>

<script>
import SaleFunctions from "@/pages/Services/SaleFunctions";
import Modal from "@/components/GeneralComponents/Modal";
import RoutedIpv4Network from "@/components/ObjParts/MultipleCaptured/RoutedIpv4Network";
import GsmAccountSims from "@/components/ObjParts/MultipleCaptured/GsmAccountSims";

export default {
  extends: SaleFunctions,
  components: {
    Modal,
    RoutedIpv4Network,
    GsmAccountSims,
  },
  watch: {
    /* saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if(oldSaleDef.sale.description != newSaleDef.sale.description) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      }
    } */
  },
  data() {
    return {
      saleDefinition: {
        sale: {
          apn: {
            apnPackage: {},
            accounts: [],
            ipv4Networks: [],
          },
        },
      },
      selectedAccount: {
        gsmAccountNumber: 0,
        gsmSerialNumber: 0,
        dataAllocations: [],
      },
      selectableOptions: {
        apnPackage: [],
      },
      saleStartPeriod: "",
      availableData: [],
      routedNetwork: {},
      gsmAccount: {},
      simEdit: {},
      modalTitle: "",
      allocatedLoaded: true,
    };
  },
  computed: {
    getDataAllocations() {
      let dataAllocations = this.selectedAccount.dataAllocations;
      return dataAllocations;
    },
    userEditRight() {
        return this.userContainsPermission(['TECHNICAL_STAFF', 'MANAGER']);
    }   
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.saleStartPeriod = this.dateToPeriod(
      this.saleDefinition.sale.startDate
    );
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.fetchSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
        },
        (error) => {
          this.showError("Error fetching Sale Definition", error);
          console.error(error);
        }
      );
    },
    viewGsmAccount(account) {
      this.selectedAccount = account;
      this.allocatedLoaded = false;
      this.allocatedLoaded = true;
      //how to call event on component
      // this.translateCurrentDailyUsageData(account.activeAllocations);

      this.$refs.viewSimModal.open();
    },
    getStatusBadge: function(status) {
        let badge = "badge bg-green text-white";

        if (status == "Inactive") {
            badge = "badge bg-warning text-white";
        };
        if (status == "Suspended") {
            badge = "badge bg-danger text-white";
        };
        if (status == "None(Select one)") {
            badge = "badge bg-primary text-white";
        };

        return badge;
    },
    saveSale() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving APN Sale", error);
              }
            );
        }
      });
    },
    routeAdded(route) {
      if (route.family === "IPV4") {
        route.class = "za.co.adept.aims.protocol.gsm.ApnIpv4Range";
      } else {
        this.showError("Could not find family " + route.family, {});
        return;
      }
      this.createObjPart(route, true).then(
        (response) => {
          this.showSuccess("IPv4 Range Created Successfully");
          this.loadSaleDefinition();
        },
        (error) => {
          console.error(error);
          this.showError("Error Creating Range", error);
        }
      );
    },
    routeRemoved(route) {
      this.deleteObjPart(route).then(
        (response) => {
          if (route.family === "IPV4") {
            const index = this.saleDefinition.sale.apn.ipv4Networks.findIndex(
              (r) => r.objKey === route.objKey
            );
            this.saleDefinition.sale.apn.ipv4Networks.splice(index, 1);
          }
          this.showSuccess("Range Removed Successfully");
           this.loadSaleDefinition();
        },
        (error) => {
          console.error(error);
          this.showError("Error Removing Range", error);
        }
      );
    },
    addRoutedNetwork() {
      this.modalTitle = "Add IPv4 Range";
      this.routedNetwork = {
        class: "za.co.adept.aims.protocol.gsm.ApnIpv4Range",
        apnNumber: this.saleDefinition.sale.apn.apnNumber,
        type: "TOP_LEVEL_ALLOCATION",
      };
      this.$refs.addRoutedNetwork.open();
    },
    savePart(obj, name) {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/update/",
          obj
        )
        .then(
          (success) => {
            this.showSuccess("Saved " + name);
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving " + name, error);
          }
        );
    },
    editRoutedNetwork(obj) {
      this.modalTitle = "Edit Routed Network";
      this.routedNetwork = obj;
      this.$refs.addRoutedNetwork.open();
    },
    saveRoutedNetwork(obj) {
      this.$refs.addRoutedNetwork.isLoading();
      if (obj.objKey) {
        this.$http
          .put(
            this.$config.aimsAPI +
              "sales/definition/" +
              this.saleDefinition.sale.saleNumber +
              "/obj/update",
            obj
          )
          .then(
            (success) => {
              this.showSuccess("Saved Private APN Routed IPv4 Network");
              this.$refs.addRoutedNetwork.close();
              this.loadSaleDefinition();
            },
            (error) => {
              console.error(error);
              this.showError("Error Saving " + name, error);
            }
          )
          .finally(() => {
            this.$refs.addRoutedNetwork.isLoading();
          });
      } else {
        this.$http
          .post(
            this.$config.aimsAPI +
              "sales/definition/" +
              this.saleDefinition.sale.saleNumber +
              "/obj/create",
            obj
          )
          .then(
            (success) => {
              this.showSuccess("Created Private APN Routed IPv4 Network");
              this.$refs.addRoutedNetwork.close();
              this.loadSaleDefinition();
            },
            (error) => {
              console.error(error);
              this.showError("Error Saving " + name, error);
            }
          )
          .finally(() => {
            this.$refs.addRoutedNetwork.isLoading();
          });
      }
      this.$refs.addRoutedNetwork.open();
    },
    addNewSimModal() {
      this.modalTitle = "Add New SIM";
      this.gsmAccount = {
        class: this.saleDefinition.meta.apn.children.accounts.partMeta.dataType,
        apnNumber: this.saleDefinition.sale.apn.apnNumber,
        saleNumber: this.saleDefinition.sale.apn.saleNumber
      };
      this.$refs.addNewSimModal.open();
    },
    addNewSim(obj) {
      this.$refs.addNewSimModal.isLoading();
      //i found some problem using v1 here. error
      this.$http
        .post(
          this.$config.aimsAPIv2 +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/create?commit=true",
          obj
        )
        .then(
          (success) => {
            this.showSuccess("New SIM successfully created");
            this.$refs.addNewSimModal.close();
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving " + name, error);
          }
        )
        .finally(() => {
          this.$refs.addNewSimModal.isLoading();
        });
    },
    editSimDescModal(gsmAccount) {
      this.simEdit = gsmAccount;
      this.$refs.editSimDescModal.open();
    },
    editSimCEDModal(gsmAccount) {
      this.simEdit = gsmAccount;
      this.$refs.editSimCEDModal.open();
    },
    editSimStatusModal(gsmAccount) {
      this.simEdit = gsmAccount;
      this.$refs.editSimStatusModal.open();
    },
    changeSimStatus(account, status){
      this.$refs.editSimStatusModal.isLoading();
      account.simStatus = status;
      if (status == 'Inactive') {
        //account.deactivationDate = new Date().toISOString().split("T")[0];
      }
      this.saveSim(account);
      this.$refs.editSimStatusModal.close();
    },
    saveSim(obj) {
      this.$refs.editSimDescModal.isLoading();
      this.$refs.editSimCEDModal.isLoading();
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/update",
          obj
        )
        .then(
          (success) => {
            this.showSuccess("SIM Updated");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Updating " + name, error);
          }
        )
        .finally(() => {
          this.$refs.editSimDescModal.close();
          this.$refs.editSimCEDModal.close();
        });
    },

    removeSim(account) {
      this.$http
        .post(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/delete/",
          account
        )
        .then(
          (response) => {
            this.showSuccess("SIM successfully removed");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Removing SIM", error);
          }
        );
    },
  },
};
</script>