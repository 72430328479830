<template>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="apn.name" disabled>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Package</label>
                    <input type="text" class="form-control" v-model="apn.apnPackage.description" disabled>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Bundle size</label>
                    <div class="input-group">
                    <input
                        type="text"
                        class="form-control"
                        v-model="apn.apnPackage.dataBundleSize"
                        disabled
                        aria-describedby="bundle-size-unit"
                        />
                        <span class="input-group-text" id="bundle-size-unit">GB</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Max number of SIMs</label>
                    <input type="text" class="form-control" v-model="apn.apnPackage.maxNumberOfSims" disabled>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        apn: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>

</style>