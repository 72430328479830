<template>
  <div>
    <service-screen-layout :parts="parts">
      <template v-slot:details>
        <VirtualServerDetails 
          :virtualServer="saleDefinition.definition.virtualServer"
          @saveSale="saveSale"
        />
      </template>
      <template v-slot:packagemanagement>
        <VirtualServerPackage 
          :virtualServerPackageNumber="saleDefinition.definition.virtualServer.virtualServerPackageNumber"
          :virtualServerPackage="saleDefinition.definition.virtualServer.virtualServerPackage"
          :packages="packages"
          @saveSale="saveSale"
          @packageChange="packageChange"
        />
      </template>
      <template v-slot:addons>
        <VirtualServerAddOns 
          :virtualServerAddons="saleDefinition.definition.virtualServer.virtualAddons"
          @saveSale="saveSale"
        />
      </template>
      <template v-slot:licenses>
        <VirtualServerLicenses 
          :licenses="saleDefinition.definition.virtualServer.licenses"
          :splaPackages="splaPackages"
          @addLicence="addLicence"
          @editLicense="editLicense"
          @removeLicense="removeLicense"
        />
      </template>
      <template v-slot:usage>
        <VirtualServerUsage 
          :usage="saleDefinition.definition.virtualServer.usage"
        />
      </template>
      <template v-slot:ipmanagement>
        <VirtualServerVirtualInternetAccess 
          :internetAccess="saleDefinition.definition.virtualServer.virtualInternetAccess"
          :ipv4Network="ipv4Network"
          :ipv6Network="ipv6Network"
          :netmasksV4="netmasksV4"
          :netmasksV6="netmasksV6"
          :saleDefinition="saleDefinition"
          @saveSale="saveSale"
          @createObj="createObj"
          @ipv4RangeChange="ipv4RangeChange"
          @ipv6RangeChange="ipv6RangeChange"
        />
      </template>
    </service-screen-layout>
    
    <SplaLicenceModal
      ref="splaModal" 
      :modalTitle="selectedSplaLicence.splaPackageNumber ? 'Edit Licence' : 'Add Licence'"
      :splaLicence="selectedSplaLicence"
      :splaPackages="splaPackages"
      @saveSplaLicence="saveSplaLicence"
    /> 
  </div>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SplaLicenceModal from '@/components/Admin/Modals/SplaLicenceModal';

import VirtualServerDetails from "@/components/ObjParts/SingleCaptured/VirtualServerDetails";
import VirtualServerPackage from "@/components/ObjParts/Selected/VirtualServerPackage";
import VirtualServerAddOns from "@/components/ObjParts/OptionalCaptured/VirtualServerAddOns";
import VirtualServerLicenses from "@/components/ObjParts/MultipleCaptured/VirtualServerLicenses";
import VirtualServerUsage from "@/components/ObjParts/MultipleCaptured/VirtualServerUsage";
import VirtualServerVirtualInternetAccess from "@/components/ObjParts/SingleCaptured/VirtualServerVirtualInternetAccess";

export default {
  components: {
    ServiceScreenLayout,
    SplaLicenceModal,
    VirtualServerDetails,
    VirtualServerPackage,
    VirtualServerAddOns,
    VirtualServerLicenses,
    VirtualServerUsage,
    VirtualServerVirtualInternetAccess
  },
  data() {
    return {
      parts: [
        {
          name: "details",
          icon: "fa-solid fa-globe",
          displayName: "Virtual Server",
          permissions: ["*"],
        },
        {
          name: "packagemanagement",
          icon: "fa-solid fa-cube",
          displayName: "Package Management",
          permissions: ["*"],
        },
        {
          name: "addons",
          icon: "fa-solid fa-square-plus",
          displayName: "Add Ons",
          permissions: ["*"],
        },
        {
          name: "licenses",
          icon: "fa-solid fa-id-card",
          displayName: "SPLA",
          permissions: ["*"],
        },
        {
          name: "usage",
          icon: "fa fa-chart-line",
          displayName: "Usage",
          permissions: ["STAFF"],
        },
        {
          name: "ipmanagement",
          icon: "fa fa-book",
          displayName: "IP Management",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        product: {},
        definition: {
          virtualServer: {
            licenses: [],
            usage: [],
            virtualServerPackage: {},
            virtualInternetAccess: {
              routedIpv4Networks: [],
              routedIpv6Networks: [],
            },
            virtualServerPackageNumber: null,
            virtualAddons: {},
          },
        },
        supportingObjs: {
          routedIpv4Networks: [],
          routedIpv6Networks: [],
        }
      },
      packages: [],
      saleNumber: null,
      newLicense: {},
      selectedSplaLicence: {},
      splaPackages: [],
      ipv4Network: {},
      ipv6Network: {},
      netmasksV4: [32, 30, 29, 28, 27, 26, 25],
      netmasksV6: [48, 56, 64],
    };
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.getSale(this.saleNumber);
  },
  methods: {
    getSale: function() {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
        (response) => {
          this.saleDefinition = response.data; 

          this.networkSetup();         

          this.splaPackages = this.saleDefinition.selectableOptions.splaPackage;  
          this.packages = this.saleDefinition.selectableOptions.virtualServerPackage.filter(pkg => pkg.productNumber == this.saleDefinition.product.productNumber); 
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Sale Definition", error);
        }
      );
    },
    networkSetup: function() {
      this.saleDefinition.supportingObjs.routedIpv4Networks.virtualInternetAccessNumber = this.saleDefinition.definition.virtualServer.virtualInternetAccess.virtualInternetAccessNumber;
      this.saleDefinition.supportingObjs.routedIpv6Networks.virtualInternetAccessNumber = this.saleDefinition.definition.virtualServer.virtualInternetAccess.virtualInternetAccessNumber;

      this.saleDefinition.supportingObjs.routedIpv4Networks.networkType = "ROUTED";
      this.saleDefinition.supportingObjs.routedIpv6Networks.networkType = "ROUTED";

      this.ipv4Network = this.saleDefinition.supportingObjs.routedIpv4Networks;
      this.ipv6Network = this.saleDefinition.supportingObjs.routedIpv6Networks;

      if (this.saleDefinition.definition.virtualServer.virtualInternetAccess.routedIpv4Networks.length > 0) {
          this.ipv4Network = this.saleDefinition.definition.virtualServer.virtualInternetAccess.routedIpv4Networks[0];
      }

      if (this.saleDefinition.definition.virtualServer.virtualInternetAccess.routedIpv6Networks.length > 0) {
          this.ipv6Network = this.saleDefinition.definition.virtualServer.virtualInternetAccess.routedIpv6Networks[0];
      }
    },
    packageChange: function(newValue) {
      return newValue ? this.saleDefinition.definition.virtualServer.virtualServerPackageNumber = newValue : this.saleDefinition.definition.virtualServer.virtualServerPackageNumber;
    },
    ipv4RangeChange: function(newValue) {
      if (this.saleDefinition.definition.virtualServer.virtualInternetAccess.routedIpv4Networks.length > 0) {
        return newValue ? this.saleDefinition.definition.virtualServer.virtualInternetAccess.routedIpv4Networks[0].shortMask = newValue : this.saleDefinition.definition.virtualServer.virtualInternetAccess.routedIpv4Networks[0].shortMask;
      }
    },
    ipv6RangeChange: function(newValue) {
      if (this.saleDefinition.definition.virtualServer.virtualInternetAccess.routedIpv6Networks.length > 0) {
        return newValue ? this.saleDefinition.definition.virtualServer.virtualInternetAccess.routedIpv6Networks[0].shortMask = newValue : this.saleDefinition.definition.virtualServer.virtualInternetAccess.routedIpv6Networks[0].shortMask;
      }
    },
    createObj: function(data) {            
      const hostUrl = this.$config.aimsAPIv2;
      const saleNumber = this.saleNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create?commit=${true}`, data).then(
        (response) => {
          this.showSuccess("Save Success");
          this.getSale();
        },
        (error) => {
          console.error(error);
          this.showError("Error Saving Object", error);
        }
      )
    },
    saveSale: function() {     
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
          (response) => {
            this.showSuccess("Save Success");
            this.saleDefinition = response.data;
            this.getSale();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Sale Definition", error);
          }
        );
    },
    saveSplaLicence: function(spla) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;

      if (spla.splaPackageNumber) {
        this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, spla).then(
          (response) => {
            this.showSuccess("SPLA Updated successfully");
            this.getSale();
          },
          (error) => {
            console.error(error);
            this.showError("Error Updating SPLA", error);
          }
        );
      }
    },
    addLicence: function() {
      this.selectedSplaLicence = this.saleDefinition.supportingObjs.licenses;
      this.selectedSplaLicence.virtualServerNumber = this.saleDefinition.definition.virtualServer.virtualServerNumber;
      this.selectedSplaLicence.virtualServerPackageNumber = this.saleDefinition.definition.virtualServer.virtualServerPackageNumber;
      this.$refs.splaModal.open();
    },
    editLicense: function(data) {
      this.selectedSplaLicence = data;
      this.$refs.splaModal.open();
    },
    removeLicense: function(data) {
      this.$swal({
          title: "Are you sure you want to delete this Licence?",
          text: data.name,
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
      }).then((result) => {
          if (result.value) {
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.saleDefinition.sale.saleNumber;

            this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, data).then(
              (response) => {
                this.showSuccess("Delete Success");
                this.getSale();
              },(error) => {
                console.error(error);
                this.showError("Error Removing SPLA", error);
              });
          }
      });
    }
  },
};
</script>
