<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <button class="btn btn-info" type="button" v-if="userEditRight" @click="createNewPackage">
                    Create New Package
                </button>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-responsive table-bordered" v-if="axxessVumatelFtthPackages.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Size (GB)</th>
                                <th>Price (excl. VAT)</th>
                                <th>Expired</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                                v-for="axxessVumatelFtthPackage in axxessVumatelFtthPackages" 
                                :key="axxessVumatelFtthPackage.axxessFtthPackageNumber"
                                :class="{ 'bg-primary': axxessVumatelFtthPackage.expired }"
                            >
                                <td>{{ axxessVumatelFtthPackage.description }}</td>
                                <!-- <td>{{ axxessVumatelFtthPackage.size }}</td> -->
                                <td>{{ axxessVumatelFtthPackage.size > 0 ? axxessVumatelFtthPackage.size : 'Uncapped' }} {{ axxessVumatelFtthPackage.size > 0 ? 'GB' : ''}}</td>
                                <td>
                                    {{ getPrice(axxessVumatelFtthPackage.prices, period) }}
                                    <span
                                        v-if="userEditRight"
                                        style="cursor: pointer"
                                        @click="openPriceModal(axxessVumatelFtthPackage)"
                                        v-tooltip="'Edit Row Price'"
                                        class="pull-right"
                                    >
                                        <em class="fa fa-edit fa-fw text-green"></em>
                                    </span>
                                </td>
                                <td>
                                    <span
                                        class="badge"
                                        :class="axxessVumatelFtthPackage.expired ? 'badge-warning' : 'badge-green'"
                                    >
                                        {{ axxessVumatelFtthPackage.expired }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <button
                                        v-if="userEditRight"                  
                                        v-tooltip="'Edit Package'"
                                        type="button"
                                        class="btn btn-sm btn-info m-1"
                                        @click="editPackage(axxessVumatelFtthPackage)"
                                    >
                                    <em class="fa fa-edit fa-fw"></em>
                                    </button>
                                    <button
                                        v-if="userEditRight"                  
                                        v-tooltip="'Delete Package'"
                                        type="button"
                                        class="btn btn-sm btn-primary"
                                        @click="deletePackage(axxessVumatelFtthPackage)"
                                    >
                                    <em class="fa fa-trash fa-fw"></em>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="alert alert-info" v-else>
                    No Axxess Vumatel FTTH Packages Available
                </div>
            </div>
        </div>
        <ModifyAxxessVumatelFtthPackageModal 
            ref="packageModal" :package="axxessVumatelFtthPackage" @savePackage="savePackage" :modalTitle="modalTitle"
        />
        <AddPriceModal
            ref="addPriceModal"
            :row-price="rowPrice"
            :new-price="newPrice"
            @addPrice="addPrice"
            @pricesAdded="getAxxessVumatelFtthPackages()"
        />
    </div>
</template>

<script>
import PriceFunctions from "@/components/Admin/PriceFunctions";

import AddPriceModal from "@/components/Admin/Modals/AddPriceModal";
import ModifyAxxessVumatelFtthPackageModal from "@/components/Admin/Modals/ModifyAxxessVumatelFtthPackageModal";

export default {
    extends: PriceFunctions,
    components: {
        AddPriceModal,
        ModifyAxxessVumatelFtthPackageModal,
    },
    props: {
        productNumber: {
            type: Number,
            required: true,
        },
        userEditRight: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            axxessVumatelFtthPackages: [],
            period: this.getCurrentPeriod(),
            rowPrice: [],
            axxessVumatelFtthPackage: {},
            newPrice: {},
            modalTitle: '',
            newPriceObj: {},
        }
    },
    mounted() {
        this.getAxxessVumatelFtthPackages();
    },
    methods: {
        getAxxessVumatelFtthPackages: function() {
            const hostUrl = this.$config.aimsAPI;
            const productNumber = this.productNumber;
            const packageClassName = 'AxxessFtthPackage';

            this.$http.get(`${hostUrl}pricing/packages/product/${productNumber}/${packageClassName}`).then(
                (response) => {
                    this.axxessVumatelFtthPackages = response.data;
                    this.setNewPrice();
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Fetching Axxess Vumatel FTTH Packages", error);
                }
            );
        },
        editPackage: function(axxessVumatelFtthPackage) {
            this.modalTitle = 'Edit Package';
            this.axxessVumatelFtthPackage = this.deepCopyObject(axxessVumatelFtthPackage);
            this.$refs.packageModal.open();
        },
        openPriceModal: function(axxessVumatelFtthPackage) {
            this.rowPrice = this.deepCopyObject(axxessVumatelFtthPackage.prices);
            this.newPrice.axxessFtthPackageNumber = axxessVumatelFtthPackage.axxessFtthPackageNumber;
            this.$refs.addPriceModal.open();
        },
        createNewPackage: function() {
            this.modalTitle = 'Add New Package';
            const hostUrl = this.$config.aimsAPI;
            const packageClass = "AxxessFtthPackage";
            
            this.$http.get(`${hostUrl}pricing/new/package/${packageClass}`).then(
                (response) => {
                    this.axxessVumatelFtthPackage = response.data;
                    this.axxessVumatelFtthPackage.productNumber = this.productNumber;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Fetching Price Template", error);
                }
            ).finally(() => {
                this.$refs.packageModal.open();
            });
        },
        savePackage: function() {
            const hostUrl = this.$config.aimsAPI;
            const vumatelPackage = this.axxessVumatelFtthPackage;

            if (vumatelPackage.axxessFtthPackageNumber) {
                this.$http.put(`${hostUrl}pricing/packages/update`, vumatelPackage).then(
                    (response) => {
                        this.showSuccess("Successfully Edited Package");
                        this.getAxxessVumatelFtthPackages();
                    },
                    (error) => {
                        this.showError("Error Saving Package", error);
                        console.error(error);
                    }
                );
            } else {
                this.$http.post(`${hostUrl}pricing/packages/create`, vumatelPackage).then(
                        (response) => {
                            this.showSuccess("Successfully Added Package");
                            response.data.prices = [];
                            this.openPriceModal(response.data);
                        },
                        (error) => {
                            this.showError("Error Saving Package", error);
                            console.error(error);
                        }
                    )
            };
        },
        addPrice: function(newPrice) {                
            this.rowPrice.forEach(price => {
                if (price.validUntil == 0) {
                    if (newPrice.validFrom % 100 > 1) {
                        price.validUntil = newPrice.validFrom - 1;
                    } else {
                        price.validUntil = (newPrice.validFrom - 100) + 11;
                    }
                }
            });
            this.rowPrice.push(newPrice);
            this.setNewPrice();
            this.newPrice.axxessFtthPackageNumber = newPrice.axxessFtthPackageNumber;
        },
        setNewPrice: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}pricing/new/price/AxxessFtthPackagePrice`).then(
                (response) => {
                    this.newPrice = response.data;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Fetching Price Template", error);
                }
            );
        },
        deletePackage(axxessVumatelFtthPackage) {
        this.$swal({
            title: "Are you sure you want to delete this Package?",
            type: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.value) {
                const hostUrl = this.$config.aimsAPI;
                const vumatelPackage = axxessVumatelFtthPackage;

                this.$http.delete(`${hostUrl}pricing/pricepackages/${vumatelPackage.objKey}?objClassName=${vumatelPackage.class}`).then(
                    (response) => {
                        this.showSuccess(response.data.message);
                        this.getAxxessVumatelFtthPackages();
                    },
                    (error) => {
                        this.showError("Error Deleting Package", error);
                    }
                );
            }
        });
        },
    }
    
}
</script>

<style lang="scss" scoped>

</style>