<template>
  <div class="card">
    <div class="card-header row-spacing">
        <button v-allowed:view="['STAFF']" class="btn btn-info" @click="openNewAddonsModal()">Add FTTB Addon</button>
    </div>
    <div class="card-body">
      <table class="table table-bordered">
        <thead>
          <tr class="dark-row">
            <th>Addon Name</th>
            <th>VLAN Name</th>
            <th>VLAN ID</th>
            <th v-allowed:view="['STAFF']"></th>
          </tr>
        </thead>
        <tbody v-if="fttbAddons.length > 0">
          <tr v-for="fttbAddon in fttbAddons" :key="fttbAddon.fttbAddonNumber">
            <td>{{ fttbAddon.name }}</td>
            <td>{{ fttbAddon.vlanName }}</td>
            <td>{{ fttbAddon.vlanId }}</td>
            <td v-allowed:view="['STAFF']">
              <div class="text-center">
                <button class="btn btn-info" @click="openAddonsModal(fttbAddon)"><i class="fa fa-edit"></i></button>
                <button class="btn btn-primary" @click="deleteAddonPackage(fttbAddon) "><i class="fa fa-trash"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
              <td colspan="4">No Addons added to this Service</td>
          </tr>
        </tbody>
      </table>
    </div>
    <FttbAddonsModal
      ref="openAddonsModal" 
      :modalTitle="modalTitle"
      :fttbAddon="selectedAddon"
      :routedIpv4Network="routedIpv4Network"
      :routedIpv6Network="routedIpv6Network"
      @saveFttbAddon="saveFttbAddon"
      @addFttbAddonRoutedIpv4Network="addFttbAddonRoutedIpv4Network"
      @addFttbAddonRoutedIpv6Network="addFttbAddonRoutedIpv6Network"
    />

    <AddFttbAddonModal 
      ref="openNewAddonsModal"
      :modalTitle="modalTitle"
      :addonConnectivityPackages="addonConnectivityPackages"
      :fttbAddon="selectedAddon"
      :fttbAddons="fttbAddons"
      @addFttbAddon="addFttbAddon"
    />
  </div>
</template>

<script>
import FttbAddonsModal from '@/components/Admin/Modals/FttbAddonsModal';
import AddFttbAddonModal from '@/components/Admin/Modals/AddFttbAddonModal';
export default {
  components: {
    FttbAddonsModal,
    AddFttbAddonModal,
  },
  props: {
    fttbAddons: {
        type: Array,
        required: true
      },
      addonConnectivityPackages: {
        type: Array,
        required: true
      },
      saleDefinition: {
        type: Object,
        required: true
      }
  },
  data() {
    return {
      modalTitle: "",
      selectedAddon: {
        routedIpv4Network: "",
        routedIpv6Network: ""
      },
      routedIpv4Network: {},
      routedIpv6Network: {},
    }
  },
  methods: {
    openAddonsModal: function(data) {
      this.$refs.openAddonsModal.open();
      this.modalTitle = `${data.name}: ${data.vlanName} [VLAN ID: ${data.vlanId}]`;
      this.selectedAddon = this.deepCopyObject(data);
      this.routedIpv4Network = Object.assign({}, this.saleDefinition.supportingObjs.routedIpv4Network);
      this.routedIpv6Network = Object.assign({}, this.saleDefinition.supportingObjs.routedIpv6Network);
    },
    openNewAddonsModal: function() {
      this.$refs.openNewAddonsModal.open();
      this.modalTitle = 'Add New Addon Package';
    },
    saveFttbAddon: function(fttbAddon) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;
      if (fttbAddon.fttbAddonNumber) {
        this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, fttbAddon).then(
          (response) => {
            this.showSuccess("Fttb Addon Updated successfully");
            this.$emit('reloadAddons');
            const index = this.fttbAddons.findIndex(
              (a) => a.fttbAddonNumber === fttbAddon.fttbAddonNumber
            );
            this.fttbAddons[index] = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Updating Fttb Addon", error);
          }
        );
      }
    },
    addFttbAddon: function(data) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, data).then(
        (response) => {    
          this.$emit('reloadAddons');     
        },
        (error) => {
            console.error(error);
            this.showError("Error Creating Fttb Addon", error);
        }
      );
    },
    addFttbAddonRoutedIpv4Network: function(routedIpv4Network) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, routedIpv4Network).then(
        (response) => {     
        },
        (error) => {
            console.error(error);
            this.showError("Error Creating IPv4 Routed Network", error);
        }
      );
    },  
    addFttbAddonRoutedIpv6Network: function(routedIpv6Network) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleDefinition.sale.saleNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create`, routedIpv6Network).then(
        (response) => {    
          this.showSuccess("Routed networks added");
          this.getAddonRoutedNetworks(routedIpv6Network.fttbAddonNumber); 
          this.$emit('reloadAddons');  
        },
        (error) => {
            console.error(error);
            this.showError("Error Creating IPv6 Routed Network", error);
        }
      );
    },  
    getAddonRoutedNetworks: async function(fttbAddonNumber) {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}network/addon/ipv4/${fttbAddonNumber}`).then(
        (response) => {
          this.selectedAddon.routedIpv4Network = Object.assign({}, response.data);
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching IPv4 Routed Network", error);
        }
      );

      this.$http.get(`${hostUrl}network/addon/ipv6/${fttbAddonNumber}`).then(
        (response) => {
          this.selectedAddon.routedIpv6Network = Object.assign({}, response.data);
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching IPv4 Routed Network", error);
        }
      );
    },
    deleteAddonPackage: function(addon) {
      this.$swal({
                title: "Are you sure you want to delete this addon?",
                text: `${addon.name}`,
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                  const hostUrl = this.$config.aimsAPI;
                  const saleNumber = this.saleDefinition.sale.saleNumber;
                  
                  this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete`, addon).then(
                      (response) => {
                          this.showSuccess("Addon successfully removed");
                          this.$emit('reloadAddons');  
                      },
                      (error) => {
                          console.error(error);
                          this.showError("Error Deleting Addon", error);
                      });
                }
            });             
      },
  },
}
</script>

<style lang="scss" scoped>
.dark-row {
    width: 900px !important;
    background-color: #3a3f51;

    th {
        background-color: #3a3f51;
        color: #FFFFFF !important;

        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
        width: calc(100px + 1em);
    }
}
</style>