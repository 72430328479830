<template>
  <div class="card card-default">
    <div class="card-header pb-0">
      <div class="product-heading mt-3">
        <h4>{{ productHeading }}</h4>
      </div>
      <div class="random-sale--button" v-if="productNumber != 0 && saleDefinitions.includes(productDetail.productDefinition) && saleExists">
        <button
          class="btn btn-success float-left ml-0"
          @click="goToRandomSale"
          v-allowed:view="['STAFF']"
        >
          View random sale for this product
        </button>
      </div>
    </div>
    <hr />
    <div class="card-body">
      <div class="product-setup--tabs">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" v-for="item in navItems" :key="item.name" v-if="item.show">
            <a
              :href="item.link"
              :class="item.class"
              :id="item.id"
              data-toggle="tab"
              role="tab"
              :aria-controls="item.controls"
              aria-selected="true"
            >
              <i :class="item.icon"></i>
              {{ item.name }}
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane active show" id="detail">
            <div class="row row-spacing">
              <div class="col-md-12">
                <div class="row row-spacing">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <h4>Product Information</h4>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="form-group col-md-4">
                          <label>Product Number</label>
                          <input type="text" class="form-control" v-model="productDetail.productNumber" disabled>
                        </div>
                        <div class="form-group col-md-8">
                          <label>Product Description</label>
                          <input type="text" class="form-control" v-model="productDetail.description" :disabled="!isAdminUser && !isAuthorizedUser">
                        </div>
                    </div>
                    <div class="row">

                        <div class="form-group col-md-4">
                          <label>For Sale</label>
                          <select
                            v-model="productDetail.forSale"
                            class="form-control"
                            :disabled="!isAdminUser && !isAuthorizedUser"
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>

                      <div class="form-group col-md-4">
                        <label>Product Restrictions</label>
                        <select
                          v-model="productDetail.clientAccessRestriction"
                          class="form-control"
                          :disabled="!isAdminUser && !isAuthorizedUser"
                        >
                          <option
                            :value="restriction.description"
                            v-for="restriction in productRestrictions"
                            :key="restriction.name"
                          >
                            {{ restriction.description }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label>Wizard Restrictions</label>
                        <select
                          v-model="productDetail.wizardAccessRestriction"
                          class="form-control"
                          :disabled="!isAdminUser && !isAuthorizedUser"
                        >
                          <option
                            :value="restriction.description"
                            v-for="restriction in wizardRestrictions"
                            :key="restriction.name"
                          >
                            {{ restriction.description }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row row-spacing">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <h4>Product Classification</h4>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label>Type</label>
                        <select
                          v-model="productDetail.classificationType"
                          class="form-control"
                          :disabled="!isAdminUser && !isAuthorizedUser"
                        >
                          <option
                            v-for="classType in productClassificationTypes"
                            :value="classType"
                            :key="classType"
                          >
                            {{ classType }}
                          </option>
                      </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label>Supplier</label>
                        <select
                          v-model="productDetail.supplierNumber"
                          class="form-control"
                          :disabled="!isAdminUser && !isAuthorizedUser"
                        >
                          <option
                            :value="supplier.supplierNumber"
                            v-for="supplier in suppliers"
                            :key="supplier.supplierNumber"
                          >
                            {{ supplier.name }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label>Vendor</label>
                        <select
                          v-model="productDetail.vendorNumber"
                          class="form-control"
                          :disabled="!isAdminUser && !isAuthorizedUser"
                          @change="getProductSegments()"
                        >
                          <option
                            :value="vendor.vendorNumber"
                            v-for="vendor in vendors"
                            :key="vendor.vendorNumber"
                          >
                            {{ vendor.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label>Segment</label>
                        <select
                          v-model="productDetail.segmentNumber"
                          class="form-control"
                          :disabled="!isAdminUser && !isAuthorizedUser"
                           @change="getProductSubSegments()"
                        >
                          <option
                            :value="segment.segmentNumber"
                            v-for="segment in segments"
                            :key="segment.segmentNumber"
                          >
                            {{ segment.name}}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label>Subsegment</label>
                        <select
                          v-model="productDetail.subSegmentNumber"
                          class="form-control"
                          :disabled="!isAdminUser && !isAuthorizedUser"
                        >
                          <option
                            :value="subsegment.subSegmentNumber"
                            v-for="subsegment in subSegments"
                            :key="subsegment.subSegmentNumber"
                          >
                            {{ subsegment.name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Sale Definition</label>
                          <select
                            v-model="productDetail.productDefinition"
                            class="form-control"
                            :disabled="!isAdminUser && !isAuthorizedUser"
                          >
                            <option
                              v-for="def in saleDefinitions"
                              :value="def"
                              :key="def"
                            >
                              {{ def }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row mb-4">
              <div class="col-md-12 mt-4">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-success" v-if="isAdminUser || isAuthorizedUser || productDetail.productDefinition" @click="saveProduct">Save</button>
                    <button class="btn" @click="goToProductsList">Back</button>
                    <button class="btn btn-link" v-if="isAdminUser || isAuthorizedUser && productNumber != 0" @click="cloneProduct">Clone</button>
                    <button class="btn btn-link text-red" v-if="isAdminUser || isAuthorizedUser && productNumber != 0" @click="deleteProduct">Delete</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="config">
            <div class="row row-spacing">
              <div class="col-md-12">
                    <div class="row row-spacing">
                      <div class="col-md-12">
                        <h4>Product Variables</h4>
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive table-bordered">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(variable, index) in productDetail.productvariables"
                                :key="index"
                                :class="{'product-var-removed': variable.removed,'product-var-added': variable.added,}">
                                <td>
                                  <i class="fa fa-minus-circle" title="Product var not in Sale Def anymore" v-if="variable.removed"></i>
                                  <i class="fa fa-plus-circle" title="Product var added to Sale Def and this product needs a value" v-if="variable.added"></i>
                                  <span>{{ variable.description }}:</span>
                                </td>
                                <td>
                                  <span class="badge" :class="getProductVarTypeClass(variable.type)" style="margin-bottom: 5px">
                                    {{ variable.type }}
                                  </span>
                                  <input v-model="variable.value" class="form-control col-md-3" :disabled="variable.removed || !isAdminUser && !isAuthorizedUser"/>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
            <hr />
            <div class="row mb-4">
              <div class="col-md-12 mt-4">
                <div class="row">
                  <div class="col">
                    <button class="btn btn-success" v-if="isAdminUser || isAuthorizedUser || productDetail.productDefinition" @click="saveProduct">Save</button>
                    <button class="btn" @click="goToProductsList">Back</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="pricing" v-if="pricingRouteName">
            <div class="row row-spacing">
              <div class="col-md-12">
                <strong class="text-danger" v-if="!pricingRouteName">Pricing Page not set for Product</strong>
                  <FtthCappedPricing
                    v-if="pricingRouteName === 'ftthcappedpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <FtthUncappedPricing
                    v-else-if="pricingRouteName === 'ftthuncappedpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <FttbPricing
                    v-else-if="pricingRouteName === 'fttbpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <AxxessLtePricing
                    v-else-if="pricingRouteName === 'axxessltepricing'"
                    :productNumber="productDetail.productNumber"
                    :productSupplier="getSupplier(productDetail)"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <AxxessLtePricing
                    v-else-if="pricingRouteName === 'axxessltepricingl2tp'"
                    :productNumber="productDetail.productNumber"
                    :productSupplier="getSupplier(productDetail)"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <WirelessLtePricing
                    v-else-if="pricingRouteName === 'wirelessltepricing'"
                    :productNumber="productDetail.productNumber"
                    :productSupplier="getSupplier(productDetail)"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <CPanelHostingPricing
                    v-else-if="pricingRouteName === 'cpanelhostingpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <SupportServicePricing
                    v-else-if="pricingRouteName === 'supportservicepricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <BasicPricing
                    v-else-if="pricingRouteName === 'basicpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <RouterRentalPricing
                    v-else-if="pricingRouteName === 'routerrentalpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <VirtualServerPricing
                    v-else-if="pricingRouteName === 'virtualserverpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <SplaServicePricing
                    v-else-if="pricingRouteName === 'SplaServicePricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <SolarwindsBackupPricing
                    v-else-if="pricingRouteName === 'solarwindsbackuppricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <WanatelCloudPbxPricing
                    v-else-if="pricingRouteName === 'wanta'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <Cx3PbxPricing
                    v-else-if="pricingRouteName === 'cx3pbxpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <ZimbraMailPricing
                    v-else-if="pricingRouteName === 'zimbramailpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <PrivateApnPackagePricing
                    v-else-if="pricingRouteName === 'mtnapnpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <Layer3Pricing
                    v-else-if="pricingRouteName === 'layer3pricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <CyberRiskAwarePricing
                    v-else-if="pricingRouteName === 'cyberriskawarepricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <BandwidthPricing
                    v-else-if="pricingRouteName === 'BandwidthPricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <NetworkRangePricing
                    v-else-if="pricingRouteName === 'NetworkRangePricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <AxxessFtthPricing
                    v-else-if="pricingRouteName === 'axxessftthpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <WebsiteZimbraPricing
                    v-else-if="pricingRouteName === 'WebsiteZimbraPricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"          
                  />
                  <WebsitePhpPricing
                    v-else-if="pricingRouteName === 'WebsitePhpPricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"          
                  />
                  <CabinetPowerPricing
                    v-else-if="pricingRouteName === 'CabinetPowerPricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"          
                  />
                  <InternalDnsPricing
                    v-else-if="pricingRouteName === 'internaldnspricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <IonlineApnPackagePricing
                    v-else-if="pricingRouteName === 'ionlineapnpricing'"
                    :productNumber="productDetail.productNumber"
                    :userEditRight="isAdminUser || isAuthorizedUser"
                  />
                  <strong style="color: red" v-else
                    >Pricing Page not set for Product</strong
                  >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FtthCappedPricing from "@/components/Admin/FtthCappedPricing";
import FtthUncappedPricing from "@/components/Admin/FtthUncappedPricing";
import OpenserveUncappedPricing from "@/components/Admin/OpenserveUncappedPricing";
import FttbPricing from "@/components/Admin/FttbPricing";
import AxxessLtePricing from "@/components/Admin/AxxessLtePricing";
import WirelessLtePricing from "@/components/Admin/WirelessLtePricing";
import CPanelHostingPricing from "@/components/Admin/CPanelHostingPricing";
import SupportServicePricing from "@/components/Admin/SupportServicePricing";
import BasicPricing from "@/components/Admin/BasicPricing";
import RouterRentalPricing from "@/components/Admin/RouterRentalPricing";
import VirtualServerPricing from "@/components/Admin/VirtualServerPricing";
import SplaServicePricing from "@/components/Admin/SplaServicePricing";
import SolarwindsBackupPricing from "@/components/Admin/SolarwindsBackupPricing";
import WanatelCloudPbxPricing from "@/components/Admin/WanatelCloudPbxPricing";
import Cx3PbxPricing from "@/components/Admin/Cx3PbxPricing";
import ZimbraMailPricing from "@/components/Admin/ZimbraMailPricing";
import PrivateApnPackagePricing from "@/components/Admin/PrivateApnPackagePricing";
import BandwidthPricing from "@/components/Admin/BandwidthPricing";
import NetworkRangePricing from "@/components/Admin/NetworkRangePricing";
import Layer3Pricing from "@/components/Admin/Layer3Pricing";
import CyberRiskAwarePricing from "@/components/Admin/CyberRiskAwarePricing";
import AxxessFtthPricing from "@/components/Admin/AxxessFtthPricing";
import WebsiteZimbraPricing from "@/components/Admin/WebsiteZimbraPricing";
import WebsitePhpPricing from "@/components/Admin/WebsitePhpPricing";
import CabinetPowerPricing from "@/components/Admin/CabinetPowerPricing";
import InternalDnsPricing from "@/components/Admin/InternalDnsPricing";
import IonlineApnPackagePricing from "@/components/Admin/IonlineApnPackagePricing"

  export default {
    components: {
      FtthCappedPricing,
      FtthUncappedPricing,
      OpenserveUncappedPricing,
      FttbPricing,
      AxxessLtePricing,
      WirelessLtePricing,
      CPanelHostingPricing,
      SupportServicePricing,
      BasicPricing,
      RouterRentalPricing,
      VirtualServerPricing,
      SplaServicePricing,
      SolarwindsBackupPricing,
      WanatelCloudPbxPricing,
      Cx3PbxPricing,
      ZimbraMailPricing,
      PrivateApnPackagePricing,
      Layer3Pricing,
      CyberRiskAwarePricing,
      BandwidthPricing,
      NetworkRangePricing,
      AxxessFtthPricing,
      WebsiteZimbraPricing,
      WebsitePhpPricing,
      CabinetPowerPricing,
      InternalDnsPricing,
      IonlineApnPackagePricing
    },
    data() {
      return {
        productNumber: 0,
        productDetail: {
          productvariables: [],
        },
        randomSale: {},
        navItems: [
          {
            link: "#detail",
            class: "nav-link active",
            id: "detail-tab",
            controls: "detail",
            name: "Detail",
            icon: "fa-solid fa-circle-info",
            show: true
          },
          {
            link: "#config",
            class: "nav-link",
            id: "config-tab",
            controls: "config",
            name: "Configuration",
            icon: "fa-solid fa-gears",
            show: true
          },
          {
            link: "#pricing",
            class: "nav-link",
            id: "pricing-tab",
            controls: "pricing",
            name: "Pricing",
            icon: "fa-regular fa-money-bill-1",
            show: false
          },
        ],
        productClassificationTypes: [],
        productRestrictions: [],
        wizardRestrictions: [],
        suppliers: [],
        vendors: [],
        segments: [],
        subSegments: [],
        saleDefinitions: [],
        pricingRouteName: null,
        saleExists: false,
        inititalProductVariables: [],
        bundleProducts: ['za.co.adept.aims.product.datacentre.DataCentreInternetService'],
      }
    },
    created() {
      this.getProductClassificationTypes();
      this.getProductSuppliers();
      this.getProductVendors();
      this.getProductSegments();
      this.getProductSubSegments();
    },
    mounted() {
      this.productNumber = this.$route.params.productNumber;
      this.$root.$emit("SET_PAGE_TITLE", "Product");

      this.getExistingProduct();

      this.getSaleDefinitions();
      this.getProductRestrictions();
      this.getWizardRestrictions();
    },
    computed: {
      productHeading() {
        return this.productDetail.description ? `${this.productDetail.description} [${this.productDetail.productNumber}]` : 'New Product';
      },
      isAdminUser() {
        return this.userContainsPermission(['ADMIN']);
      },
      isAuthorizedUser() {
        return this.userContainsPermission(['PRODUCT_MANAGER']);
      }
    },
    methods: {
      getExistingProduct: function() {
        const hostUrl = this.$config.aimsAPI;
        const productNumber = this.productNumber;

        this.$http.get(`${hostUrl}products/${productNumber}`).then(
          (response) => {
            this.productDetail = response.data;
            document.title = this.productDetail.description;           

            const productDefinitionMetaData = this.getProductDefinition(this.productDetail.productDefinition);
            this.pricingRouteName = productDefinitionMetaData.pricingRouteName;
            this.inititalProductVariables = this.deepCopyObject(this.productDetail.productvariables);

            this.getSaleDefinitions();
            this.salePresent();
            this.getProductVariables();
            this.getProductRestrictions();
            this.getWizardRestrictions();

            const tabs = this.navItems;

            if (this.pricingRouteName) {
                tabs.filter((tab) => {
                  tab.show = true
                }
              );
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Product", error);
          }
        );
      },
      salePresent: function() {
        const hostUrl = this.$config.aimsAPI;
        const productNumber = this.productNumber;

        this.$http.get(`${hostUrl}products/${productNumber}/randomsale`).then(
            (response) => {
              this.saleExists = true;
            },
            (error) => {
              this.saleExists = false;
            }
          );
      },
      saveProduct: function() {
        const productCopy = this.deepCopyObject(this.productDetail);

        const productObj = {
          product: productCopy,
          productVariables: productCopy.productvariables,
        };

        productObj.product.class = "adept.aims.classes.Product";

        productObj.product.prorataType = "FULL_PRICE";
        // productObj.product.auditType = "ALWAYS";        
        this.$http.post(this.$config.aimsAPI + "products/save", productObj).then(
          (response) => {
            this.productDetail = response.data.product;
            this.productDetail.productvariables = response.data.productVariables;
            this.showSuccess("Save Success");
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Product", error);
          }
        );
      },
      goToRandomSale: function() {
        const hostUrl = this.$config.aimsAPI;
        const productNumber = this.productNumber;

        this.$http.get(`${hostUrl}products/${productNumber}/randomsale`).then(
          (response) => {
            this.randomSale = response.data;

            this.$router.push({
              name: "search",
              query: {
                saleNumber: this.randomSale.saleNumber,
              }
            });
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching random sale", error)
          }
        );
      },
      goToProductsList: function() {
        this.$router.go(-1);
      },
      getProductVarTypeClass: function(type) {
        switch (type) {
          case "STRING":
            return "bg-green";
          case "DOUBLE":
            return "bg-warning";
          case "INTEGER":
            return "bg-primary";
        }
      },
      getProductClassificationTypes: function() {
        const hostUrl = this.$config.aimsAPI;

        this.$http.get(`${hostUrl}products/classifications/types`).then(
          (response) => {
            this.productClassificationTypes = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error fetch product classification types", error);
          }
        );
      },
      getProductRestrictions: function() {
        const hostUrl = this.$config.aimsAPI;

        this.$http.get(`${hostUrl}products/restrictions`).then(
          (response) => {
            this.productRestrictions = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching product restrictions", error);
          }
        );
      },
      getWizardRestrictions: function() {
        const hostUrl = this.$config.aimsAPI;

        this.$http.get(`${hostUrl}products/restrictions/wizard`).then(
          (response) => {
            this.wizardRestrictions = response.data;            
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching product restrictions", error);
          }
        );
      },
      getProductSuppliers: function() {
        const hostUrl = this.$config.aimsAPIv2;

        this.$http.get(`${hostUrl}suppliers`).then(
            (response) => {      
                this.suppliers = response.data;               
            },
            (error) => {
                console.error(error);
                this.showError("Error fetching product suppliers", error);
            }
        );
      },
      getProductVendors: function() {
        const hostUrl = this.$config.aimsAPIv2;

        this.$http.get(`${hostUrl}vendors`).then(
            (response) => {
                this.vendors = response.data;                                      
            },
            (error) => {
                console.error(error);
                this.showError("Error fetching product vendors", error);
            }
        );
      },
      getProductSegments: function() {
        const hostUrl = this.$config.aimsAPIv2;

        this.$http.get(`${hostUrl}segments`).then(
            (response) => {      
                this.segments = response.data;            
            },
            (error) => {
                console.error(error);
                this.showError("Error fetching product vendors", error);
            }
        );
      },
      getProductSubSegments: function() {
        const hostUrl = this.$config.aimsAPIv2;

        this.$http.get(`${hostUrl}subsegments`).then(
            (response) => {      
                this.subSegments = response.data;                                                          
            },
            (error) => {
                console.error(error);
                this.showError("Error fetching product subsegments", error);
            }
        );
      },
      getSaleDefinitions: function() {
        const hostUrl = this.$config.aimsAPI;
        const productType = this.productDetail.definitionType;
        const old = "Old";

        if (productType === old) {
          this.$http.get(`${hostUrl}sales/definition/available/legacy`).then(
            (response) => {
              this.saleDefinitions = response.data;
            },
            (error) => {
              console.error(error);
              this.showError("Error fetching legacy sale definitions", error);
            }
          );
        } else {
          this.$http.get(`${hostUrl}sales/definition/available`).then(
            (response) => {
              this.saleDefinitions = response.data;
            },
            (error) => {
              console.error(error);
              this.showError("Error fetching legacy sale definitions", error);
            }
          );
        }
      },
      getProductVariables: function () {
        const hostUrl = this.$config.aimsAPI;
        const productNumber = this.productDetail.productNumber;

        this.$http.get(`${hostUrl}sales/definition/vars/${productNumber}`).then(
            (response) => {
              if (!this.productNumber || this.productNumber == 0) {
                this.productDetail.productvariables = response.data;
              } else {
                const currentProductVarsForSaleDef = response.data;

                currentProductVarsForSaleDef.map((cpv) => {
                  const index = this.inititalProductVariables.findIndex((ipv) =>
                      ipv.name === cpv.name
                  );

                  if (index === -1) {
                    const toAdd = this.deepCopyObject(cpv);
                    toAdd.added = true;
                    this.productDetail.productvariables.push(toAdd);
                  }
                });

                this.productDetail.productvariables.map((ipv) => {
                  const index = currentProductVarsForSaleDef.findIndex(
                    (cpv) => cpv.name === ipv.name
                  );

                  if (index == -1) {
                    ipv.removed = true;
                  }
                });
              }
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Product Variables", error);
            }
          );
      },
      cloneProduct: function() {
        const hostUrl = this.$config.aimsAPI;
        const productNumber = this.productNumber;

        this.$http.get(`${hostUrl}products/${productNumber}/clone`).then(
          (response) => {
            this.$router.replace({
              name: "productdetail",
              params: {
                productNumber: response.data.productNumber,
              },
            });
            this.productDetail = response.data;
            this.showSuccess("Successfully cloned product");
          },
          (error) => {
            console.error(error);
            this.showError("Error cloning product", error);
          }
        );
      },
      deleteProduct: function() {
        this.$swal({
          title: `Are you sure you want to delete product #${this.productNumber} ?`,
          text: "Only unused products can be deleted",
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            const hostUrl = this.$config.aimsAPI;

            this.$http.delete(`${hostUrl}products/product/${this.productNumber}`).then(
              (response) => {
                this.showSuccess("Successfully deleted Product");
                this.goToProductsList();
              },
              (error) => {
                this.showError("Error deleting Product.", error);
                console.error(error);
              }
            );
          }
        });
      },
      getSupplier: function(product) {
        let supplier = this.suppliers.filter((s) => s.supplierNumber === product.supplierNumber);      
        return supplier[0].name;
      }
    },
  }
</script>

<style lang="scss" scoped>
 .product-var-removed {
    color: red;
  }

  .product-var-added {
    color: green;
  }
</style>
