<template>
    <modal 
        ref="modifyPackageModal"
        :title="webhostingPackage.objKey ? 'Edit Package' : 'Add New Package'"
        @save="savePackage()"
    >
        <div class="row">
            <div class="form-group col-md-12">
                <label>Description</label>
                <input type="text" class="form-control" v-model="webhostingPackage.description" ref="description">
            </div>
        </div>
        <div class="row" v-if="webhostingPackage.webHostingPackageNumber">
            <div class="form-group col-md-6">
                <label>Expired</label>
                <v-select 
                    :options="options"
                    label="description"
                    valueProp="value"
                    v-model="expired"
                    ref="expired"
                >
                </v-select>
            </div>
        </div>
        <div class="row">
            <!-- v-if="this.starterType" -->
            <div class="form-group col-md-6">
                <label>Enable PHP</label>
                <select class="form-control" v-model="webhostingPackage.enablePHP">
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :key="option.description"
                      >
                        {{ option.description }}
                      </option>
                </select>
            </div>
            <div class="form-group col-md-6">
                <label>Enable Sql</label>
                <select class="form-control" v-model="webhostingPackage.enableSql">
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :key="option.description"
                      >
                        {{ option.description }}
                      </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label>Enable HTTPS</label>
                <select class="form-control" v-model="webhostingPackage.enableHttps">
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :key="option.description"
                      >
                        {{ option.description }}
                      </option>
                </select>
            </div>
            <div class="form-group col-md-6">
                <label>Enable ModSecurity</label>
                <select class="form-control" v-model="webhostingPackage.enableModSecurity">
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :key="option.description"
                      >
                        {{ option.description }}
                      </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label>Enable FTP</label>
                <select class="form-control" v-model="webhostingPackage.enableFtp">
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :key="option.description"
                      >
                        {{ option.description }}
                      </option>
                </select>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '../../GeneralComponents/Modal.vue';
export default {
    components: {
        Modal
    },
    props: {
        webhostingPackage: {
            type: Object,
            required: true
        }
        // starterType: {
        //     type: Boolean,
        //     required: true
        // }
    },
    data() {
        return {
            options: [
                {
                    description: 'Yes',
                    value: true
                },
                {
                    description: 'No',
                    value: false
                },
            ],
        }
    },
    computed: {
        expired: {
            get() {
                return this.webhostingPackage.expired.toString();
            },
            set(expired) {
                this.webhostingPackage.expired = expired;
            },
        },
    },
    methods: {
        open: function() {
            this.$refs.modifyPackageModal.open();
        },
        savePackage: function() {
            this.$refs.modifyPackageModal.isLoading();
            this.$emit("savePackage", this.webhostingPackage);
        },

    }
}
</script>

<style lang="scss" scoped>

</style>