<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:internetAccess>
      <FttbInternetAccess
        :internetAccess="saleDefinition.definition.internetAccess"
        @routeAdded="routeAdded"
        @routeRemoved="routeRemoved"
      />
      <hr />
      <button class="btn btn-primary" @click="saveSaleDefinition" :disabled='isSaving' v-allowed:view="['STAFF']">Save</button>
    </template>
    <template v-slot:addons>
      <FttbAddons
        ref="addonRef"
        :fttbAddons="saleDefinition.definition.addons"
        :addonConnectivityPackages="saleDefinition.selectableOptions.addonConnectivityPackage"
        :saleDefinition="saleDefinition"
        @reloadAddons="getSaleDefinition"
      />
    </template>
    <template v-slot:mpls>
      <FttbMpls
        :mpls="saleDefinition.definition.mpls"
        @saveMpls="saveMpls"
        @removeMpls="removeMpls"
        :mplsConnectivityPackages="saleDefinition.selectableOptions.mplsConnectivityPackage"
      />
    </template>
    <template v-slot:line>
      <FttbConnectivityLine
        v-model="saleDefinition.definition.line"
        class="col-md-8"
      />
      <hr />
      <button class="btn btn-primary" @click="saveSaleDefinition" :disabled='isSaving' v-allowed:view="['STAFF']">Save</button>
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";

import FttbInternetAccess from "@/components/ObjParts/SingleCaptured/FttbInternetAccess";
import FttbAddons from "@/components/ObjParts/MultipleCaptured/FttbAddons";
import FttbMpls from "@/components/ObjParts/OptionalCaptured/FttbMpls";
import FttbConnectivityLine from "@/components/ObjParts/SingleCaptured/FttbConnectivityLine";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    FttbInternetAccess,
    FttbAddons,
    FttbMpls,
    FttbConnectivityLine,
  },
  data() {
    return {
      isSaving: false,
      saleNumber: null,
      parts: [
        {
          name: "internetAccess",
          icon: "fa fa-link",
          displayName: "Internet Access",
          permissions: ["*"],
        },
        {
          name: "addons",
          icon: "fa fa-list",
          displayName: "Addons",
          permissions: ["*"],
        },
        {
          name: "mpls",
          icon: "fa fa-globe",
          displayName: "MPLS",
          permissions: ["*"],
        },
        {
          name: "line",
          icon: "fa fa-plug",
          displayName: "Line",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
          addons: [],
          internetAccess: {
            pointToPointIpv4Network: {},
            pointToPointIpv6Network: {},
            internetConnectivityPackage: {},
          },
          mpls: {},
          line: {},
        },
        product: {},
        sale: {},
        selectableOptions: {
          addonConnectivityPackage: [],
          mplsConnectivityPackage: [],
        },
      },
    };
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;        
    this.getSaleDefinition();
  },
  methods: {
    getSaleDefinition: function() {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
        (response) => {
          this.saleDefinition = response.data; 
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      )
    },
    updateSale() {
      this.$http
        .put(
          this.$config.aimsAPIv2 +
            "sales/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition.sale
        )
        .then(
          (response) => {
            this.saleDefinition.definition = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Updating Sale", error);
          }
        );
    },
    routeAdded(route) {
      if (route.family.toLowerCase() === "ipv4") {
        route.class =
          "za.co.adept.aims.fibre.business.components.internet.FttbInternetIpv4Network";
      } else if (route.family.toLowerCase() === "ipv6") {
        route.class =
          "za.co.adept.aims.fibre.business.components.internet.FttbInternetIpv6Network";
      } else {
        this.showError("Could not find family " + route.family, {});
        return;
      }
      this.createObjPart(route, true).then(
        (response) => {
          this.showSuccess("Route Created Successfully");
          this.getSaleDefinition();
        },
        (error) => {
          console.error(error);
          this.showError("Error Creating Route", error);
        }
      );
    },
    routeRemoved(route) {
      this.deleteObjPart(route).then(
        (response) => {
          if (route.family.toLowerCase() === "ipv4") {
            const index =
              this.saleDefinition.definition.internetAccess.routedIpv4Networks.findIndex(
                (r) => r.objKey === route.objKey
              );
            this.saleDefinition.definition.internetAccess.routedIpv4Networks.splice(
              index,
              1
            );
          }
          if (route.family.toLowerCase() === "ipv6") {
            const index =
              this.saleDefinition.definition.internetAccess.routedIpv4Networks.findIndex(
                (r) => r.objKey === route.objKey
              );
            this.saleDefinition.definition.internetAccess.routedIpv6Networks.splice(
              index,
              1
            );
          }
          this.showSuccess("Route Removed Successfully");
        },
        (error) => {
          console.error(error);
          this.showError("Error Removing Route", error);
        }
      );
    },
    saveMpls(mpls) {
      this.toggleSaving();
      
      if (!this.saleDefinition.definition.mpls.fttbMplsNumber) {
        this.createObjPart(mpls, true).then(
          (response) => {
            this.showSuccess("MPLS Added Successfully");
            //need to reload because of the line speed update
            this.getSaleDefinition();
            this.toggleSaving();
          },
          (error) => {
            console.error(error);
            this.showError("Error Added MPLS", error);
            this.toggleSaving();
          }
        );
      } else {
        this.toggleSaving();
        this.saveSaleDefinition();
      }
    },
    removeMpls() {
      this.deleteObjPart(this.saleDefinition.definition.mpls).then(
        (response) => {
          this.saleDefinition.sale.mpls = {};
          this.showSuccess("MPLS Removed Successfully");
          this.getSaleDefinition();
        },
        (error) => {
          console.error(error);
          this.showError("Error Removing MPLS", error);
        }
      );
    },
    saveSaleDefinition() {
      this.toggleSaving();
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.toggleSaving();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving sale", error);
                this.toggleSaving();
              }
            );
        }
      });
    },
    toggleSaving() {
      this.isSaving = !this.isSaving;
    },
  },
};
</script>