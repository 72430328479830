<template>
    <modal
        :title="this.pricePackage.apnPackageNumber ? 'Edit Package' : 'Add New Package'"
        ref="modifyPriceModal"
        @save="savePricePackage"
    >
        <div class="row">
            <div class="form-group col-md-12">
                <label>Description </label>
                <input type="text" class="form-control" v-model="pricePackage.description"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Bundle Size (GB)</label>
                <input type="text" class="form-control" v-model="pricePackage.dataBundleSize"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Maximum Number of Sims</label>
                <input type="text" class="form-control" v-model="pricePackage.maxNumberOfSims"/>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-10" v-if="pricePackage.apnPackageNumber">
                <label class="control-label"> Expired </label>
                <v-select :options="['true', 'false']" v-model="expired"></v-select>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/GeneralComponents/Modal';

export default {
    components: {
        Modal
    },
    props: {
        pricePackage: {
            type: Object,
            required: true,
        },
    },
    computed: {
        expired: {
            get() {
                return this.pricePackage.expired.toString();
            },
            set(expired) {
                this.pricePackage.expired = expired;
            },
        },
    },
    methods: {
        open: function() {
            this.$refs.modifyPriceModal.open();
        },
        savePricePackage: function() {     
            this.$emit("savePricePackage", this.pricePackage);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>